import { useEffect, useState } from 'react';
import { useAuth, useFirestore } from 'reactfire';
import { collection, setDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { QueryStringVariable, UserSession } from '@types';

export const useBrandQueryString = (brandId: string): QueryStringVariable[] => {
  const [params, setParams] = useState<QueryStringVariable[]>();
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const queryStringVariablesQuery = collection(
    firestore,
    `brands/${brandId}/queryStringVariables`,
  );

  const getParams = async () => {
    const queryStringVariableDocs = await getDocs(queryStringVariablesQuery);

    // first get the userSession to check if this is being passed form the home screen (This is a hack until we can move away form iframes)
    const userSessionRef = doc(
      firestore,
      `userSessions/${currentUser.uid}`,
    );

    const userSessionDoc = await getDoc(userSessionRef);
    let foundParams = [];

    if (userSessionDoc.exists()) {
      foundParams = (userSessionDoc.data() as UserSession).queryStringVariables;
      await setDoc(userSessionRef, {isExtracted: true}, {merge: true})
    }
    else {

      if (!queryStringVariableDocs.empty) {
        let queryStringVariables = queryStringVariableDocs.docs.map((d) => d.data()) as QueryStringVariable[];

        const reducedSearchParams = Array.from(new URL(window.location as any).searchParams.entries()).reduce((acc, [key, value]) => {
          if (acc[key]) {
            acc[key].push(value);
          } else {
            acc[key] = [value];
          }
          return acc;
        }, {});

        foundParams = queryStringVariables.filter((qsv) => reducedSearchParams[qsv.key]).map((qsv) => {
          let values = reducedSearchParams[qsv.key];
          qsv.queryStringOptions = qsv.queryStringOptions.filter((qso) => values.includes(qso.value));
          return qsv;
        });
      }
    }

    setParams(foundParams);
  }

  useEffect(() => {
    getParams();
  }, [])

  return params;
};
import { useState, useEffect, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getT3Dealer } from '@utils';
import { LookupType } from '@types';

type InputType = {
  sessionId?: string;
  closedMode?: {
    dealer: string;
    bac: string;
    isOpen: boolean;
  }
};

export function useRedirectURL({ sessionId, closedMode }: InputType) {
  if (!closedMode && !sessionId) {
    return null;
  }
  const functions = getFunctions();
  const getDealerHostname = httpsCallable(functions, 'getDealerHostname');

  const firestore = useFirestore();
  const [redirectURL, setRedirectURL] = useState(null);
  const [loading, setLoading] = useState(true);
  const stableClosedMode = useMemo(() => closedMode, [closedMode?.dealer, closedMode?.bac, closedMode?.isOpen]);

  useEffect(() => {
    const fetchRedirectURL = async () => {
      let referralBrand = '';
      let bacId = '';
      if (closedMode) {
        // Showroom is closed, no session exists
        // so we are reading directly from dealershipHostnames
        const { dealer, bac } = closedMode;
        referralBrand = getT3Dealer(dealer);
        if (!bac || referralBrand === '') {
          setLoading(false);
          return;
        }
        bacId = bac;
      } else {
        // Session is ended, so we are reading from lookupCollection
        const lookupRef = doc(firestore, 'lookupCollection', sessionId);
        try {
          const lookupDoc = await getDoc(lookupRef);
          const lookupData = lookupDoc.data() as LookupType;
          if (!lookupData) {
            setLoading(false);
            return;
          }
          referralBrand = lookupData.dealerBrand;
          bacId = lookupData.bacId;
        } catch (error) {
          setLoading(false);
          return;
        }
      }

      try {
        const result = await getDealerHostname({ brand: referralBrand, bacId });
        setRedirectURL(result.data);
      } catch (error) {
        setRedirectURL(null);
      } finally {
        setLoading(false);
      }
    };
    if (closedMode && closedMode.isOpen) {
      setLoading(false);
      return;
    }
    fetchRedirectURL();
  }, [sessionId, stableClosedMode, firestore]);

  if (loading) {
    return null;
  }

  return redirectURL;
}

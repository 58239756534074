/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
import { ReactElement, useState, useEffect, FormEvent, useCallback, useRef } from 'react';
import {
  Box,
  Dialog,
  TextField,
  Button,
  Typography,
  CircularProgress,
  ThemeProvider,
  OutlinedInput,
  Fade,
  Link,
} from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import VehicleSelect from './VehicleSelect';
import VehicleFeatures from './VehicleFeatures';
import VehicleFeaturePreview from './VehicleFeaturePreview';
import Chat from './Chat';
import ChatMessage from './ChatMessage';
import SessionMenuItem from './SessionMenuItem';
import SessionMenu from './SessionMenu';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { OTSession, OTPublisher, OTPublisherRef, OTStreams, OTSubscriber } from 'opentok-react';
import {
  addDoc,
  collection,
  doc,
  setDoc,
  updateDoc,
  where,
  query,
  orderBy,
  deleteDoc,
  runTransaction,
  deleteField,
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import {
  Vehicle,
  Session,
  VehicleFeature,
  BrandType,
  TestDrive,
  Nameplate,
  VehicleType,
  Topic,
  Message,
  ShareableHyperlink,
  ContactDealer,
  SessionActivity,
  ModalName,
  TrackingEventType,
  SymblType,
  SymblTracker,
} from '@types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  TextButton,
  LoadingScreen,
  SelectField,
  GridWrapper,
  ContactDealerView,
  GMToast,
  CloseToastModal,
} from '@components';
import BookTestDriveView from './BookTestDriveView';
import TransferView from './TransferView';
import EndSessionModal from './EndSessionModal';
import InviteAFriendModal from './InviteAFriendModal';
import NameplatePopupModal from './NameplatePopupModal';
import NeedHelpModal from './NeedHelpModal';
import QuestionsModal from './QuestionsModal';
import ShareableHyperlinksModal from './ShareableHyperlinksModal';
import TroubleshootModal from './TroubleshootModal';
import VehicleFeatureView from './VehicleFeatureView';
import EnableYourAudioModal from './EnableYourAudioModal';
import BookTestDriveSubmittedModal from './BookTestDriveSubmittedModal';
// import FormToastView from './FormToastView';
import ContactDealerSubmittedModal from './ContactDealerSubmittedModal';
import ScreenSharingConsentModal from './ScreenSharingConsentModal';
import ScreenSharingErrorModal from './ScreenSharingErrorModal';
import TwoWayCameraModal from './TwoWayCameraModal';
import { useTranslation } from 'react-i18next';
import {
  useClosedCaption,
  useCurrentLanguageParam,
  useReporting,
  useQueryString,
} from '@hooks';
import clsx from 'clsx';
import { SessionViewStyles } from './SessionView.style';
import StayUpdatedModal from './StayUpdatedModal';
import ChatMessageDots from './ChatMessageDots';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReservationModal from './ReservationModal';
import { createCustomTheme } from '@theme';
import TransferNowModal from './TransferNowModal';
import { regexUrlHelper } from '@constants';
import LeavingSessionTabConfirmationModal from './LeavingSessionTabConfirmationModal';
import * as OT from '@opentok/client';
import moment from 'moment';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import { Toaster, toast } from 'react-hot-toast';
import CameraBrowserNotSupportedModal from './CameraBrowserNotSupportedModal';
import { getIsMobile } from '@utils';
import SymblPurgeModal from './SymblPurgeModal';
import SymblErrorModal from './SymblErrorModal';
import ConsentModal from './ConsentModal';
import { Toast } from 'react-hot-toast/dist/core/types';

type SessionProps = {
  sessionId: string;
  brandId: BrandType;
  onEndSession?: (
    deviceKitId: string,
    sessionActivity: SessionActivity,
    transferFrom?: string,
    campaignCode?: string,
    sessionTrackingId?: string,
  ) => void;
  onCompleteSession?: () => void;
  onSessionDoesNotExist?: () => void;
  onExpireSession?: () => void;
  userId: string;
  isProductSpecialist: boolean;
  isProducer?: boolean;
  publishAudio: boolean;
  publishVideo: boolean;
  subscribeToAudio: boolean;
};

declare let window: any;

const SessionView = (props: SessionProps): ReactElement => {
  const {
    sessionId,
    brandId,
    onEndSession,
    onExpireSession,
    onCompleteSession,
    userId,
    isProductSpecialist,
    isProducer,
    publishAudio,
    publishVideo,
    subscribeToAudio,
    onSessionDoesNotExist,
    ...other
  } = props;

  const date = new Date();
  const weekday = moment(date).format('dddd');
  const theme = useTheme();
  const { t } = useTranslation();
  const { track } = useReporting();
  const { lang } = useCurrentLanguageParam();
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';
  const isFrench = lang === 'en-FR'
    || window.location.href.includes('en-FR')
    || window.location.href.includes('en-fr')
    || window.location.href.includes('fr');
  const useStyles = makeStyles(SessionViewStyles(brandId));
  const styles = useStyles();

  // TODO - API key should not be set here.
  const apiKey = '47329621';
  const subscriberProperties = {
    width: 'auto',
    height: 'auto',
    subscribeToAudio,
  };
  const publisherProperties = {
    width: 1,
    height: 1,
    publishAudio,
    publishVideo,
    showControls: false,
    videoSource: false,
    mirror: false,
  };

  const [sessionLoaded, setSessionLoaded] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [status, setUserStatus] = useState<boolean>(false);
  const [userRoute, setUserRoute] = useState<string>('');
  const [hideBookTestDrive, setHideBookTestDrive] = useState<boolean>(false);
  // comment out for now GL-1627
  // const [hideInviteAFriend, setHideInviteAFriend] = useState<boolean>(false);
  const [transferNow, setTransferNow] = useState<boolean>(false);
  const [hyperlink, setHyperlink] = useState<string>('');
  const [clickedMessageId, setClickedMessageId] = useState<string>('');
  const [sessionTimer, setSessionTimer] = useState<number>(0);
  const [vehicleNamePlates, setVehicleNamePlates] = useState<any>([]);
  // const [topicNamePlates, setTopicNamePlates] = useState<any>([]);
  const [bookVehicleTestDrives, setBookVehicleTestDrives] = useState<any>([]);
  const [vehicleTrayVehicles, setVehicleTrayVehicles] = useState<any>([]);
  const [vCallId, setVCallId] = useState('');
  const [audioRate, setAudioRate] = useState([]);
  const [videoRate, setVideoRate] = useState([]);
  const [myUserName, setMyUserName] = useState<string>('');
  const [joinedUsers, setJoinedUsers] = useState<Array<{ [user: string]: string }>>([]);
  const [hasBecomeGroupChat, setHasBecomeGroupChat] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<ModalName>();
  const [subset, setSubset] = useState<string>('');
  const { urlSearchParams } = useQueryString();
  const subsetId = urlSearchParams.get('subset');

  const {
    accessToken,
    conversationId,
    closedCaption,
    desiredTopicCheck,
    finalizedTranscriptFragment,
    currentTracker,
    currentTrackerSpeaker,
    symblError,
    initSymbl,
    connectToExistingConnection,
    subscribeToExistingConnection,
    disconnectSymbl,
    setDesiredTopicCheck,
    setFinalizedTranscriptFragment,
    setCurrentTracker,
    setCurrentTrackerSpeaker,
  } = useClosedCaption(brandId, myUserName?.replace(/\s+/g, ''));

  // symbl states
  const [symblConfigIds, setSymblConfigIds] = useState<SymblType[]>([]);
  const [closedCaptionsToggledByUser, setClosedCaptionsToggledByUser] = useState<boolean>(false);
  const [closedCaptionVisible, setClosedCaptionVisible] = useState<boolean>(false);
  const [symblConnectionTriggerRef, setSymblConnectionTriggerRef] = useState(false);
  const [customerCCs, setCustomerCCs] = useState<string>('');
  const [symblPurgeRequest, setSymblPurgeRequest] = useState<boolean>(false);
  const [timeElapsedTrackers, setTimeElapsedTrackers] = useState<SymblTracker[]>([]);
  const [captionWarning, setCaptionWarning] = useState<string>('');
  const [isConsentClicked, setIsConsentClicked] = useState<boolean>(false);
  const [isKeyboardFocus, setIsKeyboardFocus] = useState<boolean>(false); // Only used for mobile devices
  const ccTimeoutRef = useRef(null);

  // ps desktop states - screen sharing or vr
  const [shareScreenStatus, setShareScreenStatus] = useState<boolean>(false);
  const [streamWebcamStatus, setStreamWebcamStatus] = useState<boolean>(false);
  const [microphones, setMicrophones] = useState<OT.Device[]>([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('');
  const [streamWebcamRef, setStreamWebcamRef] = useState<OTPublisherRef | null>(null);
  const [agentPublisherProperties, setAgentPublisherProperties] = useState();

  // user screen share/camera states
  const [userScreenSharingProperties, setUserScreenSharingProperties] = useState();
  const [userCameraStatus, setUserCameraStatus] = useState<boolean>(false);
  const [userPublisherProperties, setUserPublisherProperties] = useState(publisherProperties);
  const [isFullSizePublisher, setIsFullSizePublisher] = useState<boolean>(false);
  const [userCameras, setUserCameras] = useState<OT.Device[]>([]);
  const [isBackCamera, setIsBackCamera] = useState<boolean>(false);
  const [userCameraPublisher, setUserCameraPusblisher] = useState<OT.Publisher | null>(null);
  const [loadingCamera, setLoadingCamera] = useState<boolean>(false);
  // const [isCameraShareApprove, setIsCameraShareApprove] = useState(false);
  // const [isScreenShareApprove, setIsScreenShareApprove] = useState(false);
  const [isCameraNotification, setIsCameraNotification] = useState<boolean>(false);

  const portrait = window.matchMedia('(orientation: portrait)').matches;
  const [isPortrait, setIsPortrait] = useState<boolean>(portrait);

  const firestore = useFirestore();
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down(1200)); // 'md' or 768
  const isMobile = getIsMobile();

  const CANFlag = require('../assets/images/flags/canFlag.png');
  const USAFlag = require('../assets/images/flags/usaFlag.png');

  const brandQuery = doc(firestore, `brands/${brandId}`);
  const { data: brand } = useFirestoreDocData(brandQuery, {
    idField: 'id',
  });

  const vehicleSubsetRef = collection(firestore, `brands/${brandId}/vehicleSubsets`);
  const vehicleSubsetQuery = query(
    vehicleSubsetRef,
    where('vehicleSubset', '==', subset)
  );
  const { data: vehicleSubset } = useFirestoreCollectionData(vehicleSubsetQuery, {
    idField: 'id',
  });

  const subsetPath = (isEnergy || isEVLive || isEVLiveCanada) ? 'topic' : 'vehicle';

  const vehicleRef = collection(useFirestore(), `brands/${brandId}/${subsetPath}s`);
  const vehicleQuery = subset !== '' && vehicleSubset && vehicleSubset[0] && vehicleSubset[0].widgetIds.includes('Vehicle Tray')
    ? query(
      vehicleRef,
      where('subsetIds', 'array-contains', vehicleSubset[0].id),
      orderBy('index', 'asc')
    )
    : query(
      vehicleRef,
      where('widgetIds', 'array-contains', 'Vehicle Tray'),
      orderBy('index', 'asc')
    );
  const { data: vehicles } = useFirestoreCollectionData(vehicleQuery, {
    idField: 'id',
  });

  const stayUpdatedVehicleQuery = query(
    vehicleRef,
    where('widgetIds', 'array-contains', 'Stay Updated'),
    orderBy('index', 'asc'),
  );
  const { data: stayUpdatedVehicles } = useFirestoreCollectionData(stayUpdatedVehicleQuery, {
    idField: 'id',
  });

  const vehicleTypesRef = collection(useFirestore(), `brands/${brandId}/${subsetPath}Types`);
  const { data: vehicleTypes } = useFirestoreCollectionData(
    query(vehicleTypesRef, orderBy('index', 'asc')),
    {
      idField: 'id',
    },
  );

  const symblTrackersRef = collection(useFirestore(), 'symblTrackers');
  const symblTrackersQuery = query(symblTrackersRef, where('brands', 'array-contains', brandId));
  const { data: symblTrackers, status: symblTrackersStatus } = useFirestoreCollectionData(symblTrackersQuery);

  const nameplateQuery = subset !== ''
    && vehicleSubset
    && vehicleSubset[0]
    && vehicleSubset[0].widgetIds.includes('Nameplate Form')
    ? query(
      vehicleRef,
      where('subsetIds', 'array-contains', vehicleSubset[0].id),
      orderBy('index', 'asc'),
    )
    : query(
      vehicleRef,
      where('widgetIds', 'array-contains', 'Nameplate Form'),
      orderBy('index', 'asc'),
    );
  const { data: nameplates } = useFirestoreCollectionData(nameplateQuery, {
    idField: 'id',
  });

  const nameplateExceptionsRef = collection(useFirestore(), `brands/${brandId}/exceptionDays`);
  const nameplateExceptionsQuery = query(
    nameplateExceptionsRef,
    where('locations', 'array-contains', 'Nameplate Form'),
  );
  const { data: namePlateExceptions } = useFirestoreCollectionData(nameplateExceptionsQuery, {
    idField: 'id',
  });

  const testDriveExceptionsRef = collection(useFirestore(), `brands/${brandId}/exceptionDays`);
  const testDriveExceptionsQuery = query(
    testDriveExceptionsRef,
    where('locations', 'array-contains', 'Book Test Drive'),
  );
  const { data: testDriveExceptions } = useFirestoreCollectionData(testDriveExceptionsQuery, {
    idField: 'id',
  });

  const vehicleTrayExceptionsRef = collection(useFirestore(), `brands/${brandId}/exceptionDays`);
  const vehicleTrayExceptionsQuery = query(
    vehicleTrayExceptionsRef,
    where('locations', 'array-contains', 'Vehicle Tray'),
  );
  const { data: vehicleTrayExceptions } = useFirestoreCollectionData(vehicleTrayExceptionsQuery, {
    idField: 'id',
  });

  const bookTestDriveQuery = subset !== ''
    && vehicleSubset
    && vehicleSubset[0]
    && vehicleSubset[0].widgetIds.includes('Book Test Drive')
    ? query(
      vehicleRef,
      where('subsetIds', 'array-contains', vehicleSubset[0].id),
      orderBy('index', 'asc'),
    )
    : query(
      vehicleRef,
      where('widgetIds', 'array-contains', 'Book Test Drive'),
      orderBy('index', 'asc'),
    );
  const { data: bookTestDrives } = useFirestoreCollectionData(bookTestDriveQuery, {
    idField: 'id',
  });

  const sessionRef = doc(firestore, 'sessions', sessionId);
  const { data: session, status: loadingStatus } = useFirestoreDocData(sessionRef, {
    idField: 'id',
  });
  const isInvited = urlSearchParams.getAll('emailId').includes('invite_start');

  /*  const topicsRef = collection(useFirestore(), `brands/${brandId}/topics`);
  const topicsQuery = query(
    topicsRef,
    where('widgetIds', 'array-contains', 'Nameplate Form'),
  );
  const { data: topics } = useFirestoreCollectionData(topicsQuery, {
    idField: 'id',
  }); */

  const shareableHyperlinksRef = collection(
    useFirestore(),
    `brands/${brandId}/shareableHyperlinks/`,
  );
  const shareableHyperlinksQuery = query(
    shareableHyperlinksRef,
    where('language', '==', lang),
    orderBy('index', 'asc'),
  );
  const { data: shareableHyperlinks } = useFirestoreCollectionData(shareableHyperlinksQuery, {
    idField: 'id',
  });

  const faqsRef = collection(useFirestore(), `brands/${brandId}/faqs/`);
  const faqsQuery = query(faqsRef, orderBy('index', 'asc'));
  const { data: faqs } = useFirestoreCollectionData(faqsQuery, {
    idField: 'id',
  });

  const getTheme = () =>
    createCustomTheme({
      theme: brandId,
    });

  const updateSession = async (sessionData: Partial<Session>) => {
    if (sessionData) {
      updateDoc(sessionRef, sessionData);
    }
  };

  // React keeps re-rendering the page every second but for PS only
  useEffect(() => {
    if (isProductSpecialist || isProducer) {
      const sessionInterval = setInterval(() => {
        const startTime = session.startTime?.toDate() || new Date();
        const timeStarted = startTime.getTime();
        const timer = Math.floor((new Date().getTime() - timeStarted) / 1000);
        setSessionTimer(timer);
      }, 1000);
      return () => {
        clearInterval(sessionInterval);
      };
    }
  }, []);

  useEffect(() => {
    if (loadingCamera) {
      const interval = setInterval(() => {
        setLoadingCamera(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [loadingCamera]);

  useEffect(() => {
    if (symblTrackers && symblTrackersStatus === 'success') {
      // Setting initial values for hasBeenTracked property
      setTimeElapsedTrackers(
        (symblTrackers as SymblTracker[])
          .filter((tracker) => tracker.trackerType === 'onTimeElapsed')
          .map((tracker) => ({
            ...tracker,
            hasBeenTracked: 'UNDEFINED'
          }))
      );
    }
  }, [symblTrackers]);

  useEffect(() => {
    // note: if you're testing cc on local, change === to !==
    if (closedCaption?.speakerName === 'deviceKit') {
      setCustomerCCs(closedCaption.text);
      // Once the specialist has stopped or no activity, clear text after 5 seconds.
      if (closedCaption?.isFinal) {
        ccTimeoutRef.current = setTimeout(() => {
          setCustomerCCs('');
        }, 5000);
      }
      return () => {
        ccTimeoutRef.current = null;
      };
    }
  }, [closedCaption]);

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (
      session
      && loadingStatus === 'success'
      && 'joinedUsers' in session
      && session.joinedUsers !== undefined
      && session.joinedUsers.length > 0
    ) {
      const sessionJU = session.joinedUsers;

      if (joinedUsers.length < sessionJU.length) {
        if (isProductSpecialist) {
          handleToast('A user has joined the session', 'confirmation');
        }
        if (sessionJU.length > 1) {
          setHasBecomeGroupChat(true);
        }
      }
      setJoinedUsers(sessionJU);

      if (!session.symblSessionId && isProductSpecialist) {
        updateSession({ symblSessionId: sessionJU[0]['user 1'] });
      }
    }
  }, [session]);

  useEffect(() => {
    if (!subsetId && session && session.queryStringVariables) {
      const subsetQueryStringVariable = session.queryStringVariables.find(
        (item) => item.key === 'subset',
      );
      if (subsetQueryStringVariable && subsetQueryStringVariable.queryStringOptions[0]) {
        setSubset(subsetQueryStringVariable.queryStringOptions[0].value);
      } else {
        setSubset('');
      }
    } else if (subsetId) {
      setSubset(subsetId);
    }
  }, [subsetId, session]);

  const eventHandlers = {
    sessionConnected: async (event) => {
      const { connectionId } = event.target.connection;

      try {
        const resultingUserName = await runTransaction(firestore, async (transaction) => {
          const sessionRefDup = doc(firestore, 'sessions', sessionId);
          const sessionSnapshot = await transaction.get(sessionRefDup);
          const joinedUsersTmp = sessionSnapshot.data().joinedUsers || [];

          if (!isProductSpecialist) {
            if (joinedUsersTmp.length > 0) {
              const potentialUserNumbers = ['user 1', 'user 2', 'user 3'];
              joinedUsersTmp.forEach((user: any) => {
                const userNumber = Object.keys(user)[0];
                if (potentialUserNumbers.includes(userNumber)) {
                  potentialUserNumbers.splice(potentialUserNumbers.indexOf(userNumber), 1);
                }
              });
              const connectionIdName = {
                [potentialUserNumbers[0]]: connectionId,
              };
              joinedUsersTmp.push(connectionIdName);

              transaction.update(sessionRefDup, { joinedUsers: joinedUsersTmp });

              return {
                user: potentialUserNumbers[0],
                joinedUsers: joinedUsersTmp,
              };
            }
            const connectionIdName = {
              'user 1': connectionId,
            };
            transaction.update(sessionRefDup, { joinedUsers: [connectionIdName] });
          }

          return {
            user: 'user 1',
            joinedUsers: joinedUsersTmp,
          };
        });

        setMyUserName(resultingUserName.user);
        setJoinedUsers(resultingUserName.joinedUsers);
        setHasBecomeGroupChat(resultingUserName.joinedUsers.length > 1);
        console.log('Transaction to add to joinedUsers successfully committed!');
      } catch (e) {
        console.log('Transaction to add to joinedUsers failed: ', e);
      }
    },

    connectionDestroyed: async (event) => {
      const { connectionId } = event.connection;
      try {
        const sessionRefDup = doc(firestore, 'sessions', sessionId);

        await runTransaction(firestore, async (transaction) => {
          const docSnap = await transaction.get(sessionRefDup);
          const sessionData = docSnap.data();

          if (!sessionData.joinedUsers.some((user) => user.connectionId === connectionId)) {
            const updatedJoinedUser = sessionData.joinedUsers.filter(
              (user: { [user: string]: string }) => Object.values(user)[0] !== connectionId,
            );

            transaction.update(sessionRefDup, {
              joinedUsers: updatedJoinedUser,
            });
          }
        });
        if (isProductSpecialist) {
          setJoinedUsers((prevJoinedUsers) =>
            prevJoinedUsers.filter(
              (user: { [user: string]: string }) => Object.values(user)[0] !== connectionId,
            ),);
          handleToast('A user has left the session', 'confirmation');
        }
      } catch (err) {
        console.log(err);
      }
    },
  };

  const messageRef = collection(firestore, `sessions/${sessionId}/messages`);
  const messageQuery = query(messageRef, where('sent', '==', true), orderBy('timestamp', 'asc'));
  const { data: messages } = useFirestoreCollectionData(messageQuery, {
    idField: 'id',
  });

  const statusRef = doc(firestore, 'status', sessionId);
  const { data: statusDoc } = useFirestoreDocData(statusRef, { idField: 'id' });

  const typingState = () => {
    if (isUser) {
      updateSession({
        isUserTyping: false,
      });
    }

    if (isProductSpecialist || isProducer) {
      updateSession({
        isProductSpecialistTyping: false,
      });
    }
  };

  const onTypingInput = (evt: FormEvent) => {
    const eventValue = evt as any;

    if (eventValue.target.value.length > 0) {
      if (isUser) {
        updateSession({
          isUserTyping: true,
        });
      }

      if (isProductSpecialist || isProducer) {
        updateSession({
          isProductSpecialistTyping: true,
        });
      }
    } else {
      typingState();
    }
  };

  const mapStatusDocRouteToUserRoute = (statusDocRoute) => {
    const map = {
      '/': 'Home',
      '/hours-of-operation': 'Home',
      '/group-toast': 'Home',
      '/user-toast': 'Home',
      '/queue': 'Lounge',
      '/lounge': 'Lounge',
      '/lounge-v2': 'Lounge',
      '/group-countdown': 'Group Lounge',
      '/group-lounge': 'Group Lounge',
      '/showroom': 'Showroom',
      '/group-showroom': 'Group Showroom',
      '/thank-you': 'Thank You',
      '/stay-updated': 'Stay Updated',
      '/tracker': 'Tour Type Page',
    };
    return map[statusDocRoute];
  };

  const filterExceptions = (exceptionsArray, arrayToFilter) => {
    const arr = [];
    const now = new Date();

    exceptionsArray.forEach((exception) => {
      if (exception.isEnabled) {
        if (
          exception.isRecurred
          && exception.weekdays.includes(weekday)
          && exception.start.toDate() <= now
          && exception.end.toDate() >= now
        ) {
          arr.push(exception.vehicleNames);
        } else if (
          !exception.isRecurred
          && exception.start.toDate() <= now
          && exception.end.toDate() >= now
        ) {
          arr.push(exception.vehicleNames);
        }
      }
    });
    return arrayToFilter.filter((ele) => !arr.flat().includes(ele.name));
  };

  useEffect(() => {
    if (namePlateExceptions && nameplates) {
      setVehicleNamePlates(filterExceptions(namePlateExceptions, nameplates));
    }
  }, [namePlateExceptions, nameplates]);

  /* useEffect(() => {
    if (namePlateExceptions && topics) {
      setTopicNamePlates(filterExceptions(namePlateExceptions, topics));
    }
  }, [namePlateExceptions, topics]); */

  useEffect(() => {
    if (testDriveExceptions && bookTestDrives) {
      setBookVehicleTestDrives(filterExceptions(testDriveExceptions, bookTestDrives));
    }
  }, [testDriveExceptions, bookTestDrives]);

  useEffect(() => {
    if (vehicleTrayExceptions && vehicles) {
      setVehicleTrayVehicles(filterExceptions(vehicleTrayExceptions, vehicles));
    }
  }, [vehicleTrayExceptions, vehicles]);

  useEffect(() => {
    setTimeout(() => {
      setCaptionWarning('');
      setCurrentTracker('');
      setCurrentTrackerSpeaker('');
    }, 5000);
  }, [captionWarning]);

  useEffect(() => {
    const isTrackerEnabled = symblTrackers
      && symblTrackersStatus === 'success'
      && symblConfigIds.includes('Trackers')
      && !symblError;

    if (isTrackerEnabled && currentTracker !== '' && currentTrackerSpeaker !== '') {
      const detectedSymblTrackers = symblTrackers.find(
        (obj) => obj.trackerName === currentTracker && obj.trackerRole && obj.trackerRole.includes(currentTrackerSpeaker),
      );

      if (detectedSymblTrackers) {
        if (detectedSymblTrackers.trackerType === 'onDetection') {
          setCaptionWarning(detectedSymblTrackers.trackerText ? detectedSymblTrackers.trackerText : '');
        } else {
          const detectedTracker = timeElapsedTrackers.find(
            (element) => element.trackerId === detectedSymblTrackers.trackerId,
          );
          // Avoid state mutation
          setTimeElapsedTrackers((prevTrackers) =>
            prevTrackers.map((tracker) =>
              (tracker.trackerId === detectedTracker.trackerId
                ? { ...tracker, hasBeenTracked: 'HAS_BEEN_TRACKED' }
                : tracker)));
        }
      }
    }
  }, [currentTracker, currentTrackerSpeaker]);

  useEffect(() => {
    const isTrackerEnabled = accessToken && symblConfigIds.includes('Trackers') && !symblError;

    if (timeElapsedTrackers && isTrackerEnabled) {
      // Create a new array with updated tracker objects to avoid state mutation
      const updatedTrackers: SymblTracker[] = timeElapsedTrackers.map((tracker) => {
        // 0.5 will be converted into 30 seconds
        const formatTrackerTimeInSeconds = tracker.trackerTimeToTrigger * 60;
        const shouldSetCaptionReminder = formatTrackerTimeInSeconds <= sessionTimer && tracker.hasBeenTracked === 'UNDEFINED';
        if (shouldSetCaptionReminder) {
          return {
            ...tracker,
            hasBeenTracked: 'NOT_TRACKED',
          };
        }
        // Return the tracker unchanged if no update is needed
        return tracker;
      });
      // Set the new array to state
      setTimeElapsedTrackers(updatedTrackers);
    }
  }, [sessionTimer]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isProductSpecialist || isProducer ? setIsUser(false) : setIsUser(true);

    setTimeout(() => {
      setSessionLoaded(true);
    }, 5000);

    if (statusDoc?.state && statusDoc.state === 'online') {
      setUserStatus(true);
    } else {
      setUserStatus(false);
    }

    if (statusDoc?.route) {
      setUserRoute(mapStatusDocRouteToUserRoute(statusDoc.route));
    }

    if (session && sessionId === userId) {
      setUserStatus(true);
    }

    if (session && session.vehicleState && session.vehicleState.hideBookTestDrive) {
      setHideBookTestDrive(true);
    }

    // if there is subsetId in the url, hide invite a friend button
    // comment out for now GL-1627
    // if (session && subsetId && session.queryStringVariables.find((item: any) => item.key === 'subset')) {
    //   setHideInviteAFriend(true);
    // }
    const vehicleLevelHideBATD = session && session.vehicleState && session.vehicleState.hideBookTestDrive;

    if (
      vehicleLevelHideBATD
      || (isMobileScreenSize && session && session.vehicleState?.overrideTestDriveButton)
    ) {
      // on mobile, suppress the button when it opens new tab
      setHideBookTestDrive(true);
    } else {
      setHideBookTestDrive(false);
    }

    const timeout = null;

    // Listens for the user typing
    document.addEventListener('keyup', (evt: Event) => {
      clearTimeout(timeout);
      const eventValue = evt as any;

      // Once the user enters "enter key", the typing state will change to false
      if (eventValue.code === 'Enter' && eventValue.key === 'Enter') {
        typingState();
      }

      // Do a timeout, if the user isn't typing and save the typing state
      // timeout = setTimeout(() => {
      //   typingState();
      // }, 5000);
    });
  }, [statusDoc, session]);

  const handleToggleModal = (modalName?: ModalName) => {
    if (modalName && typeof modalName === 'string') {
      if (closedCaptionsToggledByUser) {
        setClosedCaptionVisible(false);
      }
      setCurrentModal(modalName);
    } else {
      if (closedCaptionsToggledByUser) {
        setClosedCaptionVisible(true);
      }
      setCurrentModal(null);
      updateSession({
        formOpen: false,
      });
    }
  };

  useEffect(() => {
    if (session && session.transferRequest && !isProductSpecialist) {
      if (session.transferRequest.transferNow) {
        handleToggleModal('transferNow');
      } else {
        updateSession({
          formOpen: true,
          formTitle: 'Transfer form',
        });
        handleToggleModal('reservation');
      }
    }
  }, [session?.transferRequest]);

  const formatSessionTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  const handelToggleVehicleFeature = () => {
    if (!session.vehicleFeatureOpen) track('view-features');

    const sessionState: Partial<Session> = {
      vehicleFeatureOpen: !session.vehicleFeatureOpen,
      chatVisible: false,
      moreMenuOpen: false,
      vehicleColorsVisible: false,
      vehicleModelVisible: false,
    };

    if (session.vehicleFeaturesState) {
      const defaultVehicle = session.vehicleFeaturesState[0];
      sessionState.selectedVehicleFeatureState = defaultVehicle;
    }

    updateSession(sessionState);
  };

  const handelToggleMoreMenu = () => {
    if (isEnergy) {
      updateSession({
        moreMenuOpen: !session.moreMenuOpen,
        vehicleFeatureOpen: false,
        vehicleColorsVisible: false,
        vehicleModelVisible: false,
      });
    } else {
      updateSession({
        moreMenuOpen: !session.moreMenuOpen,
        chatVisible: false,
        vehicleFeatureOpen: false,
        vehicleColorsVisible: false,
        vehicleModelVisible: false,
      });
    }
  };

  const handelToggleShowChat = (forceToShowChat?: boolean) => {
    // if there's message from shareable hyperlinks, it needs to show chat = forceToShowChat
    if (!session.chatVisible) {
      if (session && session.dealership) {
        track('open-chat', { bac: session.dealership.bacCode });
      } else {
        track('open-chat');
      }
    }

    if (!isMobileScreenSize) {
      if (isEnergy) {
        updateSession({
          chatVisible: forceToShowChat || !session.chatVisible,
          vehicleColorsVisible: false,
          vehicleModelVisible: false,
        });
      } else {
        updateSession({
          chatVisible: forceToShowChat || !session.chatVisible,
          moreMenuOpen: false,
          vehicleColorsVisible: false,
          vehicleModelVisible: false,
        });
      }
    } else if (isEnergy) {
      updateSession({
        chatVisible: forceToShowChat || !session.chatVisible,
        vehicleColorsVisible: false,
        vehicleModelVisible: false,
        vehicleFeatureOpen: false,
      });
    } else {
      updateSession({
        chatVisible: forceToShowChat || !session.chatVisible,
        moreMenuOpen: false,
        vehicleColorsVisible: false,
        vehicleModelVisible: false,
        vehicleFeatureOpen: false,
      });
    }

    if (userCameraStatus) {
      setIsCameraNotification(true);
    }

    if (isKeyboardFocus) {
      setIsKeyboardFocus(false);
    }
  };

  const handelToggleMuteVideo = () => {
    updateSession({ isMuted: !session.isMuted });
  };

  const addTracking = async (event: TrackingEventType, data: any = {}) => {
    const trackingRef = collection(firestore, 'tracking');
    const trackingData = {
      brandId,
      event,
      data,
      date: new Date(),
      uid: sessionId,
    };
    await addDoc(trackingRef, trackingData);
  };

  const handelToggleUserScreenSharing = () => {
    if (!session.allowScreenSharingUser) {
      addTracking('screen-share-specialist-enable');
    } else {
      addTracking('screen-share-specialist-disable');
    }
    updateSession({ allowScreenSharingUser: !session.allowScreenSharingUser });
  };

  const handelToggleUserCameraSharing = () => {
    if (!session.allowCameraSharing) {
      addTracking('camera-share-specialist-enable');
    } else {
      addTracking('camera-share-specialist-disable');
    }
    updateSession({ allowCameraSharing: !session.allowCameraSharing });
  };

  const getPublisherBase = () =>
    ({
      width: '100%',
      height: '100%',
      publishAudio: true,
      publishVideo: true,
      maxResolution: { width: 1920, height: 1920 },
    } as any);

  const updateUserScreenShareState = (isUserScreenSharing: boolean) => {
    if (!isUserScreenSharing) {
      setUserScreenSharingProperties(null);
    }
    updateSession({
      isUserScreenSharing,
    });
  };

  const updateUserWebcamState = (isUserWebcamSharing: boolean) => {
    setUserCameraStatus(isUserWebcamSharing);
    updateSession({
      isUserWebcamSharing,
      isUserBackCamera: false,
    });
  };

  const updatePSScreenShareState = (isProductSpecialistScreenSharing: boolean) => {
    if (!isProductSpecialistScreenSharing) {
      setAgentPublisherProperties(null);
    }
    updateSession({
      isProductSpecialistScreenSharing,
    });
  };

  const userPublisherEventHandlers = {
    videoElementCreated: async (e) => {
      if (userCameraStatus) {
        const publisher = e.target;
        setUserCameraPusblisher(publisher);
        track('camera-share-user-start');

        const videoInputs = [];
        OT.getDevices((err, devices) => {
          videoInputs[0] = devices.find(
            (device) =>
              device.deviceId
              && device.kind === 'videoInput'
              && device.label.toLowerCase().includes('front'),
          );
          videoInputs[1] = devices.find(
            (device) =>
              device.deviceId
              && device.kind === 'videoInput'
              && device.label.toLowerCase().includes('back'),
          );
        });
        setUserCameras(videoInputs);
      }
    },
    accessDenied: async () => {
      updateUserWebcamState(false);
    },
  };

  const userSreenSharingEventHandlers = {
    streamCreated: async () => {
      updateUserScreenShareState(true);
    },
    streamDestroyed: async (event) => {
      event.stream?.publisher.publishVideo(false);
      track('screen-share-user-end');
      updateUserScreenShareState(false);
    },
    accessDenied: async () => {
      updateUserScreenShareState(false);
    },
  };

  const agentPublisherRef = useCallback(
    (node) => {
      if (streamWebcamStatus || shareScreenStatus) {
        setStreamWebcamRef(node);
      }
    },
    [streamWebcamStatus, shareScreenStatus],
  );

  useEffect(() => {
    if (isProductSpecialist || isProducer) {
      updatePSScreenShareState(shareScreenStatus);
    }
  }, [shareScreenStatus]);

  useEffect(() => {
    const isUserTmp = !isProductSpecialist && !isProducer;
    if (isUserTmp) {
      updateSession({
        isUserWebcamSharing: userCameraStatus,
      });
    }
  }, [userCameraStatus]);

  const handleStreamWebcam = () => {
    const properties = getPublisherBase();
    properties.resolution = '1280x720';

    setAgentPublisherProperties(properties);
    setStreamWebcamStatus(!streamWebcamStatus);
    setShareScreenStatus(false);

    if (streamWebcamStatus) {
      setAgentPublisherProperties(null);
    }
  };

  const handleUserCamera = () => {
    const properties = getPublisherBase();
    properties.resolution = '1280x720';

    setUserPublisherProperties(properties);
    updateUserWebcamState(!userCameraStatus);

    if (userCameraStatus) {
      setUserPublisherProperties(publisherProperties);
      setIsFullSizePublisher(false);
    } else {
      setLoadingCamera(true);
    }
  };

  const handleUserCameraFlip = async () => {
    if (isUser && userCameraStatus && userCameraPublisher) {
      try {
        const selectedCamera = isBackCamera ? userCameras[0]?.deviceId : userCameras[1]?.deviceId;
        if (selectedCamera) {
          setLoadingCamera(true);
          userCameraPublisher.setVideoSource(selectedCamera);
          setIsBackCamera(!isBackCamera);
          updateSession({
            isUserBackCamera: !isBackCamera,
          });
        }
      } catch (error) {
        console.log("Flipping user's camera failed: ", error);
      }
    }
  };

  const handleStats = (err, statsArray) => {
    setVCallId(statsArray[0]?.connectionId);
    setAudioRate(statsArray[0]?.stats.audio);
    setVideoRate(statsArray[0]?.stats.video);
  };

  const getMicrophones = async () => {
    if (isProductSpecialist) {
      OT.getDevices((err, devices) => {
        const audioInputs = devices.filter(
          (device) => device.deviceId && device.kind === 'audioInput',
        );
        setMicrophones(audioInputs);
        if (streamWebcamStatus) {
          setSelectedMicrophone(audioInputs[0] && audioInputs[0].label);
        } else {
          setSelectedMicrophone('');
        }
      });
    }
  };

  const agentPublisherEventHandlers = {
    streamDestroyed: async (event) => {
      if (shareScreenStatus) {
        addTracking('screen-share-specialist-end');
      }
      event.stream?.publisher.publishVideo(false);
      setShareScreenStatus(false);
      setStreamWebcamStatus(false);
    },
    accessDenied: async () => {
      setShareScreenStatus(false);
    },
    accessAllowed: () => {
      getMicrophones();
    },
  };

  const updateMicrophone = async () => {
    if (selectedMicrophone && streamWebcamRef) {
      const agentPublisher = streamWebcamRef.getPublisher();
      const microphone = microphones.find((mic) => mic.label === selectedMicrophone);
      if (microphone?.deviceId) {
        await agentPublisher.setAudioSource(microphone.deviceId);
      } else {
        console.log('invalid microphone');
      }
    }
  };

  if (isProductSpecialist) {
    navigator.mediaDevices.ondevicechange = () => {
      getMicrophones();
    };

    useEffect(() => {
      getMicrophones();
    }, []);
  }

  useEffect(() => {
    updateMicrophone();
  }, [selectedMicrophone]);

  const handelToggleColorTray = () => {
    updateSession({
      vehicleColorsVisible: !session.vehicleColorsVisible,
      chatVisible: false,
      vehicleFeatureOpen: false,
      moreMenuOpen: !session.moreMenuOpen,
    });
  };

  const handelToggleModelTray = () => {
    updateSession({
      vehicleModelVisible: !session.vehicleModelVisible,
      chatVisible: false,
      vehicleFeatureOpen: false,
      moreMenuOpen: !session.moreMenuOpen,
    });
  };

  const handelRequestHelp = () => {
    updateSession({
      helpRequested: !session.helpRequested,
    });
  };

  const handelCloseVehicleTray = () => {
    updateSession({
      vehicleColorsVisible: false,
      vehicleModelVisible: false,
      chatVisible: false,
      vehicleFeatureOpen: false,
      moreMenuOpen: !session.moreMenuOpen,
    });
  };

  const handelChangeVehicle = () => {
    setAgentPublisherProperties(null);
  };

  const handleEndSession = async () => {
    await updateSession({
      status: 'Ended',
      endTime: new Date(),
      nameplateFormVisible: currentModal === 'endSession'
    });
  };

  const handelSelectFeature = (vehicleFeature: VehicleFeature) => {
    if (vehicleFeature) {
      updateSession({
        vehicleFeatureOpen: true,
        selectedVehicleFeatureState: vehicleFeature,
      });
    }
  };

  const handleScreenShare = (isUserParam: boolean) => {
    const properties = getPublisherBase();
    properties.publishAudio = false;
    properties.videoSource = 'screen';

    if (isUserParam) {
      OT.checkScreenSharingCapability((res) => {
        if (res.supported) {
          setUserScreenSharingProperties(properties);
        } else {
          handleToggleModal('screenSharingConsentError');
        }
      });
    } else {
      setAgentPublisherProperties(properties);
      setShareScreenStatus(!shareScreenStatus);
      setStreamWebcamStatus(false);
    }
  };

  // const handleCloseFormToast = () => {
  //   updateSession({ formToastVisible: false });
  // };

  const handleToggleEndSession = () => {
    if (streamWebcamRef) {
      const agentPublisher = streamWebcamRef.getPublisher();
      agentPublisher.getStats((err, stats) => handleStats(err, stats));
    }
    handleToggleModal('endSession');
  };

  const triggerEndSession = () => {
    setTimeElapsedTrackers([]);
    const sessionActivity = {
      transferRequested: session.transferRequested || false,
      bookedTestDrive: session.bookedTestDrive || false,
      hasReservation: session.hasReservation || false,
      invitedAFriend: session.invitedAFriend || false,
    };
    onEndSession(
      session.deviceKitId,
      sessionActivity,
      session.previousTransferRequest?.fromBrand,
      session.campaignCode,
      session.sessionTrackingId,
    );
  };

  if (
    !isProductSpecialist
    && session
    && (session.status === 'Ended' || session.status === 'Expired')
  ) {
    triggerEndSession();
  }

  if (!isProductSpecialist && session && session.status === 'Expired') {
    onExpireSession();
  }

  if (isProductSpecialist && session && session.status === 'Complete') {
    onCompleteSession();
  }

  const onError = (error) => {
    if (session.email) {
      updateSession({ error });
    }
  };

  const handleSave = (testDrive: TestDrive) => {
    updateSession({ ...testDrive, testDrive } as any); // TODO: what up for this?
    handleToggleModal();
  };

  const handleTestDriveSubmit = async (testDrive: TestDrive) => {
    const ref = doc(firestore, 'testDrives', sessionId);
    const testDriveData = {
      testDrive,
      bookedTestDrive: true,
      bookedByPS: isProductSpecialist,
      type: 'single',
      queryStringVariables: session.queryStringVariables || [],
    };
    await setDoc(ref, testDriveData, { merge: true });

    await updateSession({
      ...testDrive,
      bookedTestDrive: true,
      vehicleId: testDrive.vehicleId,
    } as any);

    if (isCadillacUS || isCadillacCanada) {
      handleToggleModal('bookTestDriveSubmitted');
    }

    handleToast('Test drive submission received.', 'confirmation');

    updateSession({
      formToastVisible: true,
      formToastTitle: 'Book Test Drive Form Submitted',
      formOpen: false,
    });
  };

  const handleContactDealerSubmit = async (contactDealer: ContactDealer) => {
    const ref = doc(firestore, 'contactDealers', sessionId);
    contactDealer.type = 'single';
    contactDealer.bookedByPS = isProductSpecialist;
    await setDoc(ref, contactDealer, { merge: true });

    const sessionUserData = {
      firstName: contactDealer.firstName,
      lastName: contactDealer.lastName,
      email: contactDealer.email
    }

    if (isEVLiveCanada) {
      handleToggleModal('contactDealerSubmitted');
    }

    await updateSession({
      ...sessionUserData,
      formToastVisible: true,
      formToastTitle: 'Your form has been submitted.',
      formOpen: false,
    });

    if (isUser) handleToast('Your form has been submitted.', 'confirmation', 'modal:form submitted');
  };

  const handleNameplateSubmit = async (nameplate: Nameplate) => {
    await disconnectSymbl();
    await updateSession({
      nameplate,
      status: 'Complete',
      purgeSymblData: symblPurgeRequest || !symblConfigIds.includes('Insights'),
    });
  };

  const handleStayUpdatedSubmit = async (stayUpdated) => {
    const ref = doc(firestore, 'stayUpdated', userId);
    await setDoc(ref, stayUpdated, { merge: true });
    handleToggleModal();
  };

  const requestHelpButtonVariantChange = () => {
    if (session.helpRequested) {
      return 'statusOffline';
    }

    if (session.isMuted) {
      return 'productSpecialistLight';
    }

    if (session.isMuted && session.helpRequested) {
      return 'statusOffline';
    }

    return 'productSpecialist';
  };

  const handleMessageSend = (shareableHyperlink: ShareableHyperlink) => {
    handelToggleShowChat(true);
    updateSession({
      chatLoader: {
        isProductSpecialist: !isUser,
        isUser,
      },
    });

    /**
     * https://application.dentsu.com/browse/ENERGYL-445
     * Check if link has stay-updated, and append the uid to the url.
     * This is for Agents to encourage users to complete the Stay Updated forms in advance of the session ending.
     */
    let linkHref = shareableHyperlink.href;
    const stayUpdatedLink = linkHref.includes('stay-updated');
    const hasParams = linkHref.includes('?');

    if (stayUpdatedLink) {
      if (hasParams) {
        linkHref += `&uid=${session.id}`;
      } else {
        linkHref += `?uid=${session.id}`;
      }
      if (subset) {
        linkHref += `&subset=${subset}`;
      }
    }

    const message = {
      text: linkHref,
      timestamp: new Date(),
      userId,
      sent: false,
      linkTitle: shareableHyperlink.name,
      isClicked: false,
    };

    addDoc(messageRef, { ...message });
  };

  useEffect(() => {
    if (brand && brand.symblConfigIds) {
      setSymblConfigIds(brand.symblConfigIds);
    }
  }, [brand]);

  useEffect(() => {
    const getSymblAccess = async () => {
      // check if any any config ids are present for symbl to enable symbl
      if (symblConfigIds.length > 0 && !accessToken) {
        await initSymbl();
      }
    };
    getSymblAccess();
  }, [accessToken, symblConfigIds]);

  useEffect(() => {
    const connectToSymbl = async () => {
      if (isProductSpecialist && session.conversationId) {
        setSymblConnectionTriggerRef(true);
        await subscribeToExistingConnection(session.symblSessionId, false, symblConfigIds);
      }

      if (isUser) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(async () => {
            setSymblConnectionTriggerRef(true);
            await connectToExistingConnection(session.symblSessionId);
          }).catch(async () => {
            if (session.conversationId) {
              setSymblConnectionTriggerRef(true);
              await subscribeToExistingConnection(session.symblSessionId, true);
            }
          });
      }
    };

    const isSymblReady = accessToken && myUserName && session && session.symblSessionId;
    if (!symblConnectionTriggerRef && isSymblReady) {
      connectToSymbl();
    }
  }, [accessToken, session]);

  useEffect(() => {
    if (isUser && conversationId && !session?.conversationId) {
      updateDoc(sessionRef, { conversationId });
    }
  }, [conversationId]);

  useEffect(() => {
    if (isProductSpecialist && accessToken) {
      updateDoc(sessionRef, { symblAccessToken: accessToken });
    }
  }, [accessToken]);

  useEffect(() => {
    if (isProductSpecialist && finalizedTranscriptFragment) {
      const sessionTranscriptsRef = doc(
        firestore,
        `sessions/${sessionId}/transcriptFragments/${finalizedTranscriptFragment.id}`,
      );
      setDoc(sessionTranscriptsRef, finalizedTranscriptFragment, { merge: true });
    }
  }, [finalizedTranscriptFragment]);

  // can have only string
  const handleLeavingSessionModal = (url?: string, messageId?: string, isUserClicked?: string) => {
    setHyperlink(url && url);
    setClickedMessageId(isUserClicked && messageId);
    handleToggleModal('leavingSession');
  };

  const handleOpenTab = () => {
    handleToggleModal();
    if (clickedMessageId && session) {
      const clickedMessageRef = doc(firestore, `sessions/${sessionId}/messages`, clickedMessageId);
      updateDoc(clickedMessageRef, {
        isClicked: true,
      });
    }
    if (hyperlink === '') return;
    window.open(hyperlink, '_blank');
  };

  const handleBookTestDriveButton = (overrideTestDriveButton: boolean) => {
    if (overrideTestDriveButton) {
      handleLeavingSessionModal(session.vehicleState.urlOverride);
    } else {
      updateSession({
        formOpen: true,
        formTitle: 'Book Test Drive Form',
        moreMenuOpen: false,
      });
      handleToggleModal('bookTestDrive');
    }
  };

  useEffect(() => {
    if (symblError) {
      const trackingEvent = isUser ? 'symbl-user-fail' : 'symbl-specialist-fail';
      addTracking(trackingEvent, { error: symblError });
    }
  }, [symblError]);

  const handleCaptionToggle = () => {
    if (symblError) {
      handleToggleModal('symblErrorModal');
      return;
    }
    const newCaptionVisibility = !closedCaptionVisible;
    setClosedCaptionsToggledByUser(newCaptionVisibility);
    setClosedCaptionVisible(newCaptionVisibility);

    if (newCaptionVisibility) {
      updateSession({
        formOpen: true,
        formTitle: 'Caption',
        moreMenuOpen: false,
      });
    } else {
      setCustomerCCs('');
      updateSession({
        formOpen: false,
        formTitle: '',
        moreMenuOpen: false,
      });
    }
  };

  const checkSupportedBrowser = () => {
    const { userAgent } = navigator;

    const isSafari11Plus = userAgent.match(/safari/i)
      && !userAgent.match(/chrome/i)
      && userAgent.match(/version\/1[1-9]/i);
    const isChromeAndroid = userAgent.match(/chrome/i) && userAgent.match(/android/i);
    const isFirefoxAndroid = userAgent.match(/firefox/i) && userAgent.match(/android/i);

    return isSafari11Plus || isChromeAndroid || isFirefoxAndroid;
  };

  const toggleNotSupportedBrowserModal = () => {
    handleToggleModal('browserNotSupported');
  };

  const handleCopy = (uid: string) => {
    navigator.clipboard.writeText(uid);
    handleToast('UID copied!', 'confirmation');
  };

  const moreButtonStats = () => {
    let stat = '';

    if (isEnergy && session.moreMenuOpen) {
      stat = 'stat-button-close';
    } else if (isEnergy && !session.moreMenuOpen) {
      stat = 'stat-icon-link';
    } else {
      stat = 'stat-expand-icon';
    }

    return stat;
  };

  const getBacCodeQueryString = () => {
    if (!session.queryStringVariables || session.queryStringVariables.length === 0) {
      return null;
    }
    const bacCodeQueryString = session.queryStringVariables.find(
      (variable: any) => variable.key.toLowerCase() === 'bac',
    );
    if (!bacCodeQueryString) {
      return null;
    }
    return bacCodeQueryString.value;
  };

  const t3Customer = () => {
    if (session.t3DealerBrand) {
      return true;
    }
    return false;
  };

  const updateOrientation = async () => {
    if (isProductSpecialist || isProducer) {
      await updateSession({
        isProductSpecialistPortrait: isPortrait,
      });
    }
  };

  const handleToast = (message: string, variant: any, dataDTM?: string) => {
    toast((t: Toast) => (
      <GMToast
        variant={variant}
        message={message}
        dismiss={() => toast.dismiss(t.id)}
        dataDTM={dataDTM}
      />
    ));
  };

  useEffect(() => {
    screen.orientation.addEventListener('change', (event: any) => {
      setLoadingCamera(true);
      const { type } = event.target;

      if (type === ('portrait-primary' || 'portrait-secondary')) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    });

    updateOrientation();
  }, [isPortrait]);

  // trigger closeCaptionVisible open then send formOpen to true
  useEffect(() => {
    if (closedCaptionVisible) {
      updateSession({
        formOpen: true,
        formTitle: 'Caption',
        moreMenuOpen: false,
      });
    }
  }, [closedCaptionVisible]);

  useEffect(() => {
    if (
      isProductSpecialist
      && session?.formToastVisible
      && session?.formToastTitle
    ) {
      handleToast(session?.formToastTitle, 'confirmation', 'modal:form submitted');

      setTimeout(() => {
        updateSession(({
          formToastVisible: false,
          formToastTitle: '',
          formOpen: false,
        }));
      }, 4000);
    }
    // Inside the Dependency Array, this will prevent the toast from appearing 2-3 times
  }, [session?.formToastVisible && session?.formToastTitle]);

  useEffect(() => {
    // PDM Tagging: if contact dealer form is available to the showroom. Update formName to 'test drive'
    const isT3Dealer = (session && t3Customer() && session.dealership && session.dealership.cid);
    if (isUser && isEnergy && isT3Dealer) {
      window.digitalData.formInfo.formName = 'test drive';
    }
  }, [session]);

  return (
    <>
      {loadingStatus === 'loading' ? (
        <LoadingScreen />
      ) : session && session.sessionId && session.token && vehicles ? (
        <Box
          className={`
            ${styles.showroomOuter}
            ${currentModal === 'bookTestDrive' ? styles.showroomOuterActiveTestDrive : ''}
            ${currentModal === 'inviteAFriend' ? styles.showroomOuterActiveInviteAFriend : ''}
          `}
        >
          {/* {session.formToastVisible && !isUser ? (
            <FormToastView
              brandId={brandId}
              brandName={brand.name}
              title={session.formToastTitle}
              closeToast={handleCloseFormToast}
            />
          ) : null} */}
          <Toaster
            position="top-center"
            toastOptions={{
              className: styles.GMToast,
              duration: 4000,
            }}
          />

          <Box
            className={`
              ${styles.showroom}
              ${isProductSpecialist ? styles.showroomProductSpecialist : ''}
            `}
          >
            <Box className={styles.showroomContainer}>
              <Box
                className={`
                ${styles.OTSessionContainer}
                ${session.isMuted ? styles.OTSessionMuted : styles.OTSessionUnmuted}
                ${session.vehicleState && session.vehicleState.allowWebcamStreaming
                  ? styles.OTSessionIsOneToOneVR
                  : ''
                  }
                ${session.isProductSpecialistScreenSharing
                    || session.isUserScreenSharing
                    || session.isUserWebcamSharing
                    ? styles.OTSessionIsUserScreenAndWebcamSharing
                    : ''
                  }
              `}
              >
                <OTSession
                  apiKey={apiKey}
                  sessionId={session.sessionId}
                  token={session.token}
                  onError={onError}
                  eventHandlers={eventHandlers}
                >
                  <>
                    <OTStreams>
                      <div
                        className={`${isUser && userCameraStatus && isFullSizePublisher
                          ? session.isProductSpecialistPortrait
                            ? styles.OTUserPublisherPiP
                            : styles.OTUserPublisherPiPLandscape
                          : ''
                        }
                        ${(isUser && userCameraStatus && isFullSizePublisher && closedCaptionVisible)
                          ? styles.OTUserPublisherPiP_ClosedCaptionActiveSlideIn
                          : ''}
                        ${isProductSpecialist
                            && session.isUserWebcamSharing
                            && !session.isUserBackCamera
                            ? styles.OTSessionMirror
                            : ''
                          }
                        ${isProductSpecialist
                            && (session.isUserScreenSharing || session.isUserWebcamSharing)
                            ? styles.OTStreamPSHasBackground
                            : ''
                          }
                        ${isUser && session.isProductSpecialistScreenSharing
                            ? styles.OTStreamUserHasBackground
                            : ''
                          }
                        `}
                      >
                        <OTSubscriber properties={subscriberProperties} />
                      </div>
                    </OTStreams>

                    {/* TODO: Need to figure out PiP, when closing / turning of camera. Add the leaving animation */}
                    {isUser && (
                      <Fade
                        in={userCameraStatus && !loadingCamera}
                        timeout={{ enter: 4000 }}
                      >
                        <div
                          className={`
                          ${(session.chatVisible && isMobileScreenSize) ? styles.OTUserPublisherPiP_HidePiPOnChatOpen : ''}
                          ${userCameraStatus && !isFullSizePublisher
                            ? isPortrait
                              ? styles.OTUserPublisherPiP
                              : styles.OTUserPublisherPiPLandscape
                            : ''
                            }
                          ${isBackCamera
                              ? isFullSizePublisher
                                ? styles.OTSessionMirror
                                : styles.OTSessionPiPMirror
                              : ''
                            }
                          ${isFullSizePublisher ? styles.OTUserPublisherPiPFullScreenBackground : ''
                            }
                          ${(userCameraStatus && !isFullSizePublisher && loadingCamera)
                              ? styles.OTUserPublisherPiP_DefaultState
                              : ''}
                          ${(userCameraStatus && !isFullSizePublisher && !loadingCamera)
                                ? styles.OTUserPublisherPiP_SlideIn
                                : ''}
                          ${(userCameraStatus && !isFullSizePublisher && !loadingCamera && closedCaptionVisible)
                                  ? styles.OTUserPublisherPiP_ClosedCaptionActiveSlideIn
                                  : ''}
                          `}
                        >
                          <OTPublisher
                            properties={userPublisherProperties}
                            eventHandlers={userPublisherEventHandlers}
                            onError={(err) => {
                              track('camera-share-user-fail', { error: err.name });
                            }}
                          />
                        </div>
                      </Fade>
                    )}

                    {isUser && userScreenSharingProperties && (
                      <div className={styles.OTPublisherUser}>
                        <OTPublisher
                          properties={userScreenSharingProperties}
                          eventHandlers={userSreenSharingEventHandlers}
                          onError={(err) => {
                            track('screen-share-user-fail', { error: err.name });
                          }}
                          onInit={() => {
                            track('screen-share-user-start');
                          }}
                        />
                      </div>
                    )}

                    {isProductSpecialist && agentPublisherProperties && (
                      <div className={styles.OTPublisherSpecialist}>
                        <OTPublisher
                          ref={agentPublisherRef}
                          eventHandlers={agentPublisherEventHandlers}
                          properties={agentPublisherProperties}
                          onError={(err) => {
                            if (shareScreenStatus) {
                              addTracking('screen-share-specialist-fail', { error: err.name });
                            }
                          }}
                          onInit={() => {
                            if (shareScreenStatus) {
                              addTracking('screen-share-specialist-start');
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                </OTSession>
              </Box>

              {/* {isProductSpecialist && session.isUserScreenSharing && status && (
                <Box
                  className={clsx(styles.screenSharingContainer, styles.screenSharingPSIndicator)}
                >
                  <Typography
                    className={styles.screenSharingText}
                    variant="liveText"
                  >
                    {`${t('Customer is screen sharing')}`}
                  </Typography>
                </Box>
              )} */}
              {isUser && session.isProductSpecialistScreenSharing && (
                <Box
                  className={clsx(styles.screenSharingContainer, styles.screenSharingUserIndicator)}
                >
                  <Typography
                    className={styles.screenSharingText}
                    variant="liveText"
                  >
                    {`${t('Product Specialist is screen sharing')}`}
                  </Typography>
                </Box>
              )}

              {/* {isProductSpecialist && session.isUserWebcamSharing && status && (
                <Box
                  className={clsx(styles.screenSharingContainer, styles.screenSharingPSIndicator)}
                >
                  <Typography
                    className={styles.screenSharingText}
                    variant="liveText"
                  >
                    {t('Customer is webcam sharing')}
                  </Typography>
                </Box>
              )} */}

              <Box
                className={`
                  ${styles.liveEndSessionContainer}
                  ${isUser
                  ? styles.liveEndSessionUserContainer
                  : styles.liveEndSessionProductSpecialistContainer
                  }
                  ${isFrench ? styles.liveEndSessionFrenchContainer : ''}`}
              >
                {isProductSpecialist && (
                  <Box className={styles.liveContainer}>
                    <Typography
                      className={`${isFrench ? styles.productSpecialistLiveFrenchText : ''}`}
                      variant="liveText"
                    >
                      {`${t('LIVE')} | ${formatSessionTimer(sessionTimer)}`}
                    </Typography>
                  </Box>
                )}

                {(isUser && !isKeyboardFocus) && (
                  <Box className={`${styles.liveContainer} live-box`}>
                    <Typography variant="liveText">{`${t('LIVE')}`}</Typography>
                  </Box>
                )}

                <Box className={styles.experienceButtons}>
                  {(!isProducer && !isKeyboardFocus) && (
                    <Button
                      className={`stat-button-link ${isUser ? styles.userEndSessionButton : ''}`}
                      data-dtm="1-1 session"
                      onClick={handleToggleEndSession}
                      variant={session.isMuted ? 'experienceInverseLight' : 'experienceInverse'}
                      type="button"
                      disableRipple
                      aria-label="End session"
                    >
                      {isEnergy && <Box className="close-icon" />}
                      {!isChevy && <span>{t('End Live Tour')}</span>}
                      {isChevy && <>{t('End Session')}</>}
                    </Button>
                  )}
                  {isProductSpecialist && (
                    <Button
                      variant={session.isMuted ? 'experienceInverseLight' : 'experienceInverse'}
                      type="button"
                      disableRipple
                      aria-label="Expire session"
                      onClick={async () => {
                        await disconnectSymbl();
                        await updateSession({
                          status: 'Expired',
                          endTime: new Date(),
                          purgeSymblData: true,
                        });
                        setTimeout(() => {
                          triggerEndSession();
                        }, 1000);
                      }}
                    >
                      {t('Expire Session')}
                    </Button>
                  )}
                </Box>

                {isProductSpecialist && brand && brand.allowCallTransfering && (
                  <Box className={styles.transferButtons}>
                    <Button
                      disabled={t3Customer()}
                      onClick={() => {
                        setTransferNow(true);
                        handleToggleModal('transfer');
                      }}
                      className={styles.transferButton}
                      variant={session.isMuted ? 'experienceInverseLight' : 'experienceInverse'}
                      type="button"
                      disableRipple
                      aria-label="Transfer now"
                    >
                      {t('Transfer Now')}
                    </Button>
                    <Button
                      disabled={t3Customer()}
                      onClick={() => {
                        setTransferNow(false);
                        handleToggleModal('transfer');
                      }}
                      className={styles.transferButton}
                      variant={session.isMuted ? 'experienceInverseLight' : 'experienceInverse'}
                      type="button"
                      disableRipple
                      aria-label="Transfer later"
                    >
                      {t('Transfer Later')}
                    </Button>
                  </Box>
                )}

                {isProductSpecialist && session && (
                  <>
                    <Box className={styles.transferButtons}>
                      <>
                        {(session.vehicleState && session.vehicleState.allowScreenSharingUser)
                          || (brand.allowScreenSharingPS && (
                            <Button
                              onClick={() => handleScreenShare(false)}
                              className={styles.transferButton}
                              variant={
                                session.isMuted ? 'experienceInverseLight' : 'experienceInverse'
                              }
                              type="button"
                              disableRipple
                              aria-label="Share screen"
                            >
                              {shareScreenStatus ? t('Stop Screen') : t('Share Screen')}
                            </Button>
                          ))}
                      </>
                      <>
                        {session.vehicleState && session.vehicleState.allowWebcamStreaming && (
                          <Button
                            onClick={handleStreamWebcam}
                            className={styles.transferButton}
                            variant={
                              session.isMuted ? 'experienceInverseLight' : 'experienceInverse'
                            }
                            type="button"
                            disableRipple
                            aria-label="Stream webcam"
                          >
                            {streamWebcamStatus ? t('Stop Webcam') : t('Stream Webcam')}
                          </Button>
                        )}
                      </>

                      {/* Unmute/mute video */}
                      <Button
                        className={styles.transferButton}
                        variant={session.isMuted ? 'experienceInverseLight' : 'experienceInverse'}
                        type="button"
                        disableRipple
                        aria-label="Mute video"
                        onClick={() => handelToggleMuteVideo()}
                      >
                        {session.isMuted ? t('Unmute Video') : t('Mute Video')}
                      </Button>
                    </Box>

                    {(brand.showScreenSharingToggle || brand.showCameraSharingToggle) && (
                      <Box className={styles.transferButtons}>
                        {/* Show/hide user screen sharing */}
                        {brand.showScreenSharingToggle && (
                          <Button
                            className={styles.transferButton}
                            variant={
                              session.isMuted ? 'experienceInverseLight' : 'experienceInverse'
                            }
                            type="button"
                            disableRipple
                            aria-label="User Screen Sharing"
                            onClick={() => handelToggleUserScreenSharing()}
                            disabled={session.isUserScreenSharing}
                          >
                            {session.allowScreenSharingUser
                              ? t('Hide Screen Sharing')
                              : t('Allow Screen Sharing')}
                          </Button>
                        )}

                        {/* Show/hide user webcam sharing */}
                        {brand.showCameraSharingToggle && (
                          <Button
                            className={styles.transferButton}
                            variant={
                              session.isMuted ? 'experienceInverseLight' : 'experienceInverse'
                            }
                            type="button"
                            disableRipple
                            aria-label="Webcam Sharing"
                            onClick={handelToggleUserCameraSharing}
                            disabled={session.isUserWebcamSharing}
                          >
                            {session.allowCameraSharing
                              ? t('Hide Webcam Sharing')
                              : t('Allow Webcam Sharing')}
                          </Button>
                        )}
                      </Box>
                    )}

                    <Box
                      className={clsx(
                        styles.transferButtons,
                        styles.transferButtonsBrandMicrophone,
                      )}
                    >
                      {/* Check which brands, they come from */}
                      <Button
                        className={styles.checkBrandButton}
                        variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                        type="button"
                        disableRipple
                        aria-label="Brand Name"
                        sx={{ width: isEnergy && '182px', mt: isEnergy && 2 }}
                      >
                        <Box sx={{ pr: 2 }}>
                          {brandId === 'evcan' || brandId === 'cadillaccan' ? (
                            <img
                              src={CANFlag.default}
                              alt="CanFlag"
                              width="30px"
                            />
                          ) : (
                            <img
                              src={USAFlag.default}
                              alt="USAFlag"
                              width="30px"
                            />
                          )}
                        </Box>{' '}
                        {brand.name || 'N/A'}
                      </Button>

                      {/* Available Microphone */}
                      <GridWrapper id="microphoneSelection">
                        <SelectField
                          className="stat-dropdown"
                          data-dtm="book 1-1 session:vehicle"
                          variant="outlined"
                          fullWidth
                          value={selectedMicrophone || t('Available Microphones')}
                          onChange={(event) => {
                            setSelectedMicrophone(event.target.value);
                          }}
                          input={<OutlinedInput />}
                        >
                          <option
                            value={t('Available Microphones') as string}
                            disabled
                          >
                            {t('Available Microphones')}
                          </option>
                          {microphones.map((mic) => (
                            <option
                              key={mic.deviceId}
                              value={mic.label}
                              disabled={!streamWebcamStatus}
                            >
                              {t(mic.label)}
                            </option>
                          ))}
                        </SelectField>
                      </GridWrapper>
                    </Box>
                  </>
                )}

                {/* {isProductSpecialist && (
                  <Button
                    className={styles.muteVideoButton}
                    variant={session.isMuted ? 'experienceIconsLight' : 'experienceIcons'}
                    type="button"
                    disableRipple
                    aria-label="Mute video"
                    onClick={() => handelToggleMuteVideo()}
                  >
                    <span className={`mute-video-button-text ${styles.muteVideoButtonText}`}>
                      {t('Mute Video')}
                    </span>
                    {session.isMuted ? (
                      <>
                        <VideocamOffIcon className="mute-video-icon-on" />
                        <VideocamIcon className="mute-video-icon-off" />
                      </>
                    ) : (
                      <>
                        <VideocamIcon className="mute-video-icon-off" />
                        <VideocamOffIcon className="mute-video-icon-on" />
                      </>
                    )}
                  </Button>
                )} */}

                {/* {isProductSpecialist && brand.showScreenSharingToggle && (
                  <Button
                    className={styles.muteVideoButton}
                    variant={session.isMuted ? 'experienceIconsLight' : 'experienceIcons'}
                    type="button"
                    disableRipple
                    aria-label="User Screen Sharing"
                    onClick={() => handelToggleUserScreenSharing()}
                  >
                    {session.allowScreenSharingUser ? (
                      <>
                        <span className={`mute-video-button-text ${styles.muteVideoButtonText}`}>
                          {t('Hide Screen Sharing')}
                        </span>
                        <StopScreenShareIcon className="mute-video-icon-on" />
                        <ScreenShareIcon className="mute-video-icon-off" />
                      </>
                    ) : (
                      <>
                        <span className={`mute-video-button-text ${styles.muteVideoButtonText}`}>
                          {t('Allow Screen Sharing')}
                        </span>
                        <ScreenShareIcon className="mute-video-icon-off" />
                        <StopScreenShareIcon className="mute-video-icon-on" />
                      </>
                    )}
                  </Button>
                )} */}

                {/* {isProductSpecialist && brand.showCameraSharingToggle && (
                  <Button
                    className={styles.muteVideoButton}
                    variant={session.isMuted ? 'experienceIconsLight' : 'experienceIcons'}
                    type="button"
                    disableRipple
                    aria-label="Webcam Sharing"
                    onClick={handelToggleUserCameraSharing}
                  >
                    {session.allowCameraSharing ? (
                      <>
                        <span className={`mute-video-button-text ${styles.muteVideoButtonText}`}>{t('Hide Webcam Sharing')}</span>
                        <StopScreenShareIcon className="mute-video-icon-on" />
                        <ScreenShareIcon className="mute-video-icon-off" />
                      </>
                    ) : (
                      <>
                        <span className={`mute-video-button-text ${styles.muteVideoButtonText}`}>{t('Allow Webcam Sharing')}</span>
                        <ScreenShareIcon className="mute-video-icon-off" />
                        <StopScreenShareIcon className="mute-video-icon-on" />
                      </>
                    )}
                  </Button>
                )} */}
              </Box>

              {isMobile && isKeyboardFocus && (
                <button
                  className={styles.keyboardIconButton}
                  type="button"
                  onClick={() => setIsKeyboardFocus(false)}
                  aria-label="Close keyboard"
                >
                  <Box className={styles.keyboardIcon} />
                </button>
              )}

              {session.chatVisible && (
                <>
                  {messages && (
                    <Chat
                      isUser={isUser}
                      isProductSpecialist={isProductSpecialist}
                      isProducer={isProducer}
                      brandId={brandId}
                      isChatOpen={session.chatVisible}
                      isCameraActive={userCameraStatus}
                      isCameraNotificationActive={isCameraNotification}
                    >
                      <>
                        {messages.map((message) => (
                          <ChatMessage
                            key={message.id}
                            isSender={message.userId === userId}
                            message={message as Message}
                            brandId={brandId}
                            hasBecomeGroupChat={hasBecomeGroupChat}
                            isProductSpecialist={isProductSpecialist}
                            onLeavingSessionModal={handleLeavingSessionModal}
                          />
                        ))}
                        {session.isUserTyping
                          ? isProductSpecialist && (
                            <ChatMessageDots
                              isSender={isUser}
                              brandId={brandId}
                              isProductSpecialist={isProductSpecialist}
                            />
                          )
                          : null}
                        {session.isProductSpecialistTyping
                          ? isUser && <ChatMessageDots
                              isSender={!isUser}
                              brandId={brandId}
                          />
                          : null}
                      </>
                    </Chat>
                  )}
                  <Box
                    className={clsx(
                      styles.chatNowNeedHelpContainer,
                      session.chatVisible ? isUser ? styles.chatNowNeedHelpContainerOpen : styles.chatNowNeedHelpContainerOpen_PS : ''
                    )}
                  >
                    {/* <Button
                      className={clsx(
                        'stat-button-close',
                        styles.chatNowClose,
                        isFrench ? styles.chatNowCloseFrench : '',
                      )}
                      data-dtm="chat"
                      variant={session.chatVisible ? 'experienceActive' : 'experience'}
                      type="button"
                      disableRipple
                      aria-label="Close chat"
                      onClick={() => handelToggleShowChat()}
                    >
                      {!isEnergy ? (
                        <CloseIcon className={styles.chatNowIcon} />
                      ) : (
                        <Box className="close-icon" />
                      )}
                      {t('Close')}
                    </Button> */}
                    <Formik
                      initialValues={{
                        text: '',
                        timestamp: undefined,
                        userId,
                        sent: true,
                      }}
                      validationSchema={Yup.object().shape({
                        sent: Yup.boolean(),
                        text: Yup.string().when('sent', {
                          is: true,
                          then: Yup.string().max(1000),
                        }),
                      })}
                      onSubmit={async (
                        values,
                        { resetForm, setStatus, setSubmitting },
                      ): Promise<void> => {
                        if (!values.sent) {
                          updateSession({
                            chatLoader: {
                              isProductSpecialist: !isUser,
                              isUser,
                            },
                          });
                        }
                        addDoc(messageRef, {
                          ...values,
                          timestamp: new Date(),
                          displayName: isProductSpecialist ? 'Product Specialist' : myUserName,
                        });
                        resetForm();
                        setStatus({ success: true });
                        setSubmitting(false);
                        typingState();
                      }}
                    >
                      {({
                        errors,
                        setFieldValue,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }): JSX.Element => (
                        <form
                          className={styles.chatNowForm}
                          // we want to prevent user from submitting if the PS is already ending the session.
                          onSubmit={!session.nameplateFormVisible ? handleSubmit : undefined}
                          {...other}
                        >
                          <Box className={styles.chatNowInputContainer}>
                            <input
                              name="imageUrl"
                              type="hidden"
                            />
                            <TextField
                              placeholder="..."
                              error={Boolean(touched.text && errors.text)}
                              fullWidth
                              helperText={touched.text && errors.text}
                              name="text"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const text = e.target.value;
                                if (regexUrlHelper.test(text)) {
                                  setFieldValue('sent', false);
                                } else {
                                  setFieldValue('sent', true);
                                }
                                setFieldValue('text', text);
                              }}
                              onInput={(e) => onTypingInput(e)}
                              onTouchStart={() => {
                                if (isUser && isMobile && isMobileScreenSize) {
                                  setIsKeyboardFocus(true);
                                }
                              }}
                              required
                              value={values.text}
                              variant="outlined"
                              className={styles.chatNowInput}
                              disabled={session.nameplateFormVisible}
                            />
                            {isUser ? (
                              session.chatLoader?.isUser ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    padding: '20px',
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <Button
                                  disabled={isSubmitting || session.nameplateFormVisible}
                                  className={styles.chatNowInputButton}
                                  variant="post"
                                  type="submit"
                                  disableRipple
                                  aria-label="Post message"
                                >
                                  {t('Post')}
                                </Button>
                              )
                            ) : session.chatLoader?.isProductSpecialist ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '20px',
                                }}
                              >
                                <CircularProgress />
                              </Box>
                            ) : (
                              <Button
                                disabled={isSubmitting || session.nameplateFormVisible}
                                className={styles.chatNowInputButton}
                                variant="post"
                                type="submit"
                                disableRipple
                                aria-label="Post message"
                              >
                                {t('Post')}
                              </Button>
                            )}
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </>
              )}

              {!session.chatVisible && (
                <Box className={styles.chatNowNeedHelpContainer}>
                  {/* <Button
                    className={`stat-icon-link ${styles.chatNow} ${isFrench ? styles.chatNowFrench : ''
                    }`}
                    data-dtm="1-1 session"
                    variant="experience"
                    type="button"
                    disableRipple
                    aria-label="Open chat"
                    onClick={() => handelToggleShowChat()}
                  >
                    {isChevy && <ChatOutlinedIcon className={styles.chatNowIcon} />}
                    {!isChevy && <Box className="chat-now-icon" />}
                    {t('Chat Now')}
                  </Button> */}
                  {isChevy && (
                    <TextButton
                      className={`stat-text-link ${styles.needHelpIconButton}`}
                      data-dtm="1-1 session"
                      disableRipple
                      variant="text"
                      type="button"
                      aria-label="Open need help modal"
                      onClick={() => {
                        updateSession({
                          formOpen: !session.formOpen,
                          formTitle: 'Need help form',
                        });
                        handleToggleModal('help');
                      }}
                    >
                      <Box
                        className={styles.needHelpIcon}
                        sx={{ backgroundImage: theme.palette.background.needHelpIconWhite }}
                      />
                      <Typography variant="iconTextLink">{t('Need Help?')}</Typography>
                    </TextButton>
                  )}
                </Box>
              )}

              {/* Had to remove session.moreMenuOpen condition or else the more menu animation wouldn't work */}
              {/* {session.moreMenuOpen && ( */}
              <SessionMenu
                brandId={brandId}
                isFrench={isFrench}
                userCameraStatus={userCameraStatus}
                isMoreMenuOpen={session.moreMenuOpen}
                isChatOpen={session.chatVisible}
                onClick={() =>
                  updateSession({
                    moreMenuOpen: false,
                  })}
              >
                {isChevy && (
                <SessionMenuItem
                  className={`stat-text-link ${styles.needHelpIconButtonMobileOnly}`}
                  dataDTM="menu"
                  label={t<string>('Need Help?')}
                  hideVideo={session.isMuted}
                  onClick={() => {
                    updateSession({
                      formOpen: !session.formOpen,
                      formTitle: 'Need help form',
                    });
                    handleToggleModal('help');
                  }}
                  brandId={brandId}
                >
                  <Box
                    className={clsx(styles.sessionHelpIcon, 'help-icon')}
                  />
                </SessionMenuItem>
                )}

                {!hideBookTestDrive
                    && (isCadillacUS
                      || isCadillacCanada
                      || (isGMC && isProductSpecialist)
                      || isEVLive
                      || isBuick) && (
                      <SessionMenuItem
                        className="stat-text-link"
                        dataDTM="menu"
                        label={
                          session.vehicleState?.overrideTestDriveButton
                            ? t<string>(session.vehicleState.urlOverrideText || 'Book Test Drive')
                            : t<string>('Book Test Drive')
                        }
                        hideVideo={session.isMuted}
                        onClick={() =>
                          handleBookTestDriveButton(session.vehicleState?.overrideTestDriveButton)}
                        brandId={brandId}
                      >
                        <Box
                          className={clsx(styles.sessionMenuIcon, 'book-test-drive-icon')}
                        />
                      </SessionMenuItem>
                )}

                {(isEVLiveCanada || (isEnergy && t3Customer() && (session.dealership && session.dealership.cid) && brand.allowLeadForm)) && (
                <SessionMenuItem
                  className="stat-button-link"
                  dataDTM="menu"
                  label={t<string>('Contact a Dealer')}
                  hideVideo={session.isMuted}
                  onClick={() => {
                    updateSession({
                      formOpen: true,
                      formTitle: 'Contact a Dealer Form',
                      moreMenuOpen: false,
                    });
                    handleToggleModal('contactDealer');
                  }}
                  brandId={brandId}
                >
                  <Box
                    className={clsx(styles.sessionMenuIcon, 'contact-dealer-icon')}
                  />
                </SessionMenuItem>
                )}
                {!brand.hideInviteAFriendForm && !t3Customer() && (
                <SessionMenuItem
                  className="stat-text-link"
                  dataDTM="menu"
                  label={t<string>('Invite a friend')}
                  hideVideo={session.isMuted}
                  onClick={() => {
                    updateSession({
                      formOpen: true,
                      formTitle: 'Invite a Friend Form',
                      moreMenuOpen: false,
                    });
                    handleToggleModal('inviteAFriend');
                  }}
                  brandId={brandId}
                >
                  <Box
                    className={clsx(styles.sessionMenuIcon, 'invite-a-friend-icon')}
                  />
                </SessionMenuItem>
                )}

                {!isEVLive && !isEVLiveCanada && !isEnergy && (
                  <>
                    {!isBuick && !t3Customer && (
                    <SessionMenuItem
                      isBorder
                      hideVideo={session.isMuted}
                      brandId={brandId}
                    />
                    )}
                    {session
                        && (!session.vehicleState
                          || (session.vehicleState && !session.vehicleState.hideColors)) && (
                          <SessionMenuItem
                            className="stat-text-link"
                            dataDTM="menu"
                            label={t<string>('Color')}
                            hideVideo={session.isMuted}
                            onClick={() => handelToggleColorTray()}
                            brandId={brandId}
                          >
                            <Box
                              className={clsx(styles.sessionMenuIcon, styles.sessionColorIcon)}
                            />
                          </SessionMenuItem>
                    )}

                    {session
                        && (!session.vehicleState
                          || (session.vehicleState && !session.vehicleState.hideModelTray)) && (
                          <SessionMenuItem
                            className="stat-text-link"
                            dataDTM="menu"
                            label={t<string>('Model')}
                            hideVideo={session.isMuted}
                            onClick={() => handelToggleModelTray()}
                            brandId={brandId}
                          >
                            <Box
                              className={clsx(styles.sessionMenuIcon, styles.sessionModelIcon, 'model-icon')}
                            />
                          </SessionMenuItem>
                    )}
                  </>
                )}

                {/* Screen sharing buttons for start and stop sharing */}
                {!isMobile
                    && session
                    && session.allowScreenSharingUser
                    && !isInvited
                    && ((isUser && !session.isUserScreenSharing)
                      || (isProductSpecialist && !session.isProductSpecialistScreenSharing)) && (
                      <SessionMenuItem
                        className="stat-text-link"
                        dataDTM="menu"
                        label={t('Share Screen')}
                        hideVideo={session.isMuted}
                        onClick={() => {
                          updateSession({
                            moreMenuOpen: false,
                          });
                          // if (!isScreenShareApprove) {
                          //   handleToggleModal('screenSharingConsent');
                          //   track('screen-share-privacy-display');
                          // } else {
                          //   handleScreenShare(!isProductSpecialist);
                          // }
                          handleToggleModal('screenSharingConsent');
                          track('screen-share-privacy-display');
                        }}
                        brandId={brandId}
                      >
                        <Box
                          className={clsx(styles.sessionMenuIcon, 'screen-share-icon')}
                        />
                      </SessionMenuItem>
                )}

                {!isMobile
                    && session
                    && session.allowScreenSharingUser
                    && !isInvited
                    && ((isUser && session.isUserScreenSharing)
                      || (isProductSpecialist && session.isProductSpecialistScreenSharing)) && (
                      <SessionMenuItem
                        className="stat-text-link"
                        dataDTM="menu"
                        label={t('Stop Screen')}
                        hideVideo={session.isMuted}
                        onClick={() => {
                          updateSession({
                            moreMenuOpen: false,
                          });
                          if (isUser && session.allowScreenSharingUser) {
                            updateUserScreenShareState(false);
                          }
                          if (isProductSpecialist && session.isProductSpecialistScreenSharing) {
                            updatePSScreenShareState(false);
                          }
                        }}
                        brandId={brandId}
                      >
                        <Box
                          className={clsx(styles.sessionMenuIcon, styles.sessionScreenShareIcon, 'screen-share-icon')}
                        />
                      </SessionMenuItem>
                )}

                {session.allowCameraSharing && isMobile && !isInvited && (
                  <SessionMenuItem
                    dataDTM="menu"
                    label={
                        !userCameraStatus
                          ? t<string>('Start Two-Way Camera')
                          : t<string>('Stop Two-Way Camera')
                      }
                    className={clsx(
                      'stat-text-link',
                      styles.userCamera,
                      userCameraStatus && styles.userCameraActive,
                      !checkSupportedBrowser() && styles.userCameraNotSupported,
                    )}
                    onClick={() => {
                      if (checkSupportedBrowser()) {
                        if (userCameraStatus) {
                          track('camera-share-user-end');
                          handleUserCamera();
                        }
                        // else if (isCameraShareApprove) {
                        //   updateSession({
                        //     moreMenuOpen: false,
                        //   });
                        //   handleUserCamera();
                        // }
                        else {
                          handleToggleModal('twoWayCamera');
                          track('camera-share-privacy-display');
                        }
                      } else {
                        toggleNotSupportedBrowserModal();
                      }
                    }}
                    brandId={brandId}
                    aria-label="Stream webcam"
                  >
                    <Box
                      className={clsx(
                        styles.sessionMenuIcon,
                        checkSupportedBrowser()
                          ? userCameraStatus
                            ? 'camera-stream-stop-icon'
                            : 'camera-stream-icon'
                          : 'camera-stream-not-supported-icon',
                      )}
                    />
                  </SessionMenuItem>
                )}

                {isEnergy && (
                <SessionMenuItem
                  className="stat-text-link"
                  dataDTM="menu"
                  label={t<string>('Help')}
                  hideVideo={session.isMuted}
                  onClick={() => {
                    updateSession({
                      formOpen: true,
                      formTitle: 'Help Form',
                      moreMenuOpen: false,
                    });
                    handleToggleModal('help');
                  }}
                  brandId={brandId}
                >
                  <Box
                    className={clsx(styles.sessionHelpIcon, 'help-icon')}
                  />
                </SessionMenuItem>
                )}

                {(isProductSpecialist || isProducer)
                    && symblConfigIds
                    && symblConfigIds.includes('Insights') && (
                      <SessionMenuItem
                        className="stat-text-link"
                        dataDTM="menu"
                        label={
                          !symblPurgeRequest
                            ? t<string>('Purge Symbl Data')
                            : t<string>('Symbl Data Will Be Purged')
                        }
                        onClick={() => {
                          handleToggleModal('purgeSymblData');
                        }}
                        brandId={brandId}
                      >
                        <Box
                          className={clsx(styles.sessionHelpIcon, 'help-icon')}
                        />
                      </SessionMenuItem>
                )}
              </SessionMenu>
              {/* )} */}

              {session
                && !hideBookTestDrive
                && ((!session.chatVisible && isChevy) || (isGMC && isUser)) && (
                  <Button
                    onClick={() =>
                      handleBookTestDriveButton(session.vehicleState?.overrideTestDriveButton)}
                    className={`stat-button-link ${styles.bookTestDrive}`}
                    data-dtm="1-1 session"
                    variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                    type="button"
                    disableRipple
                    aria-label="Open book test drive modal"
                  >
                    {session.vehicleState?.overrideTestDriveButton
                      ? t(session.vehicleState.urlOverrideText || 'Book Test Drive')
                      : t('Book Test Drive')}
                  </Button>
              )}

              {session
                && (!session.vehicleState
                  || (session.vehicleState && !session.vehicleState.hideMenuBox))
                && !isKeyboardFocus && (
                  <Button
                    className={`
                    ${moreButtonStats()}
                    ${styles.moreMenu}
                    ${userCameraStatus ? styles.moreMenuCameraOn : ''}
                    ${isUser && userCameraStatus && session.chatVisible ? styles.moreMenu_CameraAndChatAreActive : ''}
                    ${session.moreMenuOpen ? styles.moreMenuActive : ''}
                    ${isProductSpecialist ? styles.moreMenuProductSpecialist : ''}
                    ${isProducer ? styles.moreMenuProducer : ''}
                    ${session.chatVisible ? styles.moreMenuEVLiveMobileHidden : ''}
                    ${isFrench ? styles.moreMenuFrench : ''}
                  `}
                    data-dtm={isEVLiveCanada || isEnergy ? 'menu' : '1-1 session:menu'}
                    variant={session.moreMenuOpen ? 'experienceActive' : 'experience'}
                    type="button"
                    disableRipple
                    onClick={() => handelToggleMoreMenu()}
                    aria-label={session.moreMenuOpen ? 'Close more menu' : 'Open more menu'}
                  >
                    {!isEnergy && (
                      <>
                        {session.moreMenuOpen ? (
                          <CloseIcon className={styles.moreMenuIcon} />
                        ) : (
                          <MenuOutlinedIcon className={styles.moreMenuIcon} />
                        )}
                      </>
                    )}
                    {isEnergy && (
                      <Box className="menu-icon" />
                    )}
                    <span>{session.moreMenuOpen ? t('Close') : t('More')}</span>
                  </Button>
              )}

              {(session.chatVisible && userCameraStatus && isCameraNotification) && (
                <Box className={styles.cameraNotification}>
                  <Box className={styles.cameraNotificationIconText}>
                    <Box className={styles.cameraNotificationIcon} />
                    <Typography
                      className={styles.cameraNotificationText}
                      variant="body3"
                    >
                      {t('Your Camera is on')}
                    </Typography>
                  </Box>
                  <Box className={styles.cameraNotificationLinks}>
                    <Link
                      className={styles.cameraNotificationLink}
                      variant="link3"
                      underline="always"
                      component="button"
                      onClick={() => {
                        if (userCameraStatus) {
                          track('camera-share-user-end');
                          handleUserCamera();
                          // setIsCameraNotification(false);
                        }
                      }}
                    >
                      {t('Turn it off')}
                    </Link>
                    <Link
                      className={styles.cameraNotificationLink}
                      variant="link3"
                      underline="always"
                      component="button"
                      onClick={() => setIsCameraNotification(false)}
                    >
                      {t('Leave as is')}
                    </Link>
                  </Box>
                </Box>
              )}

              <Box className={clsx(styles.bottomNavBar, `${(session.allowCameraSharing && isMobile && !isInvited && !isProductSpecialist) ? styles.bottomNavBarHasCameraControls : ''}`)}>
                {/* Chat Now / Close */}
                <Button
                  className={`${session.chatVisible ? 'stat-button-close' : 'stat-icon-link'} ${styles.chatNow} ${isFrench ? styles.chatNowFrench : ''}`}
                  data-dtm="1-1 session"
                  variant={session.chatVisible ? 'experienceActive' : 'experience'}
                  type="button"
                  disableRipple
                  aria-label={session.chatVisible ? 'Close chat' : 'Open chat'}
                  onClick={() => handelToggleShowChat()}
                >
                  {session.chatVisible ? (
                    <>
                      <Box className="chat-now-icon" />
                      {t('Close')}
                    </>
                  ) : (
                    <>
                      <Box className="chat-now-icon" />
                      {t('Chat Now')}
                    </>
                  )}
                </Button>

                {/* Closed Caption */}
                {!isProductSpecialist
                  && symblConfigIds
                  && symblConfigIds.includes('Closed Captioning') && (
                  <Button
                    className={`
                  ${clsx('stat-icon-link', styles.captionIconButtonEnergy)}
                  ${userCameraStatus ? styles.closedCaptionTextCameraOn : ''}
                  ${isUser && userCameraStatus && session.chatVisible ? styles.closedCaption__CameraAndChatAreActive : ''}
                  ${closedCaptionVisible ? styles.captionIconButtonIsActive : ''}
                  `}
                    data-dtm="1-1 session"
                    variant={closedCaptionVisible ? 'experienceActive' : 'experience'}
                    type="button"
                    disableRipple
                    onClick={handleCaptionToggle}
                    aria-label="Closed Caption"
                  >
                    {closedCaptionVisible
                      ? <ClosedCaptionIcon className={styles.captionIcon} />
                      : <ClosedCaptionOffIcon className={styles.captionIcon} />}
                    <span>{closedCaptionVisible ? t('Close') : t('Captions')}</span>
                  </Button>
                )}

                {/* Two Way Camera Camera controls */}
                {(session.allowCameraSharing && isMobile && !isInvited && !isProductSpecialist) && (
                  <>
                    <button
                      className={clsx(
                        'stat-icon-link',
                        styles.cameraControl,
                        styles.cameraControl_FlipCamera,
                        `${userCameraStatus ? styles.cameraControl_Active_FlipCamera : ''}`
                      )}
                      data-dtm="menu:camera controls"
                      aria-label="Flip Camera"
                      type="button"
                      onClick={() => {
                        if (userCameraStatus) {
                          handleUserCameraFlip();
                        }
                      }}
                    >
                      <Box
                        className={clsx(
                          styles.cameraControlIcon,
                          `${userCameraStatus
                            ? isBackCamera
                              ? styles.flipCameraIcon_back
                              : styles.flipCameraIcon
                            : styles.flipCameraIcon_disabled}`
                        )}
                      />
                      <span className={clsx(styles.cameraControlText, `${!userCameraStatus ? styles.cameraControlText_Disabled : ''}`)}>{isBackCamera ? t('Camera:Back') : t('Camera:Front')}</span>
                    </button>
                    <button
                      className={clsx(
                        'stat-icon-link',
                        styles.cameraControl,
                        styles.cameraControl_SwitchCamera,
                        styles.cameraControl_SideBorders,
                        `${userCameraStatus ? styles.cameraControl_Active_SwitchCamera : ''}`
                      )}
                      data-dtm="menu:camera controls"
                      aria-label="Switch Screen"
                      type="button"
                      onClick={() => {
                        if (userCameraStatus) {
                          setLoadingCamera(true);
                          setIsFullSizePublisher(!isFullSizePublisher);
                        }
                      }}
                    >
                      <Box className={clsx(styles.cameraControlIcon, `${userCameraStatus ? styles.switchScreenIcon : styles.switchScreenIcon_disabled}`)} />
                      <span className={clsx(styles.cameraControlText, `${!userCameraStatus ? styles.cameraControlText_Disabled : ''}`)}>{t('Camera:Switch')}</span>
                    </button>
                    <button
                      className={clsx(
                        'stat-icon-link',
                        styles.cameraControl,
                        styles.cameraControl_OnCamera,
                        `${userCameraStatus ? styles.cameraControl_Active : ''}`,
                        `${userCameraStatus ? styles.cameraControl_Active_OnCamera : ''}`
                      )}
                      data-dtm="menu:camera controls"
                      aria-label="Stop Camera"
                      type="button"
                      onClick={() => {
                        if (checkSupportedBrowser()) {
                          if (userCameraStatus) {
                            track('camera-share-user-end');
                            handleUserCamera();
                          } else {
                            handleToggleModal('twoWayCamera');
                            track('camera-share-privacy-display');
                          }
                        } else {
                          toggleNotSupportedBrowserModal();
                        }
                      }}
                    >
                      {userCameraStatus ? (
                        <>
                          <Box className={clsx(styles.cameraControlIcon, styles.stopCameraIcon)} />
                          <span className={styles.cameraControlText}>{t('Off')}</span>
                        </>
                      ) : (
                        <>
                          <Box className={clsx(styles.cameraControlIcon, styles.cameraIcon)} />
                          <span className={styles.cameraControlText}>{t('Camera')}</span>
                        </>
                      )}
                    </button>
                  </>
                )}
              </Box>

              <Box>
                {!isEVLiveCanada
                  && !isEVLive
                  && !isEnergy
                  && session
                  && session.vehicleFeaturesState
                  && !session.vehicleState.hideFeatures && (
                    <Box className={styles.vehicleFeaturesContainer}>
                      <VehicleFeatures
                        brandId={brandId}
                        isActive={session.vehicleFeatureOpen}
                        onClick={handelToggleVehicleFeature}
                      >
                        {session
                          && session.selectedVehicleFeatureState
                          && session.vehicleFeatureOpen && (
                            <>
                              <VehicleFeaturePreview
                                brandId={brandId}
                                vehicleFeature={session.selectedVehicleFeatureState}
                              />
                              <Box className={styles.vehicleFeaturesItems}>
                                {session.vehicleFeaturesState
                                  .filter(
                                    (feature) => feature.vehicleId === session.vehicleState.id,
                                  )
                                  .map((vehicleFeature) => (
                                    <VehicleFeatureView
                                      key={vehicleFeature.id}
                                      brandId={brandId}
                                      onSelectVehicleFeature={handelSelectFeature}
                                      vehicleFeature={vehicleFeature as VehicleFeature}
                                      vehicleFeatureState={session.selectedVehicleFeatureState}
                                      vehicleStateName={session?.vehicleState?.name || ''}
                                    />
                                  ))}
                              </Box>
                            </>
                        )}
                      </VehicleFeatures>
                    </Box>
                )}
                {isProductSpecialist && (!session.moreMenuOpen || isEnergy) && (
                  <Box className={styles.baInterfaceButtons}>
                    <Button
                      className={styles.baInterfaceButton}
                      variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                      type="button"
                      disableRipple
                      onClick={() => handleToggleModal('troubleshoot')}
                      aria-label="Open troubleshoot modal"
                    >
                      {t('Troubleshoot')}
                    </Button>
                    {faqs?.length > 0 && (
                      <Button
                        className={styles.baInterfaceButton}
                        variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                        type="button"
                        disableRipple
                        onClick={() => handleToggleModal('questions')}
                        aria-label="Open questions modal"
                      >
                        {t('Questions')}
                      </Button>
                    )}

                    <>
                      {session.status === 'Ready' && (
                        <>
                          {(session.countdown && session.countdown) > 0 && (
                            <Button
                              className={styles.baInterfaceButton}
                              variant="statusWaiting"
                              type="button"
                              disableRipple
                              aria-label="Countdown"
                            >
                              COUNTDOWN - {session.countdown}
                            </Button>
                          )}
                          {(session.formOpen || session.isUserScreenSharing || session.isUserWebcamSharing) && (
                            <>
                              <Button
                                variant="formOpen"
                                type="button"
                                disableRipple
                                aria-label="Open-Form"
                                startIcon={<NotificationImportantIcon sx={{ height: 30, width: 30 }} />}
                              >
                                <Box className={styles.baNotiButton}>
                                  <Box className={styles.baNotiButtonText}>
                                    {session.formOpen && `${session.formTitle} is open` }
                                  </Box>
                                  <Box className={styles.baNotiButtonText}>
                                    {session.isUserScreenSharing && 'Screen Share is on'}
                                  </Box>
                                  <Box className={styles.baNotiButtonText}>
                                    {session.isUserWebcamSharing && 'Two-way Camera is on'}
                                  </Box>
                                </Box>
                              </Button>
                            </>
                          )}
                          <Button
                            className={styles.baInterfaceButton}
                            variant={status ? 'statusOnline' : 'statusOffline'}
                            type="button"
                            disableRipple
                            aria-label={status ? 'Online' : 'Offline'}
                          >
                            <>
                              {session.transferAccepted ? (
                                <>{t('TRANSFER ACCEPTED')}</>
                              ) : (
                                <>
                                  {session.firstName} - {status ? t('ONLINE') : t('OFFLINE')}
                                </>
                              )}
                            </>
                            <>
                              {userRoute && (
                                <>
                                  <br />
                                  {t(userRoute)}
                                </>
                              )}
                            </>
                          </Button>
                        </>
                      )}
                    </>

                    <Button
                      className={styles.baInterfaceButton}
                      variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                      type="button"
                      disableRipple
                      onClick={() => handleToggleModal('links')}
                      aria-label="Open shareable hyperlinks modal"
                    >
                      {t('Shareable Hyperlinks')}
                    </Button>
                    <Button
                      className={styles.baInterfaceButton}
                      variant={requestHelpButtonVariantChange()}
                      type="button"
                      disableRipple
                      onClick={handelRequestHelp}
                      aria-label={session.helpRequested ? 'Cancel request' : 'request help'}
                    >
                      {session.helpRequested ? t('Cancel Request') : t('Request Help')}
                    </Button>
                    {/* only for energy - ENERGYL-103 */}
                    {session.brandId === 'energy' && (
                      <Button
                        className={styles.baInterfaceButton}
                        variant={session.isMuted ? 'productSpecialistLight' : 'productSpecialist'}
                        type="button"
                        disableRipple
                        onClick={() => handleCopy(session.id)}
                        aria-label="Copy UID"
                      >
                        {t('Copy UID')}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>

              {/* {symblConfigIds.includes('Closed Captioning') && (
                <Button
                  className={isChevy ? styles.captionIconButtonChevy : styles.captionIconButton}
                  type="button"
                  disableRipple
                  onClick={() => {
                    setClosedCaptionVisible(!closedCaptionVisible);
                  }}
                  aria-label="Start Transcrption"
                >
                  {!closedCaptionVisible ? (
                    <ClosedCaptionDisabledIcon className={styles.captionIcon} />
                  ) : (
                    <ClosedCaptionIcon className={styles.captionIcon} />
                  )}
                </Button>
              )} */}

              {/* {symblConfigIds.includes('Closed Captioning') && closedCaptionVisible && user.user && (
                <Box className={styles.captionContainer}>
                  <Box className={styles.caption}>
                    <Typography variant="closedCaptionText">
                      <b>{presenterName}</b>&nbsp;{presenterCCs}
                    </Typography>
                  </Box>
                </Box>
              )} */}

              {symblConfigIds.includes('Closed Captioning') && closedCaptionVisible && isUser && customerCCs && (
                <Box className={`${styles.captionContainer} ${session.isUserWebcamSharing ? styles.captionCameraOn : ''} ${session.chatVisible && (isMobile || isMobileScreenSize) ? styles.captionContainer_HideCaptionOnChatOpen : ''}`}>
                  <Box
                    className={`${styles.caption}`}
                  >
                    <Typography variant="closedCaptionText">
                      {customerCCs}
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* Trackers - On Detection Prompt */}
              {captionWarning
                && isProductSpecialist && (
                  <Box className={styles.captionWarningContainer}>
                    <Box className={styles.caption}>
                      <Typography variant="closedCaptionText">
                        {captionWarning}
                        <CloseIcon
                          className={styles.captionCloseIcon}
                          onClick={() => {
                            setCaptionWarning('');
                          }}
                        />
                      </Typography>
                    </Box>
                  </Box>
              )}

              {/* Trackers - On Time Elapsed Prompt */}
              {timeElapsedTrackers && isProductSpecialist && timeElapsedTrackers
                .filter((reminder) =>
                  reminder.hasBeenTracked === 'NOT_TRACKED'
                   && reminder.trackerText)
                .map((reminder) => (
                  <Box
                    key={reminder.trackerId}
                    className={styles.captionReminderContainer}
                  >
                    <Box className={styles.caption}>
                      <Typography variant="closedCaptionText">
                        {reminder.trackerText}
                        <CloseIcon
                          className={styles.captionCloseIcon}
                          onClick={() => {
                            // Update the reminder's hasBeenTracked property
                            setTimeElapsedTrackers((prevTrackers) =>
                              prevTrackers.map((tracker) =>
                                (tracker.trackerId === reminder.trackerId
                                  ? { ...tracker, hasBeenTracked: 'HAS_BEEN_TRACKED' }
                                  : tracker)));
                            setDesiredTopicCheck(true);
                          }}
                        />
                      </Typography>
                    </Box>
                  </Box>
                ))}

              <Box
                className={`${styles.streamPause} ${session.isMuted ? styles.streamPauseActive : ''
                }`}
              >
                <Box className={styles.streamPauseContainer}>
                  <Typography variant="streamPause">
                    {t("HANG TIGHT, WE'LL BE WITH YOU SHORTLY.")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {session && vehicleTrayVehicles && (!isEVLive && !isEVLiveCanada && !isEnergy) && (
              <Box className={styles.vehicleSelectContainer}>
                <VehicleSelect
                  isProductSpecialist={isProductSpecialist}
                  session={session as Session}
                  brandId={brandId}
                  vehicles={vehicleTrayVehicles as Vehicle[]}
                  onUpdateSession={updateSession}
                  onClose={handelCloseVehicleTray}
                  onChange={handelChangeVehicle}
                />
              </Box>
            )}
            {(session.dealership && session.dealership.cid) && (
            <>
              <Dialog
                id="modal-testDrive"
                open={currentModal === 'bookTestDrive'}
                onClose={handleToggleModal}
                disableScrollLock
              >
                <BookTestDriveView
                  testDrive={session.testDrive}
                  dealershipBacCode={
                  session.dealership && session.dealership.bacCode
                    ? session.dealership.bacCode
                    : getBacCodeQueryString()
                      ? getBacCodeQueryString()
                      : null
                }
                  t3Dealership={session.dealership ? session.dealership : null}
                  t3DealershipBrand={session.t3DealerBrand ? session.t3DealerBrand : null}
                  isDealerMode={t3Customer()}
                  onClose={handleToggleModal}
                  onSave={handleSave}
                  onSubmit={handleTestDriveSubmit}
                  brandId={brandId}
                  vehicles={bookVehicleTestDrives as Vehicle[]}
                />
              </Dialog>
              <Dialog
                id="modal-contactDealer"
                open={currentModal === 'contactDealer'}
                disableScrollLock
              >
                <ContactDealerView
                  contactDealer={session.contactDealer}
                  t3Dealership={session.dealership ? session.dealership : null}
                  onClose={handleToggleModal}
                  onSave={handleSave}
                  onSubmit={handleContactDealerSubmit}
                  brandId={brandId}
                  vehicles={bookTestDrives as Vehicle[]}
                  onCloseStatus={(contactDealerValues) => {
                    // Saves the contactDealer to the session doc, if the user closes the modal.
                    updateSession({ contactDealer: contactDealerValues, });
                    handleToggleModal('closeToastModal');
                  }}
                />
              </Dialog>
            </>
            )}
            <Dialog
              id="modal-transfer"
              open={currentModal === 'transfer'}
              onClose={handleToggleModal}
            >
              <TransferView
                onClose={handleToggleModal}
                session={session as Session}
                transferNow={transferNow}
                brandId={brandId}
              />
            </Dialog>
            <Dialog
              id="modal-invite-a-friend"
              open={currentModal === 'inviteAFriend'}
              onClose={handleToggleModal}
              disableScrollLock
            >
              {sessionId && (
                <InviteAFriendModal
                  sessionId={sessionId}
                  languageCode={lang}
                  brandId={brandId}
                  firstName={session.firstName ? session.firstName : ''}
                  lastName={session.lastName ? session.lastName : ''}
                  email={session.email ? session.email : ''}
                  isProductSpecialist={isProductSpecialist}
                  onClose={handleToggleModal}
                  onSubmit={() => {
                    updateSession({
                      invitedAFriend: true,
                      formToastVisible: true,
                      formToastTitle: 'Invite a Friend Form Submitted',
                    });
                  }}
                />
              )}
            </Dialog>
            <Dialog
              id="purge-symbl-modal"
              open={currentModal === 'purgeSymblData'}
              onClose={handleToggleModal}
              disableScrollLock
            >
              {sessionId && (
                <SymblPurgeModal
                  brandId={brandId}
                  onConfirm={() => {
                    setSymblPurgeRequest(true);
                    handleToggleModal();
                  }}
                  onClose={handleToggleModal}
                />
              )}
            </Dialog>
            <Dialog
              id="modal"
              open={currentModal === 'links'}
              onClose={handleToggleModal}
            >
              {shareableHyperlinks && (
                <ShareableHyperlinksModal
                  brandId={brandId}
                  shareableHyperlinks={shareableHyperlinks as ShareableHyperlink[]}
                  onClose={handleToggleModal}
                  onSend={handleMessageSend}
                  onLeavingSessionModal={handleLeavingSessionModal}
                />
              )}
            </Dialog>
            <Dialog
              id="modal-leaving-session-tab-confirmation"
              open={currentModal === 'leavingSession'}
              onClose={handleToggleModal}
            >
              <LeavingSessionTabConfirmationModal
                brandId={brandId}
                onOpenTab={handleOpenTab}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="modal-nameplates"
              open={isProductSpecialist && session && session.status === 'Ended'}
            >
              <NameplatePopupModal
                brandId={brandId}
                onSubmit={handleNameplateSubmit}
                onClose={handleToggleModal}
                nameplates={
                  isEVLive || isEVLiveCanada || isEnergy
                    ? (vehicleNamePlates as Topic[])
                    : (vehicleNamePlates as Vehicle[])
                }
              />
            </Dialog>
            <Dialog
              id="modal"
              open={currentModal === 'troubleshoot'}
              onClose={handleToggleModal}
            >
              <TroubleshootModal
                onClose={handleToggleModal}
                brandId={brandId}
              />
            </Dialog>
            <Dialog
              id="modal-enable-your-audio"
              open={currentModal === 'enableAudio'}
              onClose={handleToggleModal}
            >
              <EnableYourAudioModal
                onClose={handleToggleModal}
                brandId={brandId}
              />
            </Dialog>
            <Dialog
              id="modal"
              open={currentModal === 'questions'}
              onClose={handleToggleModal}
            >
              <QuestionsModal
                brandId={brandId}
                onClose={handleToggleModal}
                faqs={faqs}
              />
            </Dialog>
            <>
              {session && session.transferRequest && (
                <Dialog
                  id="modal-reservation"
                  open={currentModal === 'reservation'}
                  onClose={() => {
                    updateSession({ transferRequest: null });
                    handleToggleModal();
                  }}
                >
                  <ThemeProvider theme={getTheme()}>
                    <ReservationModal
                      sessionId={session.id}
                      transferRequest={session.transferRequest}
                      onClose={() => {
                        updateSession({ transferRequest: null });
                        handleToggleModal();
                      }}
                      onTransferReq={() => {
                        updateSession({
                          formToastVisible: true,
                          formToastTitle: 'Transfer Later Form Submitted',
                          formOpen: false,
                        });
                        handleToast('Transfer Later Form Submitted', 'confirmation', 'modal:form submitted');
                      }}
                    />
                  </ThemeProvider>
                </Dialog>
              )}
            </>
            <Dialog
              id="modal-needhelp"
              open={currentModal === 'help'}
              onClose={handleToggleModal}
            >
              <NeedHelpModal
                brandId={brandId}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="modal-end-session"
              open={currentModal === 'endSession'}
              onClose={handleToggleModal}
            >
              <EndSessionModal
                brandId={brandId}
                onClose={handleToggleModal}
                onEndSession={handleEndSession}
              />
            </Dialog>
            <Dialog
              id="modal-transfer-now"
              open={currentModal === 'transferNow' && session.transferRequest}
              onClose={handleToggleModal}
            >
              <TransferNowModal
                brandId={brandId}
                transferRequest={session.transferRequest}
                onRedirect={async () => {
                  // TODO: we need to end the session for the Agent
                  // setTimeout(() => {
                  //   handleEndSession();
                  // }, 1000);
                  updateSession({ transferAccepted: true });
                }}
                onClose={async () => {
                  // const queueRef = doc(firestore, 'queue', session.transferRequest.loungeId);
                  // remove user from queue
                  // await deleteDoc(queueRef);
                  // remove the transfer request
                  await updateSession({ transferRequest: null });
                  handleToggleModal();
                }}
              />
            </Dialog>
            <Dialog
              id="modal-book-test-drive-submitted"
              open={currentModal === 'bookTestDriveSubmitted'}
              onClose={handleToggleModal}
            >
              <BookTestDriveSubmittedModal
                brandId={brandId}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="modal-contact-dealer-submitted"
              open={currentModal === 'contactDealerSubmitted'}
              onClose={handleToggleModal}
            >
              <ContactDealerSubmittedModal
                onClose={handleToggleModal}
                brandId={brandId}
              />
            </Dialog>
            <Dialog
              id="modal-stay-updated"
              open={currentModal === 'stayUpdated'}
              onClose={handleToggleModal}
            >
              <StayUpdatedModal
                brandId={brandId}
                languageCode={lang}
                reservationType="oneOnOne"
                vehicles={stayUpdatedVehicles as Vehicle[]}
                vehicleTypes={vehicleTypes as VehicleType[]}
                onSubmit={handleStayUpdatedSubmit}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="modal-screen-sharing-consent"
              open={currentModal === 'screenSharingConsent'}
              onClose={() => {
                track('screen-share-privacy-refused');
                handleToggleModal();
              }}
              disableScrollLock
            >
              {sessionId && (
                <ScreenSharingConsentModal
                  brandId={brandId}
                  onClose={() => {
                    track('screen-share-privacy-refused');
                    handleToggleModal();
                  }}
                  onSubmit={() => {
                    track('screen-share-privacy-confirmed');
                    handleScreenShare(!isProductSpecialist);
                    handleToggleModal();
                    // setIsScreenShareApprove(true);
                  }}
                />
              )}
            </Dialog>
            <Dialog
              id="modal-screen-sharing-error"
              open={currentModal === 'screenSharingConsentError'}
              onClose={handleToggleModal}
              disableScrollLock
            >
              <ScreenSharingErrorModal
                brandId={brandId}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="modal-consent-two-way-camera"
              open={currentModal === 'twoWayCamera'}
              onClose={() => {
                track('camera-share-privacy-refused');
                handleToggleModal();
              }}
              disableScrollLock
            >
              <TwoWayCameraModal
                brandId={brandId}
                onClose={() => {
                  track('camera-share-privacy-refused');
                  handleToggleModal();
                }}
                onSubmit={() => {
                  track('camera-share-privacy-confirmed');
                  handleUserCamera();
                  handleToggleModal();
                  // setIsCameraShareApprove(true);
                  updateSession({
                    moreMenuOpen: false,
                  });
                }}
              />
            </Dialog>
            <Dialog
              id="browser-camera-not-supported"
              open={currentModal === 'browserNotSupported'}
              onClose={handleToggleModal}
              disableScrollLock
            >
              <CameraBrowserNotSupportedModal
                brandId={brandId}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="symbl-error-modal"
              open={currentModal === 'symblErrorModal'}
              onClose={handleToggleModal}
              disableScrollLock
            >
              <SymblErrorModal
                brandId={brandId}
                onClose={handleToggleModal}
              />
            </Dialog>
            <Dialog
              id="consent-modal"
              open={(isUser && !isConsentClicked) || currentModal === 'consentModal'}
              disableScrollLock
            >
              <ConsentModal
                onClose={() => {
                  setIsConsentClicked(true);
                  handleToggleModal();
                }}
              />
            </Dialog>
            <Dialog
              id="close-toast-modal"
              open={currentModal === 'closeToastModal'}
              disableScrollLock
              onClose={handleToggleModal}
            >
              <CloseToastModal
                onClose={() => {
                  // Delete contactDealer property from the session doc, if the user clicks no
                  updateDoc(sessionRef, { contactDealer: deleteField() });
                  handleToggleModal();
                }}
                onOpen={() => handleToggleModal('contactDealer')}
              />
            </Dialog>
          </Box>
        </Box>
      ) : (
        <Box>
          {sessionLoaded && !session ? (
            <Box className={styles.noSession}>
              <Box className={styles.noSessionContainer}>
                <Typography
                  variant={isProductSpecialist ? 'queueText' : 'queueTextBlack'}
                  component="p"
                  sx={{ mb: '10px' }}
                >
                  {t('Session does not exist')}
                </Typography>
                <Button
                  type="button"
                  variant={isProductSpecialist ? 'primaryInverse' : 'primary'}
                  disableRipple
                  aria-label="Refresh session"
                  onClick={() => {
                    if (sessionRef) {
                      // Hack to remove lingering sessions. TODO Fix this
                      deleteDoc(sessionRef);
                    }
                    onSessionDoesNotExist();
                  }}
                  className={`${styles.noSessionButton} ${isProductSpecialist
                    ? styles.noSessionButtonProductSpecialist
                    : styles.noSessionButtonUser
                  }`}
                >
                  {t('Refresh')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className={styles.noSession}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default SessionView;

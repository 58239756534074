import { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import { Vehicle, VehicleType } from '@types';
import StayUpdatedView from './StayUpdatedView';
import ModalIconButton from './ModalIconButton';

type StayUpdatedModalProps = {
  brandId: string;
  languageCode: string;
  reservationType: string;
  vehicles: Vehicle[],
  vehicleTypes: VehicleType[],
  onSubmit: (stayUpdated) => void,
  onClose?: () => void;
};

const StayUpdatedModal = (props: StayUpdatedModalProps): ReactElement => {
  const { brandId, languageCode, reservationType, vehicles, vehicleTypes, onSubmit, onClose } = props;

  return (
    <Box className="modal-container">
      <ModalIconButton
        onClose={onClose}
        brandId={brandId}
      />
      <StayUpdatedView
        brandId={brandId}
        languageCode={languageCode}
        reservationType={reservationType}
        vehicles={vehicles}
        vehicleTypes={vehicleTypes}
        isModal
        onSubmit={onSubmit}
        onClickNoThank={onClose}
      />
    </Box>
  );
};

export default StayUpdatedModal;

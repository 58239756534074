import {
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  where,
  getDoc,
  DocumentData,
  QuerySnapshot,
  orderBy,
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
  FormControl,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Vehicle,
  BrandType,
  Session,
  DeviceKit,
  Brand,
  Language,
  Topic,
  TransferRequest,
  Toast,
} from '@types';
import { ReactElement, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  TextFieldForm,
  GridWrapper,
  SelectField,
  ModalIconButton,
  SelectBasicField,
  GMToast,
} from '@components';
import { useTheme } from '@material-ui/core/styles';
import ErrorComponent from './ErrorComponent';
import { useReporting } from '../hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Scroll from 'react-scroll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TransferViewStyles } from './TransferView.style';
import { userRepository } from '@repositories';
import { LoadingButton } from '@mui/lab';
import { generatePushID } from '@utils';

type TransferViewProps = {
  brandId: BrandType;
  transferNow: boolean;
  session: Session;
  onClose?: () => void;
};

const ALL_IDENTIFIER = 'All';

const TransferView = (props: TransferViewProps): ReactElement => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const firestore = useFirestore();
  const { session, brandId, onClose, transferNow } = props;

  const isChevy = brandId.toLowerCase() === 'chevy';
  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';
  let maxCommentLength = 150;

  const [selectedBrand, setSelectedBrand] = useState('');
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [deviceKits, setDeviceKits] = useState<DeviceKit[]>([]);
  const [validateErrors, setValidateErrors] = useState<boolean>(false);

  const brandRef = doc(firestore, 'brands', brandId);
  const { data: brand } = useFirestoreDocData(brandRef, { idField: 'id' });

  const sessionsRef = collection(firestore, 'sessions');
  const sessionQuery = query(sessionsRef, where('vrEnabled', '==', true));
  const { data: vrSessions } = useFirestoreCollectionData(sessionQuery, {
    idField: 'id',
  });

  const theme = useTheme();
  const [comments, setComments] = useState('');

  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;

  const executeScroll = (isValid: boolean) => {
    const modalEl = document.getElementById('errorContainer');

    if (isValid || !isValid) {
      setTimeout(() => {
        modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      }, 1000);
      modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
    }
  };

  if (isGMC || isBuick) {
    maxCommentLength = 1000;
  }

  const useStyles = makeStyles(TransferViewStyles(brandId));
  const styles = useStyles();

  // TODO: Move this to a util
  const getCollection = (querySnapshot: QuerySnapshot<DocumentData>) =>
    querySnapshot.docs.map((d) => ({
      ...d.data(),
      ...{ id: d.id },
    }));

  const activeVRSession = (vehicle: Vehicle) => {
    if (vehicle.allowWebcamStreaming) {
      return vrSessions && vrSessions.length > 0 && session.vrEnabled !== true;
      // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  };

  const getDeviceKits = async (brand: string) => {
    const deviceKitRef = collection(firestore, 'deviceKits');
    const deviceKitQuery = query(deviceKitRef, where('brands', 'array-contains', brand));
    const deviceKitsnapshot = await getDocs(deviceKitQuery);
    const deviceKits = getCollection(deviceKitsnapshot) as DeviceKit[];
    return deviceKits;
  };

  const handleToast = (message: string, variant: any) => {
    toast((t: Toast) => (
      <GMToast
        variant={variant}
        message={message}
        dismiss={() => toast.dismiss(t.id)}
      />
    ));
  };

  return (
    <Formik
      initialValues={{
        vehicleId: ALL_IDENTIFIER,
        deviceKitId: '',
        topicId: ALL_IDENTIFIER,
        brand: '',
        fromBrand: brandId,
        transferNow: true,
        language: '',
        comments: '',
      }}
      validationSchema={Yup.object().shape({
        brand: Yup.string().required(t('Please select a brand.')),
        language: Yup.string().required(t('Please select a language.')),
        vehicleId:
          !transferNow && (selectedBrand !== 'ev' && selectedBrand !== 'energy')
            ? Yup.string()
              .required(t('Please select a vehicle'))
              .notOneOf([ALL_IDENTIFIER], t('Please select a vehicle.'))
            : Yup.string(),
        topicId:
          !transferNow && (selectedBrand === 'ev' || selectedBrand === 'energy')
            ? Yup.string()
              .required(t('Please select a topic.'))
              .notOneOf([ALL_IDENTIFIER], t('Please select a topic.'))
            : Yup.string(),
        comments: Yup.string(),
        deviceKitId: Yup.string(),
        fromBrand: Yup.string(),
      })}
      onSubmit={async (values, { setStatus, setSubmitting, resetForm }): Promise<void> => {
        try {
          const { topicId, vehicleId } = values;
          const selectedBrandRef = doc(firestore, 'brands', selectedBrand);
          const selectedBrandData = (await getDoc(selectedBrandRef)).data() as Brand;
          const uid = generatePushID().toString();
          const { token } = await userRepository.generateToken(uid);
          const transferHost = selectedBrandData.parentHost;
          const transferPath = `/lounge?brandId=${selectedBrand}&token=${token}`;

          const transferRequest = {
            ...values,
            transferNow,
            transferPath,
            transferHost,
            comments,
            loungeId: uid,
            deviceKitName: session.deviceKitName,
            isVR: false,
          } as Partial<TransferRequest>;

          const deviceKit = deviceKits.find((d) => d.id === values.deviceKitId);
          if (deviceKit) {
            transferRequest.requestedDeviceKitName = deviceKit.name || 'N/A';
          }

          const queueData = {
            firstName: session.firstName || 'Anonymous',
            startTime: new Date(),
            brandId: selectedBrand,
            timezoneOffset: new Date().getTimezoneOffset(),
            status: 'Waiting',
            languageCode: transferRequest.language,
            browser: session.browser,
            device: session.device,
            deviceModel: session.deviceModel,
            position: 1,
            timeInQueue: 'a minute ago',
            vehicleState: {},
            vehicleId: '',
            topicId: '',
            topicName: '',
            loungeV2: false,
            loungeVOI: '',
          } as any;

          const updateVehicleState = async (vehicleId: string) => {
            const vehicleRef = doc(firestore, `brands/${selectedBrand}/vehicles/${vehicleId}`);
            const vehicleDoc = await getDoc(vehicleRef);
            if (vehicleDoc.exists) {
              const vehicleState = { ...{ id: vehicleDoc.id }, ...vehicleDoc.data() } as Vehicle;
              queueData.vehicleId = vehicleId;
              queueData.vehicleState = vehicleState;
              if (vehicleState.allowScreenSharing || vehicleState.allowWebcamStreaming) {
                transferRequest.isVR = true;
              }
            }
          };

          if (vehicleId !== ALL_IDENTIFIER) {
            await updateVehicleState(vehicleId);
          }

          if (topicId !== ALL_IDENTIFIER) {
            const topicRef = doc(firestore, `brands/${selectedBrand}/topics/${topicId}`);
            const topicData = (await getDoc(topicRef)).data() as Topic;
            queueData.topicId = topicId;
            queueData.topicName = topicData.name;
          }

          const previousTransferRequest = transferRequest; // use for queue to track how it's transferred

          session.joinedUsers = [];

          if (transferRequest.transferNow) {
            delete session.vehicleFeaturesState;
            await setDoc(
              doc(firestore, 'queue', uid),
              {
                ...session,
                ...queueData,
                previousTransferRequest,
              },
              { merge: true },
            );

            await setDoc(
              doc(firestore, 'status', uid),
              {
                lastChanged: uid,
                state: 'online',
              },
              { merge: true },
            );
          }

          await setDoc(
            doc(firestore, 'sessions', session.id),
            {
              transferRequested: true,
              transferRequest,
              formOpen: true,
              formTitle: 'Transfer form',
            },
            { merge: true },
          );
          await track('transfer-request');
          setStatus({ success: true });
          setSubmitting(false);
          handleToast('Transfer Request Created!', 'confirmation');
          resetForm();
          onClose();
        } catch (err) {
          console.error(err);
          handleToast(`Something went wrong! ${err.message}`, 'warning');
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        setFieldValue,
      }): JSX.Element => {
        const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (isValid) {
            handleSubmit(event);
          }
        };
        return (
          <>
            {isEnergy && <ModalIconButton
              onClose={onClose}
              brandId={brandId}
            />}
            <Box className="modal-container">
              {!isEnergy && <ModalIconButton
                onClose={onClose}
                brandId={brandId}
              />}
              <Box id="errorContainer">
                <Element
                  name="errorElement"
                  className={styles.errorElement}
                >
                  <ErrorComponent
                    containerId="errors"
                    condition={!isValid && validateErrors}
                    errors={errors}
                  />
                </Element>
              </Box>
              <Box>
                <Typography
                  variant="modalTitle"
                  component="h2"
                >
                  {t('INITIATE A CALL TRANSFER')}
                </Typography>
                {isGMC && <Box className="modal-border" />}
                {(isCadillacUS || isCadillacCanada) && (
                  <>
                    <Typography
                      variant="modalSubText"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: t<string>('* Indicates Required Fields'),
                      }}
                    />
                  </>
                )}
              </Box>
              <form
                onSubmit={handleCheckForm}
                noValidate
                className={styles.form}
              >
                <Grid
                  container
                  spacing="30px"
                >
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="transferIdModal"
                    className={styles.selectBrand}
                  >
                    {!isEnergy && (
                      <InputLabel>
                        <span dangerouslySetInnerHTML={{ __html: t('Select a brand*:') }} />
                      </InputLabel>
                    )}
                    <GridWrapper>
                      {isEnergy ? (
                        <FormControl
                          fullWidth
                          variant="standard"
                        >
                          <InputLabel>{t('Please select a brand')}</InputLabel>
                          <SelectBasicField
                            error={Boolean(touched.brand && errors.brand)}
                            required
                            name="brand"
                            fullWidth
                            value={values.brand || ''}
                            label={t('Please select a brand')}
                            onChange={async (e) => {
                              handleChange(e);
                              const brand = e.target.value as string;
                              setSelectedBrand(brand);
                              if (brand) {
                                const vehicleRef = collection(
                                  firestore,
                                  `brands/${brand}/vehicles`,
                                );
                                const vehiclQuery = query(vehicleRef, orderBy('index', 'asc'));
                                // const vehiclQuery = query(vehicleRef, where('widgetIds', 'array-contains', 'Transfer'), orderBy('index', 'asc'));
                                const vehicleSnapshot = await getDocs(vehiclQuery);
                                const vehicleDocs = vehicleSnapshot.docs
                                  .map((d: any) => ({
                                    ...d.data(),
                                    ...{ id: d.id },
                                  }))
                                  .filter((d) => (isEVLive ? true : d));
                                setVehicles(vehicleDocs);
                                console.log(vehicleDocs);

                                const topicRef = collection(firestore, `brands/${brand}/topics`);
                                const topicQuery = query(
                                  topicRef,
                                  where('widgetIds', 'array-contains', 'Transfer'),
                                  orderBy('index', 'asc')
                                );
                                const topicSnapshot = await getDocs(topicQuery);
                                const topicDocs = topicSnapshot.docs.map((d: any) => ({
                                  ...d.data(),
                                  ...{ id: d.id },
                                }));
                                setTopics(topicDocs);

                                setDeviceKits(await getDeviceKits(brand));
                                const languageRef = collection(
                                  firestore,
                                  `brands/${brand}/languages`,
                                );
                                const languageSnapshot = await getDocs(languageRef);
                                const languageDocs = languageSnapshot.docs.map((d: any) => ({
                                  ...d.data(),
                                  ...{ id: d.id },
                                }));
                                setLanguages(languageDocs);
                                if (!transferNow) {
                                  setFieldValue('vehicleId', ALL_IDENTIFIER);
                                  setFieldValue('topicId', ALL_IDENTIFIER);
                                }
                              }
                            }}
                            input={<OutlinedInput />}
                          >
                            <MenuItem
                              disabled
                              value={ALL_IDENTIFIER}
                            >
                              {t('Please select a brand')}
                            </MenuItem>
                            {brand && brand.transferToBrands?.map((brand) => (
                              <MenuItem
                                key={brand.id}
                                value={brand.id}
                              >
                                {brand.name}
                              </MenuItem>
                            ))}
                          </SelectBasicField>
                        </FormControl>
                      ) : (
                        <SelectField
                          variant="outlined"
                          fullWidth
                          error={Boolean(touched.brand && errors.brand)}
                          required
                          name="brand"
                          value={values.brand || ALL_IDENTIFIER}
                          onChange={async (e) => {
                            handleChange(e);
                            const brand = e.target.value;
                            setSelectedBrand(brand);
                            if (brand) {
                              const vehicleRef = collection(firestore, `brands/${brand}/vehicles`);
                              const vehiclQuery = query(vehicleRef, orderBy('index', 'asc'));
                              // const vehiclQuery = query(vehicleRef, where('widgetIds', 'array-contains', 'Transfer'), orderBy('index', 'asc'));
                              const vehicleSnapshot = await getDocs(vehiclQuery);
                              const vehicleDocs = vehicleSnapshot.docs
                                .map((d: any) => ({
                                  ...d.data(),
                                  ...{ id: d.id },
                                }))
                                .filter((d) => (isEVLive ? true : d));
                              setVehicles(vehicleDocs);

                              const topicRef = collection(firestore, `brands/${brand}/topics`);
                              const topicQuery = query(
                                topicRef,
                                where('widgetIds', 'array-contains', 'Transfer'),
                                orderBy('index', 'asc')
                              );
                              const topicSnapshot = await getDocs(topicQuery);
                              const topicDocs = topicSnapshot.docs.map((d: any) => ({
                                ...d.data(),
                                ...{ id: d.id },
                              }));
                              setTopics(topicDocs);

                              setDeviceKits(await getDeviceKits(brand));
                              const languageRef = collection(firestore, `brands/${brand}/languages`);
                              const languageSnapshot = await getDocs(languageRef);
                              const languageDocs = languageSnapshot.docs.map((d: any) => ({
                                ...d.data(),
                                ...{ id: d.id },
                              }));
                              setLanguages(languageDocs);

                              if (!transferNow) {
                                setFieldValue('vehicleId', ALL_IDENTIFIER);
                                setFieldValue('topicId', ALL_IDENTIFIER);
                              }
                            }
                          }}
                          input={<OutlinedInput />}
                        >
                          <option
                            disabled
                            value={ALL_IDENTIFIER}
                          >
                            {t('Please select a brand')}
                          </option>
                          {brand && brand.transferToBrands?.map((brand) => (
                            <option
                              key={brand.id}
                              value={brand.id}
                            >
                              {brand.name}
                            </option>
                          ))}
                        </SelectField>
                      )}
                      {(isCadillacUS || isCadillacCanada) && (
                        <div
                          className={
                            isCadillacUS || isCadillacCanada
                              ? 'dropdown-icon-cadillac'
                              : 'dropdown-icon'
                          }
                        >
                          <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                        </div>
                      )}
                      {isGMC && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                            }}
                          />
                        </div>
                      )}
                      {(isEVLive || isEnergy) && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: theme.palette.background.chevronClosedStateIcon,
                          }}
                        />
                      )}
                      {isBuick && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[800],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                              fontSize: '2rem',
                            }}
                          />
                        </div>
                      )}
                      {touched.brand && errors.brand && (
                        <FormHelperText error>{t('Please select a brand.')}</FormHelperText>
                      )}
                    </GridWrapper>
                  </GridWrapper>
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="transferIdModal"
                    className={styles.selectLanguage}
                  >
                    {!isEnergy && (
                      <InputLabel>
                        <span dangerouslySetInnerHTML={{ __html: t('Select a Language*:') }} />
                      </InputLabel>
                    )}
                    <GridWrapper>
                      {isEnergy ? (
                        <FormControl
                          fullWidth
                          variant="standard"
                        >
                          <InputLabel>{t('Please select a language')}</InputLabel>
                          <SelectBasicField
                            value={values.language || ''}
                            fullWidth
                            error={Boolean(touched.language && errors.language)}
                            required
                            name="language"
                            label={t('Please select a language')}
                            onChange={async (e) => {
                              console.log(e);
                              handleChange(e);
                              const selectedLanguage = e.target.value;
                              if (selectedLanguage) {
                                const filteredDeviceKits = (
                                  await getDeviceKits(selectedBrand)
                                ).filter((deviceKit: DeviceKit) =>
                                  deviceKit.languages.find(
                                    (language: string) => language === selectedLanguage,
                                  ),);
                                setDeviceKits(filteredDeviceKits);
                              }
                            }}
                            input={<OutlinedInput />}
                          >
                            <MenuItem
                              disabled
                              value={ALL_IDENTIFIER}
                            >
                              {t('Please select a language')}
                            </MenuItem>
                            {languages
                              && languages.map((language) => (
                                <MenuItem
                                  key={language.id}
                                  value={language.name}
                                >
                                  {language.name}
                                </MenuItem>
                              ))}
                          </SelectBasicField>
                        </FormControl>
                      ) : (
                        <SelectField
                          variant="outlined"
                          value={values.language || ALL_IDENTIFIER}
                          fullWidth
                          error={Boolean(touched.language && errors.language)}
                          required
                          name="language"
                          onChange={async (e) => {
                            console.log(e);
                            handleChange(e);
                            const selectedLanguage = e.target.value;
                            if (selectedLanguage) {
                              const filteredDeviceKits = (await getDeviceKits(selectedBrand)).filter(
                                (deviceKit: DeviceKit) =>
                                  deviceKit.languages.find(
                                    (language: string) => language === selectedLanguage,
                                  ),
                              );
                              setDeviceKits(filteredDeviceKits);
                            }
                          }}
                          input={<OutlinedInput />}
                        >
                          <option
                            disabled
                            value={ALL_IDENTIFIER}
                          >
                            {t('Please select a language')}
                          </option>
                          {languages
                            && languages.map((language) => (
                              <option
                                key={language.id}
                                value={language.name}
                              >
                                {language.name}
                              </option>
                            ))}
                        </SelectField>
                      )}
                      {(isCadillacUS || isCadillacCanada) && (
                        <div
                          className={
                            isCadillacUS || isCadillacCanada
                              ? 'dropdown-icon-cadillac'
                              : 'dropdown-icon'
                          }
                        >
                          <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                        </div>
                      )}
                      {isGMC && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                            }}
                          />
                        </div>
                      )}
                      {(isEVLive || isEnergy) && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                          }}
                        />
                      )}
                      {isBuick && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[800],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                              fontSize: '2rem',
                            }}
                          />
                        </div>
                      )}
                      {touched.language && errors.language && (
                        <FormHelperText error>{t('Please select a language.')}</FormHelperText>
                      )}
                    </GridWrapper>
                  </GridWrapper>
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="transferIdModal"
                    className={styles.topicVehicleSelect}
                  >
                    {(selectedBrand === 'ev' || selectedBrand === 'energy') ? (
                      <>
                        {!isEnergy && (
                          <InputLabel className={styles.inputLabel}>
                            <span dangerouslySetInnerHTML={{ __html: t('SELECT A TOPIC') }} />
                          </InputLabel>
                        )}
                        <GridWrapper>
                          {isEnergy ? (
                            <FormControl
                              fullWidth
                              variant="standard"
                            >
                              <InputLabel>{t('SELECT A TOPIC')}</InputLabel>
                              <SelectBasicField
                                fullWidth
                                name="topicId"
                                value={values.topicId || ''}
                                error={Boolean(touched.topicId && errors.topicId)}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                label={t('SELECT A TOPIC')}
                              >
                                <MenuItem
                                  disabled
                                  value={ALL_IDENTIFIER}
                                >
                                  {t(ALL_IDENTIFIER)}
                                </MenuItem>
                                {topics.map((topic) => (
                                  <MenuItem
                                    key={topic.id}
                                    value={topic.id}
                                  >
                                    {topic.name}
                                  </MenuItem>
                                ))}
                              </SelectBasicField>
                            </FormControl>
                          ) : (
                            <SelectField
                              variant="outlined"
                              fullWidth
                              name="topicId"
                              value={values.topicId || ALL_IDENTIFIER}
                              error={Boolean(touched.topicId && errors.topicId)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                            >
                              <option
                                disabled
                                value={ALL_IDENTIFIER}
                              >
                                {t(ALL_IDENTIFIER)}
                              </option>
                              {topics.map((topic) => (
                                <option
                                  key={topic.id}
                                  value={topic.id}
                                >
                                  {topic.name}
                                </option>
                              ))}
                            </SelectField>
                          )}
                          {(isCadillacUS || isCadillacCanada) && (
                            <div
                              className={
                                isCadillacUS || isCadillacCanada
                                  ? 'dropdown-icon-cadillac'
                                  : 'dropdown-icon'
                              }
                            >
                              <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                            </div>
                          )}
                          {isGMC && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundColor: theme.palette.grey[900],
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  color: theme.palette.grey[50],
                                }}
                              />
                            </div>
                          )}
                          {(isEVLive || isEnergy) && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                              }}
                            />
                          )}
                          {isBuick && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundColor: theme.palette.grey[800],
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  color: theme.palette.grey[50],
                                  fontSize: '2rem',
                                }}
                              />
                            </div>
                          )}
                          {touched.topicId && errors.topicId && (
                            <FormHelperText error>{t('Please select a topic.')}</FormHelperText>
                          )}
                        </GridWrapper>
                      </>
                    ) : (
                      <>
                        {!isEnergy && (
                          <InputLabel className={styles.inputLabel}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: transferNow
                                  ? t('SELECT A VEHICLE')
                                  : t('Transfer Later: SELECT A VEHICLE'),
                              }}
                            />
                          </InputLabel>
                        )}

                        <GridWrapper>
                          {isEnergy ? (
                            <FormControl
                              fullWidth
                              variant="standard"
                            >
                              <InputLabel>
                                {transferNow
                                  ? t('SELECT A VEHICLE')
                                  : t('Transfer Later: SELECT A VEHICLE')}
                              </InputLabel>
                              <SelectBasicField
                                fullWidth
                                name="vehicleId"
                                value={values.vehicleId || ALL_IDENTIFIER}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                error={Boolean(touched.vehicleId && errors.vehicleId)}
                                label={
                                  transferNow
                                    ? t('SELECT A VEHICLE')
                                    : t('Transfer Later: SELECT A VEHICLE')
                                }
                              >
                                <MenuItem
                                  disabled
                                  value={ALL_IDENTIFIER}
                                >
                                  {t(ALL_IDENTIFIER)}
                                </MenuItem>
                                {vehicles.map((vehicle) => (
                                  <MenuItem
                                    disabled={
                                      activeVRSession(vehicle)
                                      || (brandId !== selectedBrand
                                        && vehicle.allowWebcamStreaming === true)
                                    }
                                    key={vehicle.id}
                                    value={vehicle.id}
                                  >
                                    {vehicle.name}{' '}
                                    {activeVRSession(vehicle) ? ' - VR session in progress' : ''}{' '}
                                    {vehicle.allowWebcamStreaming ? ' (VR Enabled Vehicle) ' : ''}
                                  </MenuItem>
                                ))}
                              </SelectBasicField>
                            </FormControl>
                          ) : (
                            <SelectField
                              variant="outlined"
                              fullWidth
                              name="vehicleId"
                              value={values.vehicleId || ALL_IDENTIFIER}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              error={Boolean(touched.vehicleId && errors.vehicleId)}
                            >
                              <option
                                disabled
                                value={ALL_IDENTIFIER}
                              >
                                {t(ALL_IDENTIFIER)}
                              </option>
                              {vehicles.map((vehicle) => (
                                <option
                                  disabled={
                                    activeVRSession(vehicle)
                                    || (brandId !== selectedBrand
                                      && vehicle.allowWebcamStreaming === true)
                                  }
                                  key={vehicle.id}
                                  value={vehicle.id}
                                >
                                  {vehicle.name}{' '}
                                  {activeVRSession(vehicle) ? ' - VR session in progress' : ''}{' '}
                                  {vehicle.allowWebcamStreaming ? ' (VR Enabled Vehicle) ' : ''}
                                </option>
                              ))}
                            </SelectField>
                          )}

                          {(isCadillacUS || isCadillacCanada) && (
                            <div
                              className={
                                isCadillacUS || isCadillacCanada
                                  ? 'dropdown-icon-cadillac'
                                  : 'dropdown-icon'
                              }
                            >
                              <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                            </div>
                          )}
                          {isGMC && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundColor: theme.palette.grey[900],
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  color: theme.palette.grey[50],
                                }}
                              />
                            </div>
                          )}
                          {(isEVLive || isEnergy) && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                              }}
                            />
                          )}
                          {isBuick && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundColor: theme.palette.grey[800],
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  color: theme.palette.grey[50],
                                  fontSize: '2rem',
                                }}
                              />
                            </div>
                          )}
                          {touched.vehicleId && errors.vehicleId && (
                            <FormHelperText error>{t('Please select a vehicle')}</FormHelperText>
                          )}
                        </GridWrapper>
                      </>
                    )}
                  </GridWrapper>

                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="transferIdModal"
                    className={styles.selectProductSpecialist}
                  >
                    {!isEnergy && (
                      <InputLabel className={styles.inputLabel}>
                        {t('Select a Product Specialist:')}
                      </InputLabel>
                    )}
                    <GridWrapper>
                      {isEnergy ? (
                        <FormControl
                          fullWidth
                          variant="standard"
                        >
                          <InputLabel className={styles.inputLabel}>
                            {t('Select a Product Specialist:')}
                          </InputLabel>
                          <SelectBasicField
                            label={t('Select a Product Specialist:')}
                            variant="outlined"
                            fullWidth
                            name="deviceKitId"
                            value={values.deviceKitId || ''}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                          >
                            <MenuItem value={ALL_IDENTIFIER}>{t(ALL_IDENTIFIER)}</MenuItem>
                            {deviceKits
                              && deviceKits.map((deviceKit) => (
                                <MenuItem
                                  key={deviceKit.id}
                                  value={deviceKit.id}
                                >
                                  {deviceKit.name}
                                </MenuItem>
                              ))}
                          </SelectBasicField>
                        </FormControl>
                      ) : (
                        <SelectField
                          variant="outlined"
                          fullWidth
                          name="deviceKitId"
                          value={values.deviceKitId || 'Product Specialist'}
                          onChange={handleChange}
                          input={<OutlinedInput />}
                        >
                          <option value={ALL_IDENTIFIER}>{t(ALL_IDENTIFIER)}</option>
                          {deviceKits
                            && deviceKits.map((deviceKit) => (
                              <option
                                key={deviceKit.id}
                                value={deviceKit.id}
                              >
                                {deviceKit.name}
                              </option>
                            ))}
                        </SelectField>
                      )}

                      {(isCadillacUS || isCadillacCanada) && (
                        <div
                          className={
                            isCadillacUS || isCadillacCanada
                              ? 'dropdown-icon-cadillac'
                              : 'dropdown-icon'
                          }
                        >
                          <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                        </div>
                      )}
                      {isGMC && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                            }}
                          />
                        </div>
                      )}
                      {(isEVLive || isEnergy) && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                          }}
                        />
                      )}
                      {isBuick && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundColor: theme.palette.grey[800],
                          }}
                        >
                          <ExpandMoreIcon
                            sx={{
                              color: theme.palette.grey[50],
                              fontSize: '2rem',
                            }}
                          />
                        </div>
                      )}
                    </GridWrapper>
                  </GridWrapper>

                  <GridWrapper
                    item
                    xs={12}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {!isEnergy && (
                      <InputLabel>
                        <span dangerouslySetInnerHTML={{ __html: t('Comments (Optional):') }} />
                      </InputLabel>
                    )}
                    <TextFieldForm
                      className={clsx(styles.textField, 'stat-input-field')}
                      error={Boolean(touched.comments && errors.comments)}
                      helperText={touched.comments && errors.comments}
                      name="comments"
                      multiline
                      minRows={6}
                      inputProps={{ maxLength: maxCommentLength }}
                      defaultValue={values.comments}
                      onChange={(event) => {
                        const { value } = event.target;
                        setComments(value);
                      }}
                    />
                  </GridWrapper>
                </Grid>
                <Typography
                  component="h5"
                  variant="characterCount"
                  className={styles.characterCount}
                >
                  {comments.length}/{maxCommentLength}
                </Typography>
                <Box className={styles.buttonWrapper}>
                  <LoadingButton
                    loading={isSubmitting}
                    className={styles.submitButton}
                    onClick={() => {
                      // When switching to different brands on ev, the errors on the forms show the red borders
                      if (!transferNow) {
                        touched.brand = true;
                        touched.comments = true;
                        touched.deviceKitId = true;
                        touched.fromBrand = true;
                        touched.language = true;
                        touched.topicId = true;
                        touched.transferNow = true;
                        touched.vehicleId = true;
                      }

                      setValidateErrors(true);
                      executeScroll(isValid);
                    }}
                    disabled={isSubmitting}
                    variant={
                      isCadillacUS
                      || isCadillacCanada
                      || isEnergy
                      || isChevy
                      || isGMC
                      || isBuick
                      || isEVLive
                        ? 'primary'
                        : 'contained'
                    }
                    type="submit"
                    aria-label="Submit form"
                  >
                    {t('Submit')}
                  </LoadingButton>
                  {/* <TextButton
                    className={styles.saveButton}
                    variant={isGMC || isBuick ? 'tertiary' : 'text'}
                    disableRipple
                    type="button"
                    onClick={() => handelSave(values)}
                  >
                    {t('Save')}
                  </TextButton> */}
                </Box>
              </form>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default TransferView;

/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  InputLabel,
  OutlinedInput,
  Container,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormGroup,
  MenuItem,
  MenuProps
} from '@mui/material';
import { Grid, IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useBrandQueryString, useReporting, useQueryString } from '@hooks';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextFieldForm, GridWrapper, TextButton, ErrorComponent, SelectField, LoadingScreen, SelectBasicField } from '@components';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TableContainer from '@mui/material/TableContainer';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { regexEmailHelper } from '@constants';
import CheckIcon from '@mui/icons-material/Check';
import { StayUpdatedViewStyles } from './StayUpdatedView.style';
import { Vehicle, VehicleType, BrandType, Topic, TopicType } from '@types';
import * as Scroll from 'react-scroll';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { usStates } from "@constants";
import { trimSpaces } from '@utils';

declare let window: any;

type StayUpdatedProps = {
  brandId: string;
  languageCode: string;
  reservationType: string;
  transferFrom?: BrandType;
  vehicles: Vehicle[];
  vehicleTypes: VehicleType[];
  topics?: Topic[];
  topicTypes?: TopicType[];
  isModal: boolean;
  onSubmit: (stayUpdated) => void;
  onClickNoThank: () => void;
};

const EnergyMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 260,
      overflowY: 'auto',
      boxShadow: 'none',
      borderTop: 'none',
      borderLeft: '2px solid #000',
      borderRight: '2px solid #000',
      borderBottom: '2px solid #000',
    },
  },
  autoFocus: true,
  disableAutoFocusItem: true,
  disableEnforceFocus: true,
  disableAutoFocus: true,
}

const StayUpdatedView = (props: StayUpdatedProps): ReactElement => {
  const {
    brandId,
    languageCode,
    reservationType,
    transferFrom,
    vehicles,
    vehicleTypes,
    topics,
    topicTypes,
    isModal,
    onSubmit,
    onClickNoThank,
    ...other
  } = props;

  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const [openModule, setOpenModule] = useState<boolean>(false);
  const [openSelectVehicle, setOpenSelectVehicle] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const [planningValue, setPlanningValue] = useState('');
  const [selectedVehicleBrand, setSelectedVehicleBrand] = useState('');
  //const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStateOpen, setIsStateOpen] = useState<boolean>(false);
  const [isProductOfInterestOpen, setIsProductOfInterestOpen] = useState<boolean>(false);
  const [isPurchaseDateOpen, setIsPurchaseDateOpen] = useState<boolean>(false);

  const theme = useTheme();
  const { trackAdobe } = useReporting();
  const { t } = useTranslation();

  const handleClick = (event) => {
    if (event.target.value === value) {
      setValue('');
    } else {
      setValue(event.target.value);
    }
  };

  const handlePlanningClick = (event) => {
    if (event.target.value === value) {
      setPlanningValue('');
    } else {
      setPlanningValue(event.target.value);
    }
  };

  //  temporary mock data
  const purchaseDate = [
    { id: 'SA00000152', value: t('Within 1 Month') },
    { id: 'SA00000153', value: t('1-3 Months') },
    { id: 'SA00000154', value: t('4-6 Months') },
    { id: 'SA00000155', value: t('7-12 Months') },
    { id: 'SA00000156', value: t('More than 1 year') },
  ];


  // 1 - 0-3 MONTHS
  // 2 - 4-6 MONTHS
  // 6 - 7-9 MONTHS
  // 7 - 10-12 MONTHS
  // 4 - 1-2 YEARS
  // 5 - OVER 2 YEARS

  const evCanPurchaseDates = [
    { id: 1, value:t("0-3 MONTHS")},
    { id: 2, value:t("4-6 MONTHS")},
    { id: 6, value:t("7-9 MONTHS")},
    { id: 7, value:t("10-12 MONTHS")},
    { id: 4, value:t("1-2 YEARS")},
    { id: 5, value:t("OVER 2 YEARS")},
  ]; 

  /* const productOfInterest = [
    { id: 'CAENGYPOI1', value:t("Ultium Home V2H Enablement Kit")},
    { id: 'CAENGYPOI2', value:t("Ultium Home V2H Bundle")},
    { id: 'CAENGYPOI3', value:t("Ultium Home Energy System Storage Bundle")},
    { id: 'CAENGYPOI4', value:t("Ultium Home Energy System")},
    { id: 'CAENGYPOI5', value:t("Ultium Home PowerShift Charger")},
    { id: 'CAENGYPOI6', value:t("Ultium Home PowerBank")},
  ];  */

  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isChevy = brandId === 'chevy';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev';
  const isEVLiveCanada = brandId === 'evcan';
  const isFrench =
    languageCode === 'en-FR' ||
    window.location.href.includes('en-FR') ||
    window.location.href.includes('en-fr') ||
    window.location.href.includes('fr');
  const isEVLiveCanadaFrench = isEVLiveCanada && isFrench;
  const isEnergy = brandId === 'energy';

  const receiveUpdates = (isCadillacCanada || isEVLiveCanada) && {
    receiveUpdates: (isCadillacCanada || isEVLiveCanada) ? true : false,
  };
  const [receiveUpdatesValue, setReceiveUpdatesValue] = useState<boolean>(
    (isCadillacCanada || isEVLiveCanada) ? true : false,
  );

  
  // let receiveUpdatesFormCondition = null;

  // if (isCadillacCanada) {
  //   receiveUpdatesFormCondition = Yup.bool()
  //     .required(t('Please check receive updates.'))
  //     .oneOf([true], t('Please check receive updates.'));
  // }

  // if (isEVLiveCanada) {
  //   receiveUpdatesFormCondition = Yup.bool();
  // }

  const allVehiclesText = (isEVLive || isEVLiveCanada) ? 'All Brands' : 'All Vehicles';

  const buickCheckboxGridSpacing = isBuick || isEVLive || isEVLiveCanada ? 6 : 4;

  const useStyles = makeStyles(StayUpdatedViewStyles(brandId));
  const styles = useStyles();

  const queryParams = useBrandQueryString(brandId);

  const initialValues = {
    brandId,
    languageCode,
    reservationType,
    purchaseDate: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    postalCode: '',
    email: '',
    confirmEmail: '',
    city: '',
    state: '',
    showOwnershipType: true,
    ownershipType: '',
    offerType: '',
    names: [],
    vehiclesOfInterest: [],
    productOfInterest: '',
    phone: '',
    ...receiveUpdates,
  } as any;

  if (transferFrom) {
    initialValues.transferFrom = transferFrom;
  }

  const dataFormatter = (selectedVehicleType) => {
    const allVehicles = vehicleTypes?.map((vehicleType) => ({
      type: vehicleType.name as string,
      data: vehicles?.filter((vehicle) => vehicle.vehicleType === vehicleType.name || vehicle.vehicleTypes?.includes(vehicleType.name)),
    }));
    if (!selectedVehicleType.includes(allVehiclesText)) {
      const filteredVehicles = allVehicles.filter((vehicle) => {
        return selectedVehicleType.includes(vehicle.type);
      });
      return filteredVehicles;
    }
    return allVehicles;
  };

  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;

  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  const renderPurchaseLeaseCheckedIcon = () => {
    if (isGMC) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.color.checkboxGrey,
            color: theme.palette.grey[800],
          }}
        />
      );
    }

    return (
      <CheckIcon
        sx={{
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.grey[800],
        }}
      />
    );
  };

  const renderPurchaseLeaseUncheckedIcon = () => {
    if (isGMC) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            backgroundColor: theme.palette.color.checkboxGrey,
            '& path': { display: 'none' },
          }}
        />
      );
    }

    return (
      <CheckBoxOutlineBlankIcon
        sx={{
          backgroundColor: theme.palette.grey[400],
          '& path': { display: 'none' },
        }}
      />
    );
  };

  const renderCheckedIcon = () => {
    if (isChevy) {
      return <CheckBoxIcon />;
    }

    if (isBuick) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[800],
          }}
        />
      );
    }

    if (isEVLive || isEVLiveCanada) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.grey[200],
            fontSize: '1.3rem',
            color: theme.palette.grey[300],
          }}
        />
      );
    }

    if (isEnergy) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.color.checkboxGrey,
            color: theme.palette.grey[800],
            mr: '0.5rem',
            width: '1rem',
            height: '1rem',
          }}
        />
      );
    }

    return (
      <CheckIcon
        sx={{
          backgroundColor: theme.palette.color.checkboxGrey,
          color: theme.palette.grey[800],
        }}
      />
    );
  };

  const renderUncheckedIcon = () => {
    if (isChevy) {
      return <CheckBoxOutlineBlankIcon />;
    }

    if (isBuick) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            backgroundColor: theme.palette.grey[400],
            '& path': { display: 'none' },
          }}
        />
      );
    }

    if (isEVLive || isEVLiveCanada) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            backgroundColor: theme.palette.grey[200],
            '& path': { display: 'none' },
            fontSize: '1.3rem',
          }}
        />
      );
    }

    if (isEnergy) {
      return (
        <CheckBoxOutlineBlankIcon
        sx={{
          backgroundColor: theme.palette.color.checkboxGrey,
          '& path': { display: 'none' },
          mr: '0.5rem',
          width: '1rem',
          height: '1rem',
        }}
      />
      );
    }

    return (
      <CheckBoxOutlineBlankIcon
        sx={{
          backgroundColor: theme.palette.color.checkboxGrey,
          '& path': { display: 'none' },
        }}
      />
    );
  };

  const renderSelectVehicleCheckIcon = () => {
    if (isGMC) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            backgroundColor: theme.palette.color.checkboxGrey,
            '& path': { display: 'none' },
          }}
        />
      );
    }

    if (isBuick) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            border: `1px solid ${theme.palette.grey[600]}`,
            '& path': { display: 'none' },
          }}
        />
      );
    }

    if (isEVLive || isEVLiveCanada) {
      return (
        <CheckBoxOutlineBlankIcon
          sx={{
            backgroundColor: theme.palette.grey[200],
            '& path': { display: 'none' },
            fontSize: '1.3rem',
          }}
        />
      );
    }

    return <CheckBoxOutlineBlankIcon />;
  };

  const renderSelectVehicleCheckedIcon = () => {
    if (isCadillacUS || isCadillacCanada) {
      return (
        <>
          <CheckIcon className={styles.checkIcon} />
          <CheckBoxOutlineBlankIcon />
        </>
      );
    }

    if (isGMC) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.color.checkboxGrey,
            color: theme.palette.grey[800],
          }}
        />
      );
    }

    if (isBuick) {
      return (
        <CheckIcon
          sx={{
            color: theme.palette.grey[600],
            border: `1px solid ${theme.palette.grey[600]}`,
          }}
        />
      );
    }

    if (isEVLive || isEVLiveCanada) {
      return (
        <CheckIcon
          sx={{
            backgroundColor: theme.palette.grey[200],
            fontSize: '1.3rem',
            color: theme.palette.grey[300],
          }}
        />
      );
    }

    return <CheckBoxIcon />;
  };

  let dataDTM = 'stay connected';
  let vehiclesOfInterestDataDtm = `${dataDTM}:vehicle type`;
  let selectVehicleDataDtm = dataDTM;
  let modelOfInterest = `${dataDTM}:vehicle`;
  let imPlanningToDataDtm = `${dataDTM}:i am planning to`;
  const discountDataDtm = `${dataDTM}:discounts`;

  if (isChevy || isGMC || isBuick) selectVehicleDataDtm = `${dataDTM}:vehicle`;

  if (isEVLive) {
    vehiclesOfInterestDataDtm = `${dataDTM}:brand`;
    selectVehicleDataDtm = `${dataDTM}:model`;
    modelOfInterest = `${dataDTM}:model`;
    imPlanningToDataDtm = `${dataDTM}:i'm planning to`;
  }

  let discountPrograms = [
    {
      id: 0,
      label: 'GM Military Discount',
      value: 'CA00034032',
      dataDTM: discountDataDtm,
    },
    {
      id: 1,
      label: 'GM First Responder',
      value: 'CA00034037',
      dataDTM: discountDataDtm,
    },
    {
      id: 2,
      label: 'GM Supplier Discount',
      value: 'CA00034035',
      dataDTM: discountDataDtm,
    },
    {
      id: 3,
      label: 'GM Educator Discount',
      value: 'CA00034033',
      dataDTM: discountDataDtm,
    },
    {
      id: 4,
      label: 'GM College Discount',
      value: 'CA00034034',
      dataDTM: discountDataDtm,
    },
  ];

  const trackErrorMessages = (errors: any) => {
    const errorrMessages = [];
    let errorMessage = '';
    const firstName = 'Please input valid first name';
    const lastName = 'Please input valid last name';
    const zipCode = 'Please input valid zip code';
    const email = 'Please input valid email address';
    const confirmEmail = 'Please input valid confirm email';
    const purchaseDate = 'Please select a purchase date';
    const vehiclesOfInterest = 'Please select vehicle';
    const ownershipType = 'Please select an ownership type';
    const receiveUpdates = 'Please check receive updates';
    const phone = 'Please provide a valid phone number.';
    const state = 'Please provide a state.';

    if (errors.firstName) errorrMessages.push(firstName);
    if (errors.lastName) errorrMessages.push(lastName);
    if (errors.zipCode) errorrMessages.push(zipCode);
    if (errors.email) errorrMessages.push(email);
    if (errors.confirmEmail) errorrMessages.push(confirmEmail);
    if (errors.purchaseDate) errorrMessages.push(purchaseDate);
    if (errors.vehiclesOfInterest) errorrMessages.push(vehiclesOfInterest);
    if (errors.ownershipType) errorrMessages.push(ownershipType);
    if (errors.receiveUpdates) errorrMessages.push(receiveUpdates);
    if (errors.phone) errorrMessages.push(phone);
    if (errors.state)  errorrMessages.push(state);

    errorMessage = errorrMessages.join(', ');
    window.digitalData.formInfo.errorMessage = errorMessage;
  };

  const { urlSearchParams } = useQueryString();
  const isInvited = urlSearchParams.get('isInvited') === 'true';

  // UseEffect to change display of discount codes depending on brand selected, EV Live specific
  useEffect(() => {
    if (selectedVehicleBrand === 'Cadillac' && isEVLive) {
      discountPrograms.splice(discountPrograms.length - 2, 2);
    }
    if (selectedVehicleBrand === 'GMC' && isEVLive) {
      discountPrograms.splice(discountPrograms.length - 2, 2);
    }
  }, [selectedVehicleBrand]);

  if (isGMC) discountPrograms.splice(discountPrograms.length - 2, 2);

  const tabletDevice = useMediaQuery(theme.breakpoints.up(768));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('First Name must be at most 40 characters.'))
          .required(t('Please provide a first name.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
        lastName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('Last Name must be at most 40 characters.'))
          .required(t('Please provide a last name.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
        city: (isEVLive || isEVLiveCanada || isEnergy) ? 
          Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('City must be at most 40 characters.'))
          .required(t('Please provide a city.')) : 
          Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('City must be at most 40 characters.')),
        state: isEnergy ? Yup.string().required(t('Please provide a state.')) : Yup.string(),
        zipCode:
          !isCadillacCanada && !isEVLiveCanada
            ? Yup.string()
                .min(5, t('Zip Code is not valid.'))
                .max(5, t('Zip Code must be at most 5 characters.'))
                .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip Code is not valid.'))
                .required(t('Zip Code is not valid.'))
            : Yup.string(),
        postalCode:
          isCadillacCanada || isEVLiveCanada
            ? Yup.string()
                .min(6, t('Postal Code is not valid.'))
                .max(6, t('Postal Code must be at most 6 characters.'))
                .matches(
                  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                  t('Postal Code is not valid.'),
                )
                .required(t('Postal Code is not valid.'))
            : Yup.string(),
        email: Yup.string()
          .email(t('Please provide a properly formatted email address.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
          .required(t('Please provide a properly formatted email address.')),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
          .required(t('Email addresses do not match.')),
        purchaseDate: (!isCadillacCanada && !isEVLiveCanada && !isEnergy)
          ? Yup.string().required(t('Please select a purchase date.'))
          : Yup.string(),
        vehiclesOfInterest:
          isChevy || isGMC || isEVLive || isEVLiveCanada
            ? Yup.array()
                .required()
                .min(1, t('Please select a vehicle.'))
                .required(t('Please select a vehicle.'))
            : isBuick // BL-17
            ? Yup.array()
                .required()
                .min(1, t('Please select 1-3 vehicles.'))
                .max(3, t('Please select 1-3 vehicles.'))
                .required(t('Please select 1-3 vehicles.'))
            : Yup.array(),
        showOwnershipType: Yup.boolean(),
        ownershipType:
          isChevy || isBuick || isGMC || isEVLiveCanada
            ? Yup.string().required(t('Please select an ownership type.'))
            : isEVLive
            ? Yup.string().when('showOwnershipType', {
                is: true,
                then: Yup.string().required(t('Please select an ownership type.')),
              })
            : Yup.string(),
        receiveUpdates: Yup.bool(),
        productOfInterest: isEnergy && Yup.string().required(t('Please select a product of interest.')),
        phone: isEnergy
          ? Yup.string()
            .min(10, t('Please provide a valid phone number.'))
            .matches(
              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              t('Phone number is limited to Canada and USA'),
            )
          : Yup.string(),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        setIsLoading(true);
        // before we submit the form, we need to convert the vehiclesOfInterest array to only include the VOI name
        let voiArray = [];
        values.vehiclesOfInterest.map((voi) => voiArray.push(voi.name));

        try {
          const stayUpdated = {
            ...values,
            queryStringVariables: queryParams,
            vehiclesOfInterest: voiArray,
            brandId,
            isInvited
          };
          setStatus({ success: true });
          setSubmitting(false);
          trackAdobe('stay-updated');
          onSubmit(stayUpdated);
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! ${err.message}`);
          setStatus({ success: false });
          setIsLoading(false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        validateForm,
        submitForm,
        isSubmitting,
        touched,
        values,
        isValid,
      }): JSX.Element => {
        // TODO: PRETTY HACKY FOR EVCANADA, IN THE FUTURE IMPROVE CONDITION!
        const handleReceiveUpdatesClick = (event) => {
          if (isCadillacCanada || isEVLiveCanada) {
            if (event.target.checked) {
              setFieldValue(`receiveUpdates`, false);
              setReceiveUpdatesValue(false);
            } else {
              setFieldValue(`receiveUpdates`, true);
              setReceiveUpdatesValue(true);
            }
          }
        };

        if (isCadillacCanada || isEVLiveCanada) {
          values.receiveUpdates = receiveUpdatesValue;
        }

        const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();

          if (isValid) {
            handleSubmit(event);
          }
        };

        trackErrorMessages(errors);

        /* *
         * effect to listen for changes in values.names, and remove any vehiclesOfInterest whose type is not longer in the names array
         * */
        useEffect(() => {
          let filteredVoi = [];
          values.vehiclesOfInterest.length > 0 &&
            values.vehiclesOfInterest.map((voi) => {
              if (values.names.includes(voi.type)) {
                filteredVoi.push(voi);
              }
            });
          setFieldValue('vehiclesOfInterest', filteredVoi);
        }, [values.names]);

        useEffect(() => {
          if (isEVLive && selectedVehicleBrand === 'Cadillac') {
            setFieldValue(`showOwnershipType`, false);
          }
        }, [selectedVehicleBrand]);

        return (
        <>
        <Grid className={clsx(styles.gridWrapper, 'grid-wrapper')}>
          {isLoading ?
          <Box>
            <LoadingScreen />
          </Box> :
          <>
            {isEnergy && (
                    <Box className={styles.topLeftBackgroundImage} />
            )}
            <Element name="errorElement" className={styles.errorElement}>
              <ErrorComponent
                containerId="errors"
                condition={!isValid && validateErrors}
                errors={errors}
                dataDTM={dataDTM}
              />
            </Element>
            <GridWrapper
              container
              className={clsx(styles.textBox, 'form-header--page')}
            >
              {!isEnergy && (<Typography variant="modalTitle" component="h2">
                {t('STAY CONNECTED')}
              </Typography>)}

              {isEnergy && (
                <Typography
                  variant="modalTitle"
                  component="h2"
                  className={styles.modalTitle}
                >
                  {t('Let’s Stay')} <span className="blue-text">{t('Connected')}</span>
                </Typography>
              )}

              {isGMC && isModal && <Box className="modal-border" />}

              {(isChevy ||
                isGMC ||
                isBuick ||
                isEVLive ||
                isEVLiveCanada ||
                isEVLiveCanadaFrench ||
                isEnergy
              ) && (
                <>
                  <Typography
                    variant={isBuick || isEVLive || isEVLiveCanada ? 'modalSubTextAlt' : 'modalSubText'}
                    component="p"
                    className={styles.modalSubText}
                  >
                    {t(
                      'Sign up to get notifications on the latest Chevrolet products, current offers, events, and more.',
                    )}
                  </Typography>

                  {(!isEVLive && !isEVLiveCanada && !isEnergy) && (
                    <Typography
                      variant="modalSubText"
                      component="p"
                      className={styles.modalSubText}
                    >
                      {t(
                        'Thank you for your interest in Chevrolet and/or other GM vehicles. U.S. residents, please fill out the required fields below. Non-U.S. residents, please visit your country-specific GM website and/or your local dealer for further information.',
                      )}
                    </Typography>
                  )}

                  <Typography
                    variant="modalSubText"
                    component="p"
                          className={clsx(styles.modalSubText, 'modalSubTextBottom modalSubText-StayUpdatedWidth mb-0')}
                    dangerouslySetInnerHTML={{
                      __html: t<string>(
                        'Stay Updated: By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.',
                      ),
                    }}
                  />
                </>
              )}

              {isCadillacUS && (
                <>
                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={styles.modalSubText}
                    dangerouslySetInnerHTML={{
                      __html: t<string>(
                        "By providing my contact information below, I acknowledge that my information will be handled in accordance with GM's Privacy Statement, and I consent that Cadillac, GM, and/or a GM dealer can contact me with offers and product information.",
                      ),
                    }}
                  />
                </>
              )}

              {isCadillacCanada && (
                <>
                  <Typography variant="modalSubText" component="p" className={styles.modalSubText}>
                    {t(
                      'Fill out the form here to receive email and other electronic communications with news, offers and updates about products and services from Cadillac andGeneral Motors of Canada Company. You understand that you may withdraw consent at any time*Form pops up in a modal to capture this information:',
                    )}
                  </Typography>

                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={styles.modalSubText}
                    dangerouslySetInnerHTML={{
                      __html: t<string>(
                        'Stay Updated: By providing my contact information below, I consent that Cadillac, GM and/or a GM dealer can contact me with offers and product information.',
                      ),
                    }}
                  />
                </>
              )}

              {(isCadillacUS ||
                isCadillacCanada ||
                isGMC ||
                isBuick ||
                isEVLive ||
                isEVLiveCanada ||
                isEVLiveCanadaFrench
              ) && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t<string>('* Indicates Required Fields'),
                  }}
                  className={clsx(styles.modalSubText, styles.indicatesRequiredFields)}
                />
              )}
            </GridWrapper>
            <form id="stay-updated" onSubmit={handleCheckForm} noValidate className={clsx(styles.form, 'form')}>
              <Box className={styles.boxContainer}>
                <Container>
                  <Grid>
                    <Grid container spacing={3} className={clsx(styles.gridContainer, 'grid-container')}>
                      <GridWrapper item md={6} xs={12} id="firstName">
                        <InputLabel className={styles.formLabel}>
                          <span dangerouslySetInnerHTML={{ __html: t('First Name (Required):') }} />
                        </InputLabel>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          error={Boolean(touched.firstName && errors.firstName)}
                          fullWidth
                          helperText={touched.firstName && errors.firstName}
                          name="firstName"
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('firstName', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                          required
                          value={values.firstName}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('First Name') : null}
                        />
                      </GridWrapper>
                      <GridWrapper item md={6} xs={12} id="lastName" className={styles.stayUpdatedInputWrapper}>
                        <InputLabel className={styles.formLabel}>
                          <span dangerouslySetInnerHTML={{ __html: t('Last Name (Required):') }} />
                        </InputLabel>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          error={Boolean(touched.lastName && errors.lastName)}
                          fullWidth
                          helperText={touched.lastName && errors.lastName}
                          name="lastName"
                          onBlur={(e) => {
                            setFieldValue('lastName', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                          onChange={handleChange}
                          required
                          value={values.lastName}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Last Name') : null}
                        />
                      </GridWrapper>
                      <GridWrapper item md={6} xs={12} id="email">
                        <InputLabel className={clsx(styles.inputLabel, styles.formLabel)}>
                          <span
                            dangerouslySetInnerHTML={{ __html: t('Email Address (Required):') }}
                          />
                        </InputLabel>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          error={Boolean(touched.email && errors.email)}
                          type="email"
                          fullWidth
                          helperText={touched.email && errors.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let email = e.target.value;
                            if (isCadillacUS || isCadillacCanada) email = email.toLowerCase();
                            setFieldValue('email', email);
                          }}
                          required
                          value={values.email}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Email') : null}
                        />
                      </GridWrapper>
                      <GridWrapper item md={6} xs={12} id="confirmEmail" className={styles.stayUpdatedInputWrapper}>
                        <InputLabel className={clsx(styles.inputLabel, styles.formLabel)}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t('Confirm Email Address (Required):'),
                            }}
                          />
                        </InputLabel>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                          type="email"
                          fullWidth
                          helperText={touched.confirmEmail && errors.confirmEmail}
                          name="confirmEmail"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let email = e.target.value;
                            if (isCadillacUS || isCadillacCanada) email = email.toLowerCase();
                            setFieldValue('confirmEmail', email);
                          }}
                          required
                          value={values.confirmEmail}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Confirm Email') : null}
                        />
                      </GridWrapper>

                      {(isEVLive || isEVLiveCanada || isEnergy) && (
                        <GridWrapper item md={6} xs={12} id="city">
                          <InputLabel className={styles.formLabel}>
                            <span dangerouslySetInnerHTML={{ __html: t('City (Required):') }} />
                          </InputLabel>
                          <TextFieldForm
                            className="stat-input-field"
                            data-dtm={dataDTM}
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            name="city"
                            onBlur={(e) => {
                              setFieldValue('city', trimSpaces(e.target.value));
                              handleBlur(e);
                            }}
                            onChange={handleChange}
                            required
                            value={values.city}
                            variant={isEnergy ? "filled" : "outlined"}
                            label={isEnergy ? t('City') : null}
                          />
                        </GridWrapper>
                      )}

                      {(isEnergy && (
                        <GridWrapper item md={6} xs={12} id="state" className={styles.stayUpdatedInputWrapper}>
                          <InputLabel className={clsx(styles.inputLabel, styles.formLabel)}>
                            <span dangerouslySetInnerHTML={{ __html: t('State (Required):') }} />
                          </InputLabel>
                          <GridWrapper className={clsx(styles.selectWrapper, 'select-wrapper')}>
                          <FormControl fullWidth variant="standard">
                          <InputLabel>{t('State (Required):')}</InputLabel>
                            <SelectBasicField
                              className="stat-dropdown"
                              data-dtm={`${dataDTM}:state`}
                              variant="outlined"
                              fullWidth
                              name="state"
                              value={values.state || ""}
                              error={Boolean(touched.state && errors.state && !isStateOpen)}
                              onBlur={handleBlur}
                              onFocus={() => setFieldTouched('state', true)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              required
                              MenuProps={EnergyMenuProps}
                              open={isStateOpen}
                              onOpen={() => setIsStateOpen(true)}
                              onClose={() => setIsStateOpen(false)}
                            >
                              {usStates.map((state) => (
                                <MenuItem
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                  sx={{
                                    py: '16px',
                                    pr: 0,
                                    fontWeight: values.state === state.abbreviation ? 'bold' : 'normal',
                                }}>
                                  {values.state === state.abbreviation && renderCheckedIcon()}
                                  {values.state !== state.abbreviation && renderUncheckedIcon()}
                                  {state.abbreviation}
                                </MenuItem>
                              ))}
                            </SelectBasicField>
                          </FormControl>
                          <div
                            className="chevron-icon"
                            style={{
                              backgroundImage: theme.palette.background.chevronClosedStateIcon,
                              transform: isStateOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                          />
                          </GridWrapper>
                          {touched.state && errors.state && !isStateOpen && (
                            <FormHelperText error>
                              {t('Please provide a state.')}
                            </FormHelperText>
                          )}
                        </GridWrapper>
                      ))}

                      {isCadillacCanada || isEVLiveCanada ? (
                        <GridWrapper item md={6} xs={12} id="postalCode">
                          <InputLabel>
                            <span dangerouslySetInnerHTML={{ __html: t('Postal Code*') }} />
                          </InputLabel>
                          <TextFieldForm
                            className="stat-input-field"
                            data-dtm={dataDTM}
                            error={Boolean(touched.postalCode && errors.postalCode)}
                            fullWidth
                            helperText={touched.postalCode && errors.postalCode}
                            name="postalCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.postalCode}
                            variant="outlined"
                          />
                        </GridWrapper>
                      ) : (
                        <GridWrapper item md={6} xs={12} id="zipCode">
                          <InputLabel className={styles.formLabel}>
                            <span dangerouslySetInnerHTML={{ __html: t('Zip Code (Required):') }} />
                          </InputLabel>
                          <TextFieldForm
                            className="stat-input-field"
                            data-dtm={dataDTM}
                            error={Boolean(touched.zipCode && errors.zipCode)}
                            fullWidth
                            helperText={touched.zipCode && errors.zipCode}
                            name="zipCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.zipCode}
                            variant={isEnergy ? "filled" : "outlined"}
                            label={isEnergy ? t('Zip Code') : null}
                          />
                        </GridWrapper>
                      )}

                      {isEnergy && <GridWrapper item md={6} xs={12} id="phoneNumber" className={styles.stayUpdatedInputWrapper}>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          error={Boolean(touched.phone && errors.phone)}
                          type="phone"
                          fullWidth
                          helperText={touched.phone && errors.phone}
                          name="phone"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let phone = e.target.value;
                            setFieldValue('phone', phone);
                          }}
                          required
                          value={values.phone}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Phone Number') : null}
                        />
                        {!(touched.phone && errors.phone) && (
                          <FormHelperText>{t('Optional')}</FormHelperText>
                        )}
                       </GridWrapper>}

                      {isEnergy && (
                        <GridWrapper item md={6} xs={12} id="productOfInterest">
                          <InputLabel className={clsx(styles.inputLabel, styles.formLabel)}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t('Select product of interest:*'),
                              }}
                            />
                          </InputLabel>
                          <GridWrapper className={clsx(styles.selectWrapper, 'select-wrapper')}>
                            <FormControl fullWidth variant="standard">
                            <InputLabel>{t('Select Product of Interest')}</InputLabel>
                            <SelectBasicField
                              className="stat-dropdown"
                              data-dtm="stay connected:product of interest"
                              name="productOfInterest"
                              fullWidth
                              label={t("Select Product of Interest")}
                              value={values.productOfInterest || ""}
                              error={Boolean(touched.productOfInterest && errors.productOfInterest && !isProductOfInterestOpen)}
                              onBlur={handleBlur}
                              onFocus={() => setFieldTouched('productOfInterest', true)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              required
                              MenuProps={EnergyMenuProps}
                              open={isProductOfInterestOpen}
                              onOpen={() => setIsProductOfInterestOpen(true)}
                              onClose={() => setIsProductOfInterestOpen(false)}
                            >
                              {topics && topics.map(product => (
                                <MenuItem
                                  key={product.name}
                                  value={product.name} 
                                  sx={{
                                      py: '16px',
                                      pr: 0,
                                      fontWeight: values.productOfInterest === product.name ? 'bold' : 'normal',
                                      whiteSpace: 'normal',
                                    }}
                                >
                                  {values.productOfInterest === product.name && renderCheckedIcon()}
                                  {values.productOfInterest !== product.name && renderUncheckedIcon()}
                                  {product.name}
                                </MenuItem>
                              ))}
                            </SelectBasicField>
                            </FormControl>
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundImage: theme.palette.background.chevronClosedStateIcon,
                                transform: isProductOfInterestOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                              }}
                            />
                          </GridWrapper>
                          {touched.productOfInterest && errors.productOfInterest && !isProductOfInterestOpen && (
                            <FormHelperText error>
                              {t('Please select a product of interest.')}
                            </FormHelperText>
                          )}
                        </GridWrapper>
                      )}

                      {!isCadillacCanada && purchaseDate && (
                        <GridWrapper item md={6} xs={12} id="purchaseDate" className={styles.stayUpdatedInputWrapper}>
                          <InputLabel className={clsx(styles.inputLabel, styles.formLabel)}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t('Estimated Purchase Date (Required):'),
                              }}
                            />
                          </InputLabel>
                          <GridWrapper className={styles.selectWrapper}>
                          {isEVLiveCanada ? <SelectField
                              className="stat-dropdown"
                              data-dtm={`${dataDTM}:purchase date`}
                              variant="outlined"
                              fullWidth
                              name="purchaseDate"
                              value={values.purchaseDate || t('--Select--')}
                              error={Boolean(touched.purchaseDate && errors.purchaseDate)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              required
                            >
                              <option disabled value={t<string>('--Select--')}>
                                {t('--Select--')}
                              </option>
                              {evCanPurchaseDates.map((date) => (
                                <option key={date.id} value={date.id}>
                                  {date.value}
                                </option>
                              ))}
                            </SelectField> : 
                            isEnergy ? <FormControl fullWidth variant="standard">
                            <InputLabel>{t('Estimated Purchase Date')}</InputLabel>
                            <SelectBasicField
                              className="stat-dropdown"
                              data-dtm={`${dataDTM}:purchase date`}
                              variant="outlined"
                              fullWidth
                              name="purchaseDate"
                              value={values.purchaseDate || ""}
                              error={Boolean(touched.purchaseDate && errors.purchaseDate)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              required
                              MenuProps={EnergyMenuProps}
                              open={isPurchaseDateOpen}
                              onOpen={() => setIsPurchaseDateOpen(true)}
                              onClose={() => setIsPurchaseDateOpen(false)}
                            >
                              <MenuItem disabled value={t<string>('--Select--')}>
                                {t('--Select--')}
                              </MenuItem>
                              {purchaseDate.map((date) => (
                                <MenuItem key={date.id} value={date.value}
                                sx={{
                                  py: '16px',
                                  pr: 0,
                                  fontWeight: values.purchaseDate === date.value ? 'bold' : 'normal',
                                }}>
                                  {values.purchaseDate === date.value && renderCheckedIcon()}
                                  {values.purchaseDate !== date.value && renderUncheckedIcon()}
                                  {date.value}
                                </MenuItem>
                              ))}
                            </SelectBasicField>
                            </FormControl> : 
                            <SelectField
                              className="stat-dropdown"
                              data-dtm={`${dataDTM}:purchase date`}
                              variant="outlined"
                              fullWidth
                              name="purchaseDate"
                              value={values.purchaseDate || t('--Select--')}
                              error={Boolean(touched.purchaseDate && errors.purchaseDate)}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              required
                            >
                              <option disabled value={t<string>('--Select--')}>
                                {t('--Select--')}
                              </option>
                              {purchaseDate.map((date) => (
                                <option key={date.id} value={date.id}>
                                  {date.value}
                                </option>
                              ))}
                            </SelectField>}
                            {isChevy && (
                              <div className="dropdown-icon">
                                <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                              </div>
                            )}
                            {(isCadillacUS || isCadillacCanada || isGMC || isBuick || isEVLive || isEVLiveCanada || isEnergy) && (
                              <div
                                className="chevron-icon"
                                style={{
                                  backgroundImage: theme.palette.background.chevronClosedStateIcon,
                                  transform: isPurchaseDateOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                  fontSize: isBuick ? '2rem' : '1.5rem',
                                }}
                              />
                            )}
                          </GridWrapper>
                          {touched.purchaseDate && errors.purchaseDate && (
                            <FormHelperText error>
                              {t('This field is required in the standard format.')}
                            </FormHelperText>
                          )}
                        </GridWrapper>
                      )}

                      {!isEnergy && (
                        <>
                          <GridWrapper item md={6} xs={12} id="vehiclesOfInterest">
                            <InputLabel className={styles.inputLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Select Vehicle Type(s) (Required):'),
                                }}
                              />
                            </InputLabel>
                            <TableContainer
                              className={styles.vehicleType}
                              onClick={() => {
                                setOpenModule(!openModule);
                              }}
                            >
                              <Table id="vehicleTypeTable">
                                <TableBody
                                  className={`${styles.border} ${
                                    !isBuick && touched.vehiclesOfInterest && errors.vehiclesOfInterest
                                      ? styles.vehicleTypeError
                                      : ' '
                                  }`}
                                >
                                  <TableRow
                                    className="stat-expand-icon"
                                    data-dtm={vehiclesOfInterestDataDtm}
                                    sx={{
                                      td: {
                                        border: 'none',
                                      },
                                    }}
                                  >
                                    <TableCell className={styles.vehicleTypeCell}>
                                      <Typography
                                        className={styles.vehicleTypeHeading}
                                        variant="vehicleTypeHeading"
                                        component="p"
                                      >
                                        {t((isEVLive || isEVLiveCanada) ? 'Brands' : 'Vehicles')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      className={styles.vehicleTypeCell}
                                      style={{
                                        width: '40px',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => {
                                          setOpenModule(!openModule);
                                        }}
                                        className={styles.vehicleTypeIcon}
                                      >
                                        {openModule ? (
                                          isCadillacUS ||
                                          isCadillacCanada ||
                                          isGMC ||
                                          isBuick ||
                                          isEVLive ||
                                          isEVLiveCanada ? (
                                            <div
                                              className="chevron-icon"
                                              style={{
                                                backgroundImage:
                                                  theme.palette.background.chevronClosedStateIcon,
                                                transform: 'rotate(180deg)',
                                              }}
                                            />
                                          ) : (
                                            <RemoveCircleOutlineIcon />
                                          )
                                        ) : isCadillacUS ||
                                          isCadillacCanada ||
                                          isGMC ||
                                          isBuick ||
                                          isEVLive ||
                                          isEVLiveCanada ? (
                                          <div
                                            className="chevron-icon"
                                            style={{
                                              backgroundImage:
                                                theme.palette.background.chevronClosedStateIcon,
                                            }}
                                          />
                                        ) : (
                                          <AddCircleOutlineIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      td: {
                                        borderBottom: 'none',
                                      },
                                    }}
                                  >
                                    <TableCell colSpan={4} className={styles.vehicleTypeTableCell}>
                                      <Collapse in={openModule} timeout="auto">
                                        <Table>
                                          <TableBody>
                                            {!isBuick && (
                                              <TableRow className={styles.tableRowBorderTop}>
                                                <TableCell
                                                  className={styles.vehicleTypeList}
                                                  colSpan={2}
                                                >
                                                  <FormControlLabel
                                                    style={{ padding: 0, margin: 0 }}
                                                    className={clsx(styles.rename, 'stat-checkbox')}
                                                    data-dtm={vehiclesOfInterestDataDtm}
                                                    control={
                                                      <Checkbox
                                                        onChange={(event, checked) => {
                                                          if (checked) {
                                                            setFieldValue('names', [
                                                              event.target.value,
                                                            ]);
                                                            setOpenSelectVehicle(true);
                                                          } else {
                                                            setFieldValue('names', []);
                                                          }
                                                        }}
                                                        icon={renderSelectVehicleCheckIcon()}
                                                        checkedIcon={renderSelectVehicleCheckedIcon()}
                                                      />
                                                    }
                                                    label={t<string>(allVehiclesText)}
                                                    name="names"
                                                    value={allVehiclesText}
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            {vehicleTypes &&
                                              vehicleTypes.map((vehicleType) => (
                                                <TableRow
                                                  className={styles.tableRowBorderTop}
                                                  key={vehicleType.name}
                                                >
                                                  <TableCell
                                                    className={styles.vehicleTypeList}
                                                    colSpan={2}
                                                  >
                                                    <FormControlLabel
                                                      style={{ padding: 0, margin: 0 }}
                                                      className={clsx(styles.rename, 'stat-checkbox')}
                                                      data-dtm={`${dataDTM}:vehicle type`}
                                                      control={
                                                        <Checkbox
                                                          onChange={(event, checked) => {
                                                            if (checked) {
                                                              setFieldValue('names', [
                                                                ...values.names,
                                                                event.target.value,
                                                              ]);
                                                              setOpenSelectVehicle(true);
                                                            } else {
                                                              const filteredTypes = values.names.filter(
                                                                (name) => name !== event.target.value,
                                                              );
                                                              setFieldValue('names', [
                                                                ...filteredTypes,
                                                              ]);
                                                            }
                                                          }}
                                                          icon={renderSelectVehicleCheckIcon()}
                                                          checkedIcon={renderSelectVehicleCheckedIcon()}
                                                        />
                                                      }
                                                      label={t<string>(vehicleType.name)}
                                                      name="names"
                                                      checked={
                                                        values.names.length > 0 &&
                                                        values.names.indexOf(vehicleType.name) > -1
                                                          ? true
                                                          : false
                                                      }
                                                      value={vehicleType.name}
                                                      disabled={
                                                        values.names.indexOf(allVehiclesText) > -1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {!isBuick && touched.vehiclesOfInterest && errors.vehiclesOfInterest && (
                              // can't update through translation as there's same one on reservation
                              <FormHelperText error>{t('Please select a vehicle.')}</FormHelperText>
                            )}
                          </GridWrapper>
                          <GridWrapper item md={6} xs={12}>
                            <InputLabel className={styles.inputLabel}>
                              <span dangerouslySetInnerHTML={{ __html: t('Select Vehicle:') }} />
                            </InputLabel>
                            <TableContainer
                              className={`${styles.border} ${styles.scrollBar} ${
                                styles.selectVehicleHeight
                              } ${values.names.length > 0 ? styles.vehicleSelect : ''} ${
                                touched.vehiclesOfInterest && errors.vehiclesOfInterest
                                  ? styles.vehicleTypeError
                                  : ' '
                              }`}
                            >
                              {(isGMC || isBuick || isEVLive || isEVLiveCanada) && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    setOpenSelectVehicle(!openSelectVehicle);
                                  }}
                                  className={clsx(
                                    styles.vehicleTypeIcon,
                                    styles.vehicleTypeIconSelectVehicle,
                                    'stat-expand-icon',
                                  )}
                                  data-dtm={selectVehicleDataDtm}
                                >
                                  {openSelectVehicle ? (
                                    <div
                                      className="chevron-icon"
                                      style={{
                                        backgroundImage:
                                          theme.palette.background.chevronClosedStateIcon,
                                        transform: 'rotate(180deg)',
                                      }}
                                    />
                                  ) : (
                                    <div
                                      className="chevron-icon"
                                      style={{
                                        backgroundImage:
                                          theme.palette.background.chevronClosedStateIcon,
                                      }}
                                    />
                                  )}
                                </IconButton>
                              )}
                              <Collapse in={openSelectVehicle} timeout="auto">
                                <Table id="vehicleTable">
                                  <TableBody>
                                    {values.names.length > 0 &&
                                      !isEnergy && dataFormatter(values.names).map((group) => (
                                        <React.Fragment key={group.type}>
                                          {(JSON.stringify(values.names).includes(group.type) ||
                                            JSON.stringify(values.names).includes(allVehiclesText)) && (
                                            <TableRow>
                                              <TableCell className={styles.vehicleHeading}>
                                                <Typography
                                                  variant="vehicleSelectHeading"
                                                  component="p"
                                                >
                                                  {t(group.type)}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          )}
                                          {group.data.length > 0 &&
                                            group.data.map((vehicle) => (
                                              <TableRow key={vehicle.id}>
                                                <TableCell
                                                  className="stat-checkbox"
                                                  data-dtm={`${modelOfInterest}:${group.type.toLowerCase()}`}
                                                  style={{
                                                    padding: 0,
                                                    margin: 0,
                                                    outline: 'none',
                                                    border: 'none',
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    style={{ padding: 0, margin: 0 }}
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          values.vehiclesOfInterest &&
                                                          values.vehiclesOfInterest.find(
                                                            (v) => v.name === vehicle.name,
                                                          ) !== undefined
                                                        }
                                                        onChange={async (event, checked) => {
                                                          setFieldTouched('vehiclesOfInterest');
                                                          if (checked) {
                                                            const selectedVehicles = (isEVLive || isEVLiveCanada)
                                                              ? []
                                                              : values.vehiclesOfInterest;
                                                            const newValues = [
                                                              ...selectedVehicles,
                                                              {
                                                                name: event.target.value,
                                                                type: group.type,
                                                              },
                                                            ];
                                                            //await setSelectedVehicles(newValues);
                                                            setSelectedVehicleBrand(group.type);
                                                            setFieldValue(
                                                              `vehiclesOfInterest`,
                                                              newValues,
                                                            );
                                                          } else {
                                                            const filteredVoi =
                                                              values.vehiclesOfInterest.filter(
                                                                (voiObject: {
                                                                  name: string;
                                                                  type: string;
                                                                }) =>
                                                                  voiObject.name !== event.target.value,
                                                              );
                                                            setFieldValue(
                                                              `vehiclesOfInterest`,
                                                              filteredVoi,
                                                            );
                                                          }
                                                        }}
                                                        icon={renderSelectVehicleCheckIcon()}
                                                        checkedIcon={renderSelectVehicleCheckedIcon()}
                                                      />
                                                    }
                                                    label={t(vehicle.name).toString()}
                                                    name="vehiclesOfInterest"
                                                    value={vehicle.name}
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </React.Fragment>
                                      ))}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableContainer>
                            {isBuick && touched.vehiclesOfInterest && errors.vehiclesOfInterest && (
                              // can't update through translation as there's same one on reservation
                              <FormHelperText error>{t('Please select 1-3 vehicles.')}</FormHelperText>
                            )}
                          </GridWrapper>
                        </>
                      )}

                      {(isChevy || isGMC || isBuick || isEVLive || isEVLiveCanada) && (
                        <>
                          <Grid container style={{ paddingLeft: '24px' }}>
                            <Grid
                              item
                              md={6}
                              xs={12}
                              id="ownershipType"
                              className={styles.ratioGroupMargin}
                            >
                              {(isChevy || isGMC || isBuick || isEVLiveCanada || (isEVLive && selectedVehicleBrand !== 'Cadillac')) && (
                                <InputLabel className={styles.planningTo}>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: t("I'm planning to (required):"),
                                    }}
                                  />
                                </InputLabel>
                              )}
                              {(isChevy || isEVLiveCanada || (isEVLive && selectedVehicleBrand !== 'Cadillac')) && (
                                <RadioGroup
                                  name="ownershipType"
                                  onChange={(event) => {
                                    setFieldValue('ownershipType', event.currentTarget.value);
                                  }}
                                  row
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    className={styles.planningContainer}
                                  >
                                    <Grid item xs={12} md={6}>
                                      <FormControlLabel
                                        className="stat-radio"
                                        data-dtm={imPlanningToDataDtm}
                                        value={isEVLiveCanada ? "P": "SA00000151"}
                                        control={<Radio />}
                                        label={t<string>('Purchase')}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormControlLabel
                                        className="stat-radio"
                                        data-dtm={imPlanningToDataDtm}
                                        value={isEVLiveCanada ? "L": "SA00000150"}
                                        control={<Radio />}
                                        label={t<string>('Lease')}
                                      />
                                    </Grid>
                                  </Grid>
                                </RadioGroup>
                              )}
                              {(isGMC || isBuick) && (
                                <RadioGroup
                                  name="ownershipType"
                                  onChange={(event) => {
                                    setFieldValue('ownershipType', event.currentTarget.value);
                                  }}
                                  row
                                  value={planningValue}
                                >
                                  <Grid
                                    className={styles.planningContainer}
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={isGMC || isBuick ? 6 : 12} md={6}>
                                      <FormControlLabel
                                        className={clsx(styles.formControlLabel, 'stat-checkbox')}
                                        data-dtm={imPlanningToDataDtm}
                                        value="SA00000151"
                                        control={
                                          <Radio
                                            checkedIcon={renderPurchaseLeaseCheckedIcon()}
                                            icon={renderPurchaseLeaseUncheckedIcon()}
                                          />
                                        }
                                        label={t<string>('Purchase')}
                                        onClick={handlePlanningClick}
                                      />
                                    </Grid>
                                    <Grid item xs={isGMC || isBuick ? 6 : 12} md={6}>
                                      <FormControlLabel
                                        className={clsx(styles.formControlLabel, 'stat-checkbox')}
                                        data-dtm={imPlanningToDataDtm}
                                        value="SA00000150"
                                        control={
                                          <Radio
                                            checkedIcon={renderPurchaseLeaseCheckedIcon()}
                                            icon={renderPurchaseLeaseUncheckedIcon()}
                                          />
                                        }
                                        label={t<string>('Lease')}
                                        onClick={handlePlanningClick}
                                      />
                                    </Grid>
                                  </Grid>
                                </RadioGroup>
                              )}
                              {touched.ownershipType && errors.ownershipType && (
                                <FormHelperText error>
                                  {t('This field is required in the standard format.')}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>

                          {isEVLiveCanada && (
                            <>
                              <GridWrapper
                                item
                                md={6}
                                xs={12}
                                id="receiveUpdates"
                                sx={{
                                  marginTop: '16px',
                                  '@media screen and (min-width: 768px)': {
                                    marginTop: 0,
                                  },
                                }}
                              >
                                <FormGroup
                                  sx={{
                                    borderBottom: 'none !important',
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className="stat-checkbox"
                                        data-dtm={dataDTM}
                                        value={receiveUpdatesValue}
                                        onChange={handleChange}
                                        onClick={(event) => handleReceiveUpdatesClick(event)}
                                        onBlur={handleBlur}
                                        checkedIcon={
                                          <>
                                            <CheckIcon className="check-icon" />
                                            <CheckBoxOutlineBlankIcon className="checkbox-square-icon" />
                                          </>
                                        }
                                        icon={
                                          <CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />
                                        }
                                      />
                                    }
                                    label={`${t('Opt Out')}`}
                                    name="receiveUpdates"
                                  />
                                </FormGroup>
                                <Typography
                                  variant="modalSubText"
                                  component="p"
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      'We would like to update your profile, and periodically provide you with information on new products and services that may be relevant to your interests based on information we have about you. If you wish the information provided be used solely to respond to your request, simply check the box. By clicking submit I confirm that I would like to receive emails from EV Live Canada and General Motors of Canada Company. I understand that I may withdraw my consent at any time and that my information will be used in accordance with the GM Privacy Policy.',
                                    ),
                                  }}
                                />
                              </GridWrapper>
                            </>
                          )}

                          <Grid
                            item
                            md={12}
                            xs={12}
                            id="offerType"
                            className={styles.ratioGroupMargin}
                          >
                            {(isChevy || isGMC || isEVLive) && (
                              <>
                                <FormLabel component="legend">
                                  <Typography
                                    component="h3"
                                    variant="formBody"
                                    sx={{ mb: 1, maxWidth: isEVLive ? '700px' : '100%' }}
                                    className={styles.formLabelLegend}
                                    dangerouslySetInnerHTML={{
                                      __html: t<string>(
                                        'You may be eligible to take advantage of an exclusive discount program from GM. Please select the program below that you would like to learn more about. (Optional) ',
                                      ),
                                    }}
                                  />
                                </FormLabel>
                                <RadioGroup
                                  name="offerType"
                                  value={value}
                                  onChange={(event) => {
                                    setFieldValue('offerType', event.currentTarget.value);
                                  }}
                                  row
                                >
                                  <Grid container direction="row" justifyContent="flex-start">
                                    {discountPrograms.map((discountProgram) => (
                                      <Grid
                                        key={discountProgram.id}
                                        item
                                        xs={12}
                                        md={buickCheckboxGridSpacing}
                                        lg={4}
                                      >
                                        <FormControlLabel
                                          className={clsx(styles.formControlLabel, 'stat-checkbox')}
                                          data-dtm={discountProgram.dataDTM}
                                          value={discountProgram.value}
                                          control={
                                            <Radio
                                              checkedIcon={renderCheckedIcon()}
                                              icon={renderUncheckedIcon()}
                                              onClick={handleClick}
                                            />
                                          }
                                          label={t<string>(discountProgram.label)}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                </RadioGroup>
                              </>
                            )}
                          </Grid>

                          {isChevy && (
                            <>
                              <Grid
                                item
                                md={6}
                                xs={12}
                                id="legendaryTruckOwner"
                                className={clsx(styles.legend, styles.ratioGroupMargin)}
                              >
                                <FormControl component="fieldset" variant="standard">
                                  <FormLabel component="legend">
                                    <Typography component="h3" variant="formBody">
                                      {t(
                                        'Chevy Truck Legends (Optional): A community for legendary truck owners and their Chevy Trucks. Please select yes to receive more information',
                                      )}
                                    </Typography>
                                  </FormLabel>
                                  <FormControlLabel
                                    value="CA00035526"
                                    control={
                                      <Checkbox
                                        className="stat-checkbox"
                                        data-dtm={`${dataDTM}:truck legends`}
                                        checkedIcon={<CheckBoxIcon />}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        name="legendaryTruckOwner"
                                        onChange={(event) => {
                                          setFieldValue(
                                            'legendaryTruckOwner',
                                            event.currentTarget.value,
                                          );
                                        }}
                                      />
                                    }
                                    label={t<string>('Yes')}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                                id="rewardsInformation"
                                className={clsx(styles.legend, styles.ratioGroupMargin)}
                              >
                                <FormControl component="fieldset" variant="standard">
                                  <FormLabel component="legend">
                                    <Typography component="h3" variant="formBody">
                                      {t(
                                        'My Chevrolet Rewards (Optional): A new way to earn points and save on GM Product and Services. Please select yes to receive more information.',
                                      )}
                                    </Typography>
                                  </FormLabel>
                                  <FormControlLabel
                                    value="CA00040021"
                                    control={
                                      <Checkbox
                                        className="stat-checkbox"
                                        data-dtm={`${dataDTM}:my chevrolet rewards`}
                                        checkedIcon={<CheckBoxIcon />}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        name="rewardsInformation"
                                        onChange={(event) => {
                                          setFieldValue(
                                            'rewardsInformation',
                                            event.currentTarget.value,
                                          );
                                        }}
                                      />
                                    }
                                    label={t<string>('Yes')}
                                  />
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      {isCadillacCanada && (
                        <>
                          <Grid item xs={12}>
                            <GridWrapper item md={6} xs={12} id="receiveUpdates">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      className="stat-checkbox"
                                      data-dtm={dataDTM}
                                      // value={values.receiveUpdates}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={receiveUpdatesValue}
                                      onClick={(event) => handleReceiveUpdatesClick(event)}
                                      checkedIcon={<><CheckIcon className={styles.checkIcon}/><CheckBoxOutlineBlankIcon /></>}
                                      icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                                    />
                                  }
                                  // label={t<string>('Receive Updates*')}
                                  // label={t<string>('Opt In to Electronic Marketing Communications')}
                                  label={t<string>('Opt Out')}
                                  name="receiveUpdates"
                                />
                              </FormGroup>
                              <Typography
                                variant="modalSubText"
                                component="p"
                                dangerouslySetInnerHTML={{
                                  __html: t('Stay Updated: Opt in for additional updates and offers. YES! I also want to receive email and other electronic communications with news, offers and updates about products and services from Cadillac and General Motors of Canada company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*')
                                }}
                              />
                            </GridWrapper>
                          </Grid>

                          <Typography
                            variant="modalSubText"
                            component="p"
                            sx={{
                              paddingLeft: '24px',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t('Stay Updated: By Clicking submit I confirm that I would like to receive emails from General Notors of Canada Company. I understand that I may withdraw my consent at any time and that my information will be used in accordance with the GM Privacy Policy')
                            }}
                          />
                        </>
                      )}
                    </Grid>

                    <GridWrapper
                      container
                      className={clsx(styles.buttonContainer, 'form-button-container')}
                    >
                      <LoadingButton
                        loading={isLoading}
                        disabled={isSubmitting}
                        onClick={() => {
                          validateForm()
                            .then(() => trackErrorMessages(errors))
                            .then(() => submitForm())
                            .catch((e) => new Error(e));
                          setValidateErrors(true);
                          executeScroll();
                        }}
                        variant="primary"
                        type="submit"
                        className={clsx('stat-button-link', styles.submitButton, 'submit-button')}
                        data-dtm={dataDTM}
                        aria-label="Submit form"
                      >
                        {t('Submit')}
                      </LoadingButton>
                      <TextButton
                        disableRipple
                        variant={(isGMC || isBuick || isEnergy) ? 'tertiary' : 'text'}
                        type="button"
                        onClick={onClickNoThank}
                        className={clsx(styles.noThanksButton, 'no-thanks-button', `${(isGMC || isBuick || isEnergy) ? 'stat-button-link' : 'stat-text-link'}`)}
                        data-dtm={dataDTM}
                        aria-label="No thanks"
                      >
                        {t('No thanks')}
                      </TextButton>
                    </GridWrapper>

                    {/* <Box className={styles.buttonContainer}>
                      <LoadingButton
                        loading={isLoading}
                        disabled={isSubmitting}
                        onClick={() => {
                          validateForm()
                            .then(() => trackErrorMessages(errors))
                            .then(() => submitForm())
                            .catch((e) => new Error(e));
                          setValidateErrors(true);
                          executeScroll();
                        }}
                        variant="primary"
                        type="submit"
                        className={clsx('stat-button-link', styles.submitButton, 'submit-button')}
                        data-dtm={dataDTM}
                        aria-label="Submit form"
                      >
                        {t('Submit')}
                      </LoadingButton>
                      <TextButton
                        disableRipple
                        variant={(isGMC || isBuick || isEnergy) ? 'tertiary' : 'text'}
                        type="button"
                        onClick={onClickNoThank}
                              className={clsx(styles.noThanksButton, 'no-thanks-button', `${(isGMC || isBuick || isEnergy) ? 'stat-button-link' : 'stat-text-link'}`)}
                        data-dtm={dataDTM}
                        aria-label="No thanks"
                      >
                        {t('No thanks')}
                      </TextButton>
                    </Box> */}

                    {isCadillacUS && (
                      <Box
                        sx={{
                          width: '100%',
                          marginTop: '35px',
                        }}
                      >
                        <Typography
                          variant="modalSubText"
                          component="p"
                        >
                          {t(
                            "DISCLAIMER: Email must be in a valid format. Example: “myname@provider.com” or “mname@provider.net.” Forwarding this link does not add your or the recipient's email address to the GM promotional email list.",
                          )}
                        </Typography>
                      </Box>
                    )}

                    {(isCadillacCanada || isEVLiveCanada) && (
                      <Grid sx={{ mt: '50px' }}>
                        <Typography
                          variant="modalSubText"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html: t<string>(
                              'stay updated: * General Motors of Canada Company (GM) is located at 500 Wentworth Street West, Oshawa, ON, L1J 0C5, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click "YES", your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',
                            ),
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </Box>
            </form>
          </>
      }
      </Grid>
               </>
        );
      }}
    </Formik>
  );
};
export default StayUpdatedView;

import { useState, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { useReporting } from './useReporting';
import { doc, getDoc } from 'firebase/firestore';
import { Brand, Reservation, Topic, Vehicle } from '@types';
import { useReservationToken } from './useReservationToken';
import { useBrandQueryString } from './useBrandQueryString';


export const useCheckReservation = (token, reservationId, brandId) => {
  const firestore = useFirestore();
  const { track } = useReporting();
  const userId = useReservationToken(token, reservationId);
  const [reservationData, setReservationData] = useState<Reservation>();
  const [withinReservation, setWithinReservation] = useState<boolean>();
  const queryParams = useBrandQueryString(brandId);
  
  const checkReservation = async () => {
    if (reservationId && token) {
      const ref = doc(firestore, 'reservations', reservationId);
      const reservation = (await getDoc(ref))?.data();
      let earlyOverride = 30; // default early value
      let overrideFound = false;
      if (reservation) {
        setReservationData(reservation as Reservation);
        // check for override at vehicle level
        if (reservation.vehicleId) {
          const vehicleRef = doc(firestore, `brands/${brandId}/vehicles`, reservation.vehicleId);
          const vehicleDoc = await getDoc(vehicleRef);
          if (vehicleDoc.exists()) {
            const vehicle = vehicleDoc.data() as Vehicle;
            if (vehicle.loungeArrivalOverride) {
              earlyOverride = vehicle.loungeEarlyOverride;
              overrideFound = true;
            }
          }
        }
        // check for override at topic level if no vehicle override found
        if (!overrideFound && reservation.topicId) {
          const topicRef = doc(firestore, `brands/${brandId}/topics`, reservation.topicId);
          const topicDoc = await getDoc(topicRef);
          if (topicDoc.exists()) {
            const topic = topicDoc.data() as Topic;
            if (topic.loungeArrivalOverride) {
              earlyOverride = topic.loungeEarlyOverride;
              overrideFound = true;
            }
          }
        }
        // check for brand level override if no vehicle / topic override found
        if (!overrideFound) {
          const brandRef = doc(firestore, 'brands', reservation.brandId);
          const brandDoc = await getDoc(brandRef);
          if (brandDoc.exists()) {
            const brand = brandDoc.data() as Brand;
            if (brand.loungeArrivalOverride) {
              earlyOverride = brand.loungeEarlyOverride;
            }
          }
        }

        console.log("early override value =>", earlyOverride);

        const scheduleTime = reservation.date.toDate();
        const currentTime = new Date();
        const timeDiff = Math.floor((scheduleTime.valueOf() - currentTime.valueOf())) / (1000 * 60);
        console.log(timeDiff);

        if (timeDiff > earlyOverride) {
          setWithinReservation(false);
          track('joined-early', { queryStringVariables: queryParams || [] });
        } else {
          setWithinReservation(true);
        }
      } else {
        setWithinReservation(true);
      }
    } else {
      setWithinReservation(true);
    }
  };
  useMemo(() => {
    if (userId) {
      checkReservation();
    }
  }, [userId]);
  return {
    withinReservation,
    reservationData,
  };
};

import { useTheme } from '@material-ui/core/styles';
import { evliveColors } from '@constants';

const ReservationViewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    root: {
      backgroundColor: theme.palette.background.formBackground,
      borderRadius: 'none',
      boxShadow: 'none',
    },
    form: {
      marginTop: '70px',
    },
    selectWrapper: {
      position: 'relative',
      '@global': {
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    timePickerContainer: {
      marginTop: '27px',
    },
    datePicker: {
      '@global': {
        '.MuiInputBase-root': {
          background: theme.palette.background.selectBackground,
          '&:hover': {
            background: theme.palette.background.selectBackgroundHover,
          },
        },
      },
    },
    // gridWrapper: {
    //   margin: '0 auto',
    //   paddingTop: '40px',
    //   paddingLeft: '15px',
    //   paddingRight: '15px',
    //   width: '100%',
    //   maxWidth: '940px',
    // },
    inputLabel: {
      whiteSpace: 'break-spaces',
      [theme.breakpoints.between(767, 991)]: {
        height: '40px',
      },
    },
    inputLabelWithoutBreakpoint: {
      whiteSpace: 'break-spaces',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    buttonContainerisModifyReservation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(768)]: {
        alignItems: 'flex-end',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up(768)]: {
        flexDirection: 'row',
      },
    },
    buttonsContainerIsModifyReservation: {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up(768)]: {
        flexDirection: 'row',
      },
    },
    submitButton: {},
    noThanksButton: {
      '&.MuiButton-text': {
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    cancelReservation: {
      marginTop: 20,
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
        marginRight: 45,
      },
    },
    calendarOpen: {
      '@global': {
        '.MuiInputBase-root': {
          background: theme.palette.background.selectBackgroundHover,
        },
      },
    },
    modalSubText: {
      maxWidth: '540px',
      marginBottom: '35px',
    },
    modalSubTextBottom: {
      marginBottom: '70px',
    },
    textBox: {
      maxWidth: '992px',
      margin: '0 auto',
      paddingLeft: '24px',
    },
    boxContainer: {
      marginBottom: '50px',
    },
    '@global': {
      '.MuiFormLabel-root': {
        fontSize: '16px',
      },
      '.MuiIconButton-root': {
        width: '460px',
        height: '40px',
      },
      '.MuiFormControlLabel-label': {
        display: 'block',
        position: 'relative',
        paddingLeft: '0px',
        marginBottom: '0',
        color: theme.palette.text.primary,
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '16px',
        textTransform: 'initial',
        cursor: 'pointer',
        userSelect: 'none',
      },
      '.MuiGrid-item': {
        paddingTop: '0 !important',
      },
      '.MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiOutlinedInput-notchedOutline .notranslate': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '.calendar-icon-transfer-base': {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: '#3F3F38',
        width: '50px',
        height: '52px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          width: '16px',
          height: '17px',
        },
      },
      '.transfer-mod-1': {
        height: '46px',
        '@media screen and (min-width: 768px)': {
          height: '52px',
        },
      },
      '.transfer-mod-2': {
        height: '40px !important',
      },
      '.transfer-mod-3': {
        '@media screen and (max-width: 768px)': {
          height: '46px',
        },
      },
      '.transfer-mod-4': {
        backgroundColor: '#808080',
        height: '40px',
      },
      '.transfer-mod-5': {
        backgroundColor: '#808080',
        height: '40px',
        width: '69px',
      },
      '.transfer-mod-6': {
        backgroundColor: '#808080',
        height: '54px',
      },
      '.transfer-mod-7': {
        backgroundColor: '#808080',
        height: '54px',
        '@media screen and (max-width: 768px)': {
          height: '48px',
        },
      },
      '.select-arrow-container-mod-1': {
        '@media screen and (max-width: 768px)': {
          height: '46px',
        },
      },
      '.transfer-shrink-text': {
        '@media screen and (max-width: 600px)': {
          textWrap: 'wrap',
          whiteSpace: 'break-spaces',
        },
      },
      '.transfer-left-column': {
        '@media screen and (min-width: 768px)': {
          paddingRight: '15px',
        },
      },
      '#adjustPadding': {
        paddingRight: '12px',
        '@media screen and (max-width: 768px)': {
          paddingRight: '0px',
        },
      },
      // '.MuiIconButton-edgeStart': {
      //   marginRight: '33px',
      // },
    },
    link: {
      fontWeight: 700,
      textDecoration: 'underline',
      color: 'inherit',
    },
    errorElement: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down(576)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    buttonArrow: {
      height: 12,
      width: '7.41px',
      marginLeft: '23.59px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up(768)]: {
        height: 20,
        width: '12.12px',
        marginLeft: '20.88px',
      },
    },
    numberOfOpenSlots: {
      marginLeft: '24px',
      padding: '4px 10px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '2px',
    },
  };

  const cadillacStyles = {
    textBox: {
      paddingLeft: '16px',
      paddingRight: '16px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
    boxContainer: {
      marginBottom: 0,
    },
    datePicker: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 52,
        height: 40,
        width: 1,
        backgroundColor: '#b3b3b3',
      },
      '&:hover': {
        '&::before': {
          backgroundColor: '#262626',
        },
      },
    },
    gridWrapper: {
      paddingTop: '40px',
      paddingBottom: '40px',
      [theme.breakpoints.up('md')]: {
        paddingTop: '80px',
        paddingBottom: '80px',
      },
    },
    buttonContainer: {
      marginTop: 36,
      [theme.breakpoints.up(768)]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    buttonsContainer: {
      justifyContent: 'center',
    },
    buttonsContainerIsModifyReservation: {
      [theme.breakpoints.up(768)]: {
        flexDirection: 'row-reverse',
        marginBottom: 10,
      },
      '@global': {
        '.MuiButton-root': {
          [theme.breakpoints.up(768)]: {
            width: 304,
          },
        },
      },
    },
    submitButton: {},
    noThanksButton: {
      marginTop: 10,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    cancelReservation: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginTop: 18,
      marginBottom: 0,
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
        marginLeft: 50,
        marginRight: 0,
      },
    },
    inputHeader: {
      marginTop: '25px',
      marginBottom: '40px !important',
      textAlign: 'center',
      [theme.breakpoints.up(768)]: {
        marginTop: '49px',
        textAlign: 'left',
      },
    },
    modalTitle: {
      maxWidth: '700px',
      margin: '0 auto',
    },
    modalSubText: {
      maxWidth: 'unset',
    },
    modalSubTextBottom: {
      marginBottom: '15px',
    },
    inputLabel: {
      [theme.breakpoints.between(767, 991)]: {
        height: '20px',
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '6px',
      right: '5px',
    },
    errorElement: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down(576)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    '@global': {
      '#receiveUpdates': {
        paddingLeft: 24,
      },
      '#optOut': {
        [theme.breakpoints.up(768)]: {
          paddingLeft: 24,
        },
      },
    },
    numberOfOpenSlots: {
      borderColor: theme.palette.grey[200],
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // cancelReservation: {
    //   ...cadillacStyles.cancelReservation,
    //   '&:hover': {
    //     color: '#fff',
    //   },
    // },
  };

  const gmcStyles = {
    gridWrapper: {
      paddingTop: '40px',
      paddingBottom: '40px',
      paddingLeft: '16px',
      paddingRight: '16px',
      maxWidth: 977,
      [theme.breakpoints.up('md')]: {
        paddingBottom: '80px',
      },
    },
    gridWrapperGMCEV: {
      paddingTop: '40px',
      paddingBottom: '40px',
      paddingLeft: '16px',
      paddingRight: '16px',
      maxWidth: 977,
      '@media screen and (max-width: 576px)': {
        paddingTop: '40px',
        paddingBottom: '40px',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
    },
    link: {
      color: theme.palette.warning.main,
      fontWeight: 'normal',
    },
    modalTitle: {
      marginBottom: '35px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '25px',
      },
    },
    modalSubTextBottom: {
      marginBottom: '30px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '15px',
      },
    },
    inputLabel: {
      // fontFamily: 'muliregular', // GL-1470 (remove)
      fontFamily: 'StratumGMC-Regular', // GL-1470
      fontSize: '20px !important',
      textTransform: 'capitalize',
      color: '#464646',
      letterSpacing: 0,
      [theme.breakpoints.down(768)]: {
        fontSize: '16px !important',
      },
    },
    datePicker: {
      '@global': {
        '.MuiInputBase-root': {
          background: 'transparent !important',
          '&:hover': {
            background: 'transparent !important',
          },
        },
      },
    },
    inputHeader: {
      marginTop: '18px',
      marginBottom: '0px !important',
      [theme.breakpoints.up(768)]: {
        marginTop: '24px',
        marginBottom: '24px !important',
      },
    },
    buttonContainer: {
      marginTop: '8px',
      [theme.breakpoints.up(768)]: {
        marginTop: '40px', // GL-1470
        // marginTop: 34, // GL-1470 (remove)
        justifyContent: 'flex-start',
        flexDirection: 'row',
      },
    },
    buttonContainerisModifyReservation: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    buttonsContainer: {
      width: 'auto',
    },
    buttonsContainerIsModifyReservation: {
      width: '100%',
      '@global': {
        '.MuiButton-root': {
          [theme.breakpoints.up(768)]: {
            width: 450,
            maxWidth: 450,
          },
        },
      },
      [theme.breakpoints.up(768)]: {
        marginBottom: 35,
        flexDirection: 'row-reverse',
      },
    },
    submitButton: {
      width: 280,
      [theme.breakpoints.up('lg')]: {
        width: 350,
      },
    },
    noThanksButton: {
      marginTop: '25px',
      width: 280,
      '&.MuiButton-text': {
        marginTop: 14,
        // GL-1470
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '45px',
        width: 350,
      },
    },
    cancelReservation: {
      width: 280,
      marginTop: 25,
      marginBottom: 0,
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
        marginLeft: 45,
        marginRight: 0,
        marginBottom: '0 !important',
      },
      [theme.breakpoints.up('lg')]: {
        width: 350,
      },
    },
    form: {
      [theme.breakpoints.down(768)]: {
        marginTop: '60px',
      },
    },
    errorElement: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    '@global': {
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 45px)',
          marginLeft: '-45px',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.MuiGrid-item': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '26px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '45px !important',
        },
      },
      '.calendar-icon-gmc': {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'black',
        width: '50px',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          width: '16px',
          height: '17px',
        },
      },
      // GL-1470 (Remove)
      // '.MuiTypography-modalSubText a': {
      //   display: 'table',
      //   fontWeight: 'inherit !important',
      // },
    },
    checkIcon: {
      position: 'absolute',
      top: '9px',
      right: '5px',
    },
    checkboxOutline: {
      color: 'transparent',
    },
    boxContainer: {
      marginBottom: '0px',
    },
    numberOfOpenSlots: {
      fontWeight: 'bold',
    },
    labelTime: {
      fontSize: '16px',
      lineHeight: '16px',
      fontFamily: 'StratumGMC-Regular',
      fontWeight: 'normal',
    },
  };

  const buickStyles = {
    gridWrapper: {
      paddingTop: '30px',
      paddingBottom: '60px',
      paddingLeft: '25px',
      paddingRight: '25px',
      maxWidth: 1010,
      [theme.breakpoints.up('md')]: {
        paddingTop: '68px',
        paddingBottom: '120px',
      },
    },
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
      marginTop: '10px',
      marginBottom: 10,
    },
    inputHeader: {
      marginTop: '20px',
      marginBottom: '0px !important',
      [theme.breakpoints.up(768)]: {
        marginTop: '40px',
        marginBottom: '30px !important',
      },
    },
    boxContainer: {
      marginBottom: 0,
    },
    buttonContainer: {
      marginTop: '25px',
      [theme.breakpoints.up(768)]: {
        marginTop: '52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    buttonContainerisModifyReservation: {
      [theme.breakpoints.up(768)]: {
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    buttonsContainer: {
      [theme.breakpoints.up(768)]: {
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
        '@global': {
          '.MuiButton-root': {
            width: '100%',
            maxWidth: '100%',
          },
        },
      },
    },
    buttonsContainerIsModifyReservation: {
      [theme.breakpoints.up(768)]: {
        width: '100%',
        maxWidth: '100%',
        marginBottom: 33,
        flexDirection: 'row-reverse',
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '9px',
      right: '5px',
      color: theme.palette.grey[700],
    },
    checkboxOutline: {
      color: 'transparent',
    },
    submitButton: {
      width: 'calc(50% + 30px)',
      maxWidth: 'calc(50% + 30px)',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    noThanksButton: {
      marginLeft: '60px',
      width: 'calc(50% + 30px)',
      maxWidth: 'calc(50% + 30px)',
      '&.MuiButton-text': {
        marginLeft: 0,
      },
      [theme.breakpoints.down(768)]: {
        marginTop: '25px',
        marginLeft: 0,
        width: '100%',
        maxWidth: '100%',
        '&.MuiButton-text': {
          marginTop: 12,
        },
      },
    },
    cancelReservation: {
      width: 'calc(50% + 30px)',
      maxWidth: 'calc(50% + 30px)',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 65,
      marginRight: 0,
      [theme.breakpoints.down(768)]: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 20,
        marginLeft: 0,
        marginRight: 0,
      },
    },
    buttonArrow: {
      marginTop: '-3px',
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
      },
    },
    errorElement: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    '@global': {
      '.MuiTypography-modalTitle br': {
        '@media screen and (min-width: 768px)': {
          display: 'none',
        },
      },
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 60px)',
          marginLeft: '-60px',
        },
      },
      '.MuiGrid-item': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '20px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '60px !important',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.calendar-icon-gmc': {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme.palette.grey[800],
        width: '50px',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          width: '16px',
          height: '17px',
        },
      },
      '.MuiTypography-modalSubText a': {
        display: 'table',
        fontWeight: 'inherit !important',
      },
    },
  };

  const evliveStyles = {
    gridWrapper: {
      paddingTop: '30px',
      paddingBottom: '30px',
      paddingLeft: '35px',
      paddingRight: '35px',
      maxWidth: 1014,
      [theme.breakpoints.up('md')]: {
        paddingTop: '93px',
        paddingBottom: '75px',
      },
    },
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
      fontWeight: '400',
      fontSize: 16,
      lineHeight: '28px',
    },
    modalSubTextBottom: {
      marginBottom: 16,
    },
    inputHeader: {
      marginTop: '18px',
      marginBottom: '8px !important',
      [theme.breakpoints.up(768)]: {
        marginTop: '18px',
        marginBottom: '36px !important',
      },
    },
    buttonContainer: {
      marginTop: '20px',
      [theme.breakpoints.up(768)]: {
        marginTop: '14px',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'column',
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '9px',
      right: '5px',
      color: theme.palette.grey[700],
    },
    checkboxOutline: {
      color: 'transparent',
    },
    buttonsContainerIsModifyReservation: {},
    submitButton: {},
    noThanksButton: {
      marginTop: '12.5px',
      [theme.breakpoints.up(768)]: {
        width: 'auto',
      },
    },
    cancelReservation: {
      marginTop: 22,
      marginBottom: 0,
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
        marginRight: 35,
      },
    },
    errorElement: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    boxContainer: {
      marginBottom: '0',
    },
    formGroup: {
      borderBottom: 'none !important',
    },
    gridContainerEVLiveCanada: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiFormControlLabel-label': {
        lineHeight: '28px',
      },
      '& #optOut': {
        marginBottom: '9px !important',
      },
      [theme.breakpoints.down(769)]: {
        flexDirection: 'column',
      },
    },
    inputLabel: {
      [theme.breakpoints.between(767, 991)]: {
        height: 'initial',
      },
    },
    datePicker: {
      '@global': {
        '.MuiInputBase-root': {
          background: 'transparent !important',
          '&:hover': {
            background: 'transparent !important',
          },
        },
      },
    },
    '@global': {
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 34px)',
        marginLeft: '-34px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 54px)',
          marginLeft: '-54px',
        },
      },
      '.MuiGrid-item': {
        paddingLeft: '34px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '20px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '54px !important',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.calendar-icon-gmc': {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme.palette.grey[800],
        width: '50px',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          width: '16px',
          height: '17px',
        },
      },
      '.check-icon': {
        backgroundColor: theme.palette.grey[200],
        fontSize: '1.3rem',
        color: theme.palette.grey[300],
      },
      '.checkbox-square-icon': {
        display: 'none !important',
      },
      '.checkbox-outlined-icon': {
        backgroundColor: theme.palette.grey[200],
        '& path': { display: 'none' },
        fontSize: '1.3rem',
      },
    },
  };

  const evliveStylesCanada = {
    ...evliveStyles,
  };

  const energyStyles = {
    inputLabel: {
      visibility: 'hidden',
      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },
    formEnergyMuiGridContainer: {
      '@global': {
        '.MuiGrid-container': {
          width: '100%,',
          marginLeft: '-24px',
          [theme.breakpoints.up(768)]: {
            marginLeft: '-24px',
            width: 'calc(100% + 24px)',
          },
        },
      }
    },
    formLabel: {
      display: 'none',
    },
    checkboxOutline: {
      visibility: 'hidden',
    },
    timePickerContainer: {
      marginTop: '24px',
      border: `1px solid ${theme.palette.primary.main}`,
      '& .MuiFormGroup-root': {
        paddingBottom: '16px',
      },
      '& .select-time-container': {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderLeft: 'unset',
        borderRight: 'unset',
        borderBottom: 'unset',
      },
      '& .MuiFormControlLabel-root': {
        marginLeft: '5px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.6rem',
      },
    },
    wrapperContainer: {
      [theme.breakpoints.down(768)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    errorElement: {
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiContainer-root': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
    datePicker: {
      '& #mui-1': {
        color: theme.palette.grey[300],
      },
    },
    dateSelected: {
      '& #mui-1': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    topicSelectedText: {
      '& #topic': {
        color: theme.palette.primary.main,
      },
    },
    topic: {
      padding: '10px 16px',
      height: '50px !important',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: '#707070',
        '& .MuiFormControlLabel-label': {
          color: '#ffffff',
        },
      },
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    topicSelected: {
      backgroundColor: '#707070',
      '& .MuiFormControlLabel-label': {
        color: '#ffffff',
        fontWeight: 700,
      },
    },
    timeSelected: {
      '& .MuiTypography-root': {
        fontWeight: 700,
      },
    },
    numberOfOpenSlots: {
      marginLeft: 0,
    },
    timeWrapper: {
      // width: '72%',
      marginLeft: '0px !important',
      '& .MuiFormControlLabel-label': {
        width: '100%',
      },
      // [theme.breakpoints.down(768)]: {
      //   width: '235px',
      // },
      '&:hover': {
        backgroundColor: '#707070',
        '& .MuiFormControlLabel-label': {
          color: '#ffffff',
        },
        '& .MuiSvgIcon-root': {
          color: '#ffffff',
        },
      },
    },
    labelTime: {
      width: 213,
    },
    boxContainer: {
      marginBottom: 0,
    },
    textReminderContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginBottom: '0',
      },
    },
    topLeftBackgroundImage: {
      backgroundImage: theme.palette.background.greenTopLeftDesktop,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 253,
      height: 248,
      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },
    '@global': {
      '.MuiContainer-root': {
        padding: 0,
        // padding: '0px 0px 0px 24px !important',
        // [theme.breakpoints.down(768)]: {
        //   padding: '0 !important',
        // },
      },
      '.calendar-icon': {
        height: '55px !important',
      },
      '.stat-checkbox': {
        border: '0 !important',
      },
      '.transfer-form-control-label': {
        borderLeft: `1px solid ${evliveColors.lightGrey} !important`,
        borderRight: `1px solid ${evliveColors.lightGrey} !important`,
      },
      '.transfer-time-picker-container': {
        border: `1px solid ${evliveColors.lightGrey} !important`,
      },
      '.transfer-select-time-container': {
        borderTop: '0 !important',
      },
      '.MuiRadio-root': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '.MuiFormControlLabel-label': {
        fontFamily: 'Overpass',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
      },
      '.MuiFormControlLabel-root': {
        marginRight: 0,
      },
      '.MuiGrid-item': {
        paddingLeft: '0 !important',
      },
      '#topic': {
        color: theme.palette.grey[300],
      },
      '.MuiInputLabel-root': {
        marginLeft: '3px',
      },
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStylesCanada,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};

export default ReservationViewStyles;

import { useTheme } from '@material-ui/core/styles';

export const StayUpdatedViewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    selectWrapper: {
      position: 'relative',
      '@global': {
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    inputLabel: {
      [theme.breakpoints.between(768, 1038)]: {
        whiteSpace: 'break-spaces',
        height: '40px',
      },
      [theme.breakpoints.down(480)]: {
        whiteSpace: 'break-spaces',
      },
    },
    formControlLabel: {
      marginBottom: '15px',
      [theme.breakpoints.down(575)]: {
        width: '200px',
      },
    },
    legend: {
      marginBottom: '35px',
    },
    ratioGroupMargin: {
      marginBottom: 30,
    },
    modalSubText: {
      maxWidth: '540px',
      marginBottom: '35px',
    },
    textBox: {
      maxWidth: '992px',
      margin: '0 auto',
      paddingLeft: '24px',
    },
    boxContainer: {
      marginBottom: '50px',
    },
    border: {
      border: `1px solid ${theme.palette.border.vehicleTypeBorder}`,
    },
    tableRowBorderTop: {
      td: {
        borderTop: `1px solid ${theme.palette.border.vehicleTypeBorder}`,
      },
    },
    submitButton: {},
    noThanksButton: {},
    indicatesRequiredFields: {},
    formLabelLegend: {
      '@global': {
        a: {
          fontWeight: 700,
          textDecoration: 'underline',
          color: 'inherit',
        },
      },
    },
    '@global': {
      '.MuiFormLabel-root': {
        fontSize: '16px',
      },
      '.MuiFormGroup-root': {
        [theme.breakpoints.down(576)]: {
          flexDirection: 'column !important',
          '& .MuiGrid-root': {
            flexDirection: 'column',
          },
        },
      },
      '.MuiIconButton-root': {
        width: '460px',
        height: '40px',
      },
      '.MuiGrid-item': {
        paddingTop: '0 !important',
      },
      '.Mui-checked': {
        color: `${theme.palette.grey[200]} !important`,
      },
      '.MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiOutlinedInput-notchedOutline .notranslate': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    vehicleSelect: {
      height: 'auto',
    },
    vehicleType: {
      cursor: 'pointer',
      overflow: 'hidden',
    },
    vehicleTypeError: {
      border: '3px solid rgba(226, 89, 89, 1)',
    },
    vehicleTypeCell: {
      padding: 0,
      margin: 0,
    },
    vehicleTypeHeading: {
      margin: '0 0 0 20px',
      padding: 0,
    },
    vehicleTypeIcon: {
      color: 'white',
      width: '70px',
      maxWidth: '70px',
      backgroundColor: theme.palette.background.formInputCustomIcon,
      float: 'right',
      '&:hover': {
        backgroundColor: theme.palette.background.formInputCustomIcon,
        color: 'white',
      },
    },
    vehicleTypeTableCell: {
      padding: 0,
      margin: 0,
    },
    vehicleTypeList: {
      padding: 0,
      margin: 0,
    },
    purchaseDateContainer: {
      marginBottom: 34,
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.down(768)]: {
        paddingRight: 0,
      },
    },
    rename: {
      position: 'relative',
      width: '100%',
      '&::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 36,
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    form: {
      marginTop: '70px',
    },
    selectVehicleHeight: {
      minHeight: '40px',
    },
    errorElement: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down(576)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    scrollBar: {
      '&::-webkit-scrollbar': {
        width: 10,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.formScrollTrack,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.formScrollThumb,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.background.formScrollThumbHover,
      },
    },
  };

  const cadillacStyles = {
    textBox: {
      paddingLeft: '16px',
      paddingRight: '16px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
    boxContainer: {
      marginBottom: 0,
    },
    vehicleTypeHeading: {
      margin: '0 0 0 10px',
      padding: 0,
    },
    vehicleTypeIcon: {
      color: theme.palette.primary.main,
      width: '40px',
      maxWidth: '40px',
      backgroundColor: 'transparent',
      float: 'right',
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    purchaseDateContainer: {
      paddingTop: '0 !important',
      marginBottom: 34,
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.down(768)]: {
        paddingRight: 0,
      },
    },
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    modalSubText: {
      marginBottom: '15px',
      maxWidth: 'unset',
      '@global': {
        a: {
          fontWeight: 700,
          textDecoration: 'underline',
        },
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '6px',
      right: '5px',
    },
    errorElement: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down(576)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    noThanksButton: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    '@global': {
      '.stat-dropdown': {
        '& option': {
          background: 'black !important',
        },
      },
      '#purchaseDate': {
        '& .MuiOutlinedInput-input': {
          paddingLeft: '0 !important',
        },
      },
      '.Mui-checked': {
        color: `${theme.palette.primary.main} !important`,
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
      // '#receiveUpdates': {
      //   '& .MuiCheckbox-root': {
      //     position: 'absolute',
      //     top: 0,
      //     left: -10,
      //   },
      //   '& .MuiTypography-modalSubText': {
      //     textIndent: 30,
      //     marginTop: 13,
      //     marginLeft: 13,
      //     fontSize: '10px !important',
      //   },
      // },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
  };

  const gmcStyles = {
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
      marginBottom: '15px',
    },
    border: {
      border: `2px solid ${theme.palette.border.vehicleTypeBorder}`,
    },
    tableRowBorderTop: {
      td: {
        borderTop: `2px solid ${theme.palette.border.vehicleTypeBorder}`,
      },
    },
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
      },
    },
    submitButton: {
      width: 240,
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 350,
      },
    },
    noThanksButton: {
      width: 240,
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '45px',
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 350,
      },
    },
    selectVehicleHeight: {
      minHeight: '49px',
      [theme.breakpoints.up('md')]: {
        minHeight: '58px',
      },
    },
    vehicleTypeIcon: {
      width: '48px',
      maxWidth: '48px',
      height: '48px',
      maxHeight: '48px',
      [theme.breakpoints.up('md')]: {
        width: '54px',
        maxWidth: '54px',
        height: '54px',
        maxHeight: '54px',
      },
    },
    errorElement: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    vehicleTypeHeading: {
      margin: '0 0 0 14px',
      padding: 0,
    },
    vehicleTypeError: {
      border: '2px solid rgba(170, 0, 0, 1)',
    },
    planningTo: {
      marginTop: '24px',
      // GL-1470
      fontFamily: 'StratumGMC-Regular',
      fontWeight: 'normal',
      lineHeight: '20px',
      [theme.breakpoints.up('md')]: {
        fonsSize: '19px',
        lineHeight: '24px',
      },
    },
    boxContainer: {
      marginBottom: 0,
    },
    planningContainer: {
      [theme.breakpoints.down(768)]: {
        '&.MuiGrid-root': {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          '& .MuiGrid-item': {
            maxWidth: '200px !important',
            '& .MuiFormControlLabel-root': {
              maxWidth: '110px',
            },
          },
        },
      },
    },
    formControlLabel: {
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    vehicleHeading: {
      '&.MuiTableCell-root': {
        padding: '6px 0px 6px 10px',
      },
    },
    vehicleTypeIconSelectVehicle: {
      position: 'absolute',
      right: 0,
      [theme.breakpoints.down(768)]: {
        top: '30px',
        right: '2px',
      },
    },
    formLabelLegend: {
      // GL-1470
      fontFamily: 'StratumGMC-Regular',
      fontWeight: 'normail',
      fontSize: '12px',
      lineHeight: '15px',
      [theme.breakpoints.up(768)]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      '@global': {
        a: {
          fontWeight: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    '@global': {
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 45px)',
          marginLeft: '-45px',
        },
      },
      '.MuiGrid-item': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '26px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '45px !important',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.MuiGrid-item#ownershipType': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '26px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '66px !important',
        },
      },
      '#ownershipType .MuiGrid-item': {
        marginBottom: '0px !important',
      },
      '#offerType .MuiGrid-item': {
        marginBottom: '0px !important',
      },
      '#offerType legend .MuiTypography-root': {
        marginBottom: 15,
      },
      '#offerType .MuiFormControlLabel-root .MuiTypography-root': {
        paddingLeft: 5,
        paddingRight: 0,
        [theme.breakpoints.up(768)]: {
          paddingRight: 50,
        },
      },
      '#ownershipType .MuiTypography-root': {
        paddingLeft: 5,
      },
      '.MuiTableCell-root': {
        borderBottom: 'none',
      },
      // '.stat-text-link': {
      //   display: 'table',
      //   color: `${theme.palette.color.privacyStatement} !important`,
      //   fontWeight: 'inherit !important',
      // },
      // GL-1470 (Remove)
      // '.MuiTypography-modalSubText a': {
      //   display: 'table',
      //   fontWeight: 'inherit !important',
      // },
    },
  };

  const buickStyles = {
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
      marginBottom: '15px',
    },
    border: {
      border: `1px solid ${theme.palette.grey[600]}`,
    },
    tableRowBorderTop: {
      td: {
        borderTop: `2px solid ${theme.palette.border.vehicleTypeBorder}`,
      },
    },
    buttonContainer: {
      marginTop: '30px',
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
      },
      [theme.breakpoints.down(768)]: {
        marginTop: '20px',
      },
    },
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 450,
      },
    },
    noThanksButton: {
      width: '100%',
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '60px',
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 450,
      },
    },
    selectVehicleHeight: {
      minHeight: '48px',
      [theme.breakpoints.up('md')]: {
        minHeight: '54px',
      },
    },
    vehicleTypeIcon: {
      width: '48px',
      maxWidth: '48px',
      height: '48px',
      maxHeight: '48px',
      [theme.breakpoints.up('md')]: {
        width: '54px',
        maxWidth: '54px',
        height: '54px',
        maxHeight: '54px',
      },
    },
    errorElement: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    vehicleTypeHeading: {
      margin: '0 0 0 14px',
      padding: 0,
    },
    vehicleTypeError: {
      border: '1px solid rgba(181, 0, 0, 1)',
    },
    planningContainer: {
      [theme.breakpoints.down(768)]: {
        '&.MuiGrid-root': {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          '& .MuiGrid-item': {
            maxWidth: '200px !important',
            '& .MuiFormControlLabel-root': {
              maxWidth: '110px',
            },
          },
        },
      },
    },
    formControlLabel: {
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    planningTo: {
      marginTop: '34px',
      marginBottom: '10px',
      fontFamily: 'BuickFuturaND-Medium !important',
      fontWeight: 'normal',
      fontSize: '20px !important',
      lineHeight: '30px',
      letterSpacing: '4px',
      textTransform: 'uppercase',
      [theme.breakpoints.down(768)]: {
        marginTop: '18px',
        fontSize: '16px !important',
        lineHeight: '24px',
      },
    },
    vehicleTypeIconSelectVehicle: {
      position: 'absolute',
      right: 0,
      top: '27px',
      [theme.breakpoints.down(768)]: {
        top: '27px',
        right: '0px',
      },
    },
    vehicleHeading: {
      '&.MuiTableCell-root': {
        padding: '6px 0px 6px 10px',
      },
    },
    inputLabel: {
      [theme.breakpoints.between(768, 1038)]: {
        whiteSpace: 'break-spaces',
        height: '27px',
      },
      [theme.breakpoints.down(480)]: {
        whiteSpace: 'break-spaces',
      },
    },
    formLabelLegend: {
      '@global': {
        a: {
          // display: 'table',
          fontWeight: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    boxContainer: {
      marginBottom: 0,
    },
    '@global': {
      // '.stat-text-link': {
      //   display: 'table',
      //   color: `${theme.palette.color.privacyStatement} !important`,
      //   fontWeight: 'inherit !important',
      // },
      '.MuiTypography-modalSubText a': {
        display: 'table',
        fontWeight: 'inherit !important',
      },
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 60px)',
          marginLeft: '-60px',
        },
      },
      '.MuiGrid-item': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '20px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '60px !important',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
      '.MuiGrid-item#ownershipType': {
        paddingLeft: '25px !important',
        [theme.breakpoints.down(768)]: {
          marginBottom: '0px !important',
        },
        [theme.breakpoints.up(768)]: {
          paddingLeft: '96px !important',
        },
      },
      '#ownershipType .MuiGrid-item': {
        marginBottom: '0px !important',
      },
      '#offerType .MuiGrid-item': {
        marginBottom: '0px !important',
      },
      '#offerType legend .MuiTypography-root': {
        marginBottom: 15,
      },
      '.MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
  };

  const evliveGlobalStyles = {
    '.MuiContainer-root': {
      padding: '0px !important',
    },
    '.MuiGrid-container': {
      width: 'calc(100% + 34px)',
      marginLeft: '-34px',
      [theme.breakpoints.up(768)]: {
        width: 'calc(100% + 54px)',
        marginLeft: '-54px',
      },
    },
    '.MuiGrid-item': {
      paddingLeft: '34px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '20px !important',
      },
      [theme.breakpoints.up(768)]: {
        paddingLeft: '54px !important',
      },
    },
    '.MuiGrid-container.error-wrapping-container, .error-container': {
      width: '100%',
      marginLeft: '0',
      [theme.breakpoints.up(768)]: {
        width: '100%',
        marginLeft: '0',
      },
    },
    '.MuiTouchRipple-root': {
      display: 'none',
    },
    '.MuiGrid-item#ownershipType': {
      paddingLeft: '47px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '0px !important',
      },
      [theme.breakpoints.up(768)]: {
        paddingLeft: '85px !important',
      },
    },
    '.MuiInputBase-root': {
      '&:hover': {
        background: 'transparent !important',
      },
      '&.Mui-focused': {
        background: 'transparent !important',
      },
    },
    '#offerType': {
      marginTop: 22,
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
      },
    },
    '#ownershipType .MuiGrid-item': {
      marginBottom: '0px !important',
    },
    '#offerType .MuiGrid-item': {
      marginBottom: '0px !important',
    },
    '#offerType legend .MuiTypography-root': {
      marginBottom: 15,
    },
    '.MuiTypography-modalSubText a': {
      '@media screen and (min-width: 768px)': {
        display: 'table',
      },
    },
    '.check-icon': {
      backgroundColor: theme.palette.grey[200],
      fontSize: '1.3rem',
      color: theme.palette.grey[300],
    },
    '.checkbox-square-icon': {
      display: 'none !important',
    },
    '.checkbox-outlined-icon': {
      backgroundColor: theme.palette.grey[200],
      '& path': { display: 'none' },
      fontSize: '1.3rem',
    },
  };

  const evliveStyles = {
    textBox: {
      paddingLeft: '0px',
      paddingRight: '0px',
      '@media screen and (min-width: 576px)': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    modalSubText: {
      maxWidth: '100%',
      marginBottom: 18,
    },
    border: {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    tableRowBorderTop: {
      td: {
        borderTop: `2px solid ${theme.palette.border.vehicleTypeBorder}`,
      },
    },
    buttonContainer: {
      marginTop: '0',
      [theme.breakpoints.up(768)]: {
        marginTop: '0',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'column',
      },
    },
    submitButton: {},
    noThanksButton: {
      [theme.breakpoints.up(768)]: {
        width: 'auto',
      },
    },
    selectVehicleHeight: {
      minHeight: '40px',
    },
    vehicleTypeIcon: {
      width: '69px',
      maxWidth: '69px',
      height: '40px',
      maxHeight: '40px',
      [theme.breakpoints.up('md')]: {
        width: '69px',
        maxWidth: '69px',
        height: '40px',
        maxHeight: '40px',
      },
    },
    errorElement: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    vehicleTypeHeading: {
      margin: '0 0 0 14px',
      padding: 0,
    },
    vehicleTypeError: {
      border: '3px solid rgba(227, 82, 5, 1)',
    },
    formControlLabel: {
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    planningTo: {
      marginTop: '10px',
      marginBottom: '20px',
      fontFamily: 'Overpass !important',
      fontWeight: 500,
      fontSize: '16px !important',
      lineHeight: '16px',
      letterSpacing: '1px',
    },
    planningContainer: {
      display: 'flex',
      flexDirection: 'row !important',
      justifyContent: 'flex-start',
      '& .MuiGrid-item': {
        width: '200px',
        maxWidth: '200px',
      },
      [theme.breakpoints.down(440)]: {
        '& .MuiGrid-item': {
          width: '120px',
          maxWidth: '120px',
        },
      },
    },
    formGroup: {
      borderBottom: 'none !important',
    },
    gridContainerEVLiveCanada: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiFormControlLabel-label': {
        lineHeight: '28px',
      },
      '& #optOut': {
        marginBottom: '9px !important',
      },
      [theme.breakpoints.down(769)]: {
        flexDirection: 'column',
        marginTop: '30px',
      },
    },
    '@global': {
      ...evliveGlobalStyles,
    },
  };

  const evliveCanadaStyles = {
    ...evliveStyles,
    form: {
      marginTop: 47,
      [theme.breakpoints.up(768)]: {
        marginTop: 57,
      },
    },
    modalSubText: {
      ...evliveStyles.modalSubText,
      marginBottom: 8,
      '@global': {
        '.stat-text-link': {
          fontWeight: 700,
        },
      },
    },
    indicatesRequiredFields: {
      marginTop: 17,
      marginBottom: 0,
    },
    '@global': {
      ...evliveGlobalStyles,
      '.MuiTypography-modalSubText a': {
        fontWeight: 'unset',
        display: 'initial',
        '@media screen and (min-width: 768px)': {
          display: 'initial',
        },
      },
    },
  };

  const energyStyles = {
    errorElement: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    indicatesRequiredFields: {
      paddingTop: 48,
      marginBottom: 64,
    },
    boxContainer: {
      marginBottom: 0,
    },
    formLabel: {
      display: 'none',
    },
    stayUpdatedInputWrapper: {
      '&#lastName, &#confirmEmail, &#state, &#phoneNumber, &#purchaseDate': {
        paddingLeft: '8px !important',
        [theme.breakpoints.down(768)]: {
          paddingRight: 0,
          paddingLeft: '0 !important',
        },
      },
    },
    topLeftBackgroundImage: {
      backgroundImage: theme.palette.background.greenTopLeftDesktop,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 253,
      height: 248,
      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },
    '@global': {
      '.MuiGrid-item': {
        paddingLeft: '0 !important',
      },
      '.MuiContainer-root': {
        padding: '0 !important',
      },
      '#productOfInterest, #purchaseDate, #state': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'transparent',
        },
        '& .MuiSelect-standard': {
          backgroundColor: 'transparent',
        },
        '& .MuiInputLabel-shrink': {
          top: '7px !important',
        },
        '& .MuiInputLabel-root': {
          paddingLeft: '14px',
          top: '-3px',
          '&.Mui-focused': {
            top: '7px',
          },
        },
      },
      // hide purchase date
      '#purchaseDate': {
        display: 'none',
      },
      '#firstName, #email, #city, #zipCode, #productOfInterest': {
        paddingRight: '8px !important',
        [theme.breakpoints.down(768)]: {
          paddingRight: '0 !important',
        },
      },
      '.MuiInputBase-root': {
        '&:hover': {
          '&& fieldset': {
            border: `2px solid ${theme.palette.color.modalTitleBlueText}`,
          },
        },
      },
      '.MuiButton-root': {
        height: '48px',
        width: '315px',
        [theme.breakpoints.down(768)]: {
          width: '100%',
        },
      },
      '#productOfInterest': {
        '& .MuiGrid-root': {
          maxWidth: '381px',
          [theme.breakpoints.down(768)]: {
            maxWidth: '100%'
          },
        },
      },
      '#phoneNumber': {
        '&.MuiGrid-item': {
          [theme.breakpoints.up(768)]: {
            marginBottom: '0px !important',
          },
        },
      },
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveCanadaStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};

import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { Vehicle, TestDrive, Dealership, BrandType } from '@types';
import { ReactElement, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextFieldForm, GridWrapper, TextButton, ErrorComponent, SelectField, ModalIconButton } from '@components';
import { useTheme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useSortedDealerships, useReporting, useCurrentLanguageParam, useQueryString } from '@hooks';
import { BookTestDriveViewStyles } from './BookTestDriveView.style';
import clsx from 'clsx';
import * as Scroll from 'react-scroll';
import { collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { getT3Dealer } from '../utils/getT3Dealer';

type BookTestDriveViewProps = {
  vehicles: Vehicle[];
  // dealerships: Dealership[];
  dealershipBacCode?: string;
  isDealerMode?: boolean;
  brandId: BrandType;
  testDrive?: TestDrive;
  t3Dealership?: any;
  t3DealershipBrand?: string;
  onClose?: () => void;
  onSave?: (testDrive: TestDrive) => void;
  onSubmit: (testDrive: TestDrive) => void;
};

const BookTestDriveView = (props: BookTestDriveViewProps): ReactElement => {
  const { t } = useTranslation();
  const { lang: languageCode } = useCurrentLanguageParam();
  const { vehicles, testDrive, brandId, dealershipBacCode, isDealerMode, t3Dealership, t3DealershipBrand, onClose, onSubmit, onSave } = props;

  const theme = useTheme();
  const savedComments = testDrive ? testDrive.comments : '';
  const [comments, setComments] = useState<string>(savedComments);
  const [vehicleItems, setVehicleItems] = useState<Vehicle[]>(vehicles);
  const [zipCode, setZipCode] = useState(testDrive?.zipCode || '');
  const [postalCode, setPostalCode] = useState(testDrive?.postalCode || '');

  const { urlSearchParams } = useQueryString();
  const t3Dealer = urlSearchParams.get('dealer');
  const t3DealerBrand = t3DealershipBrand || getT3Dealer(t3Dealer);
  const { sortedDealerships } = t3DealerBrand === ''
    ? useSortedDealerships(brandId, zipCode, postalCode)
    : useSortedDealerships(t3DealerBrand, zipCode, postalCode);
  let filteredDealerships: Dealership[];

  if (dealershipBacCode && isDealerMode) {
    filteredDealerships = sortedDealerships.filter((dealership: Dealership) => dealership && dealership.id === dealershipBacCode);

    if (t3Dealership) {
      filteredDealerships.push(t3Dealership);
    }
  }

  const [validateErrors, setValidateErrors] = useState<boolean>(false);

  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isChevy = brandId === 'chevy';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isFrench = languageCode === 'en-FR' || window.location.href.includes('en-FR') || window.location.href.includes('en-fr') || window.location.href.includes('fr');

  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  // const isEVLiveCanadaEnglish = isEVLiveCanada && !isFrench;
  const isEVLiveCanadaFrench = isEVLiveCanada && isFrench;
  // const isEnergy = brandId.toLocaleLowerCase() === 'energy';

  let maxCommentLength = 150;

  const receiveUpdates = (isCadillacCanada && isEVLiveCanada) && { receiveUpdates: '' };

  if (isGMC || isBuick) maxCommentLength = 1000;

  if (isEVLive || isEVLiveCanada || isEVLiveCanadaFrench) maxCommentLength = 100;

  const useStyles = makeStyles(BookTestDriveViewStyles(brandId));
  const styles = useStyles();

  const handelSave = (values: any) => {
    onSave(values);
  };
  const { trackAdobe } = useReporting();

  const { Element } = Scroll;

  const executeScroll = (isValid: boolean) => {
    // window.scrollTo(0, 0);
    // const scroll = Scroll.animateScroll;
    // scroll.scrollToTop('errorElement');
    const modalEl = document.getElementById('errorContainer');

    if (isValid || !isValid) {
      setTimeout(() => {
        modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      }, 1000);
      modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
    }
  };

  // const brands = ['Chevy', 'Cadillac', 'GMC', 'Buick'];

  const vehicleTypesQuery = collection(useFirestore(), `brands/${brandId}/vehicleTypes`);
  const { data: vehicleTypes } = useFirestoreCollectionData(vehicleTypesQuery, {
    idField: 'id',
  });

  return (
    <Formik
      initialValues={{
        firstName: testDrive ? testDrive.firstName : '',
        lastName: testDrive ? testDrive.lastName : '',
        email: testDrive ? testDrive.email : '',
        confirmEmail: testDrive ? testDrive.confirmEmail : '',
        city: testDrive ? testDrive.city : '',
        zipCode: testDrive ? testDrive.zipCode : '',
        postalCode: testDrive ? testDrive.postalCode : '',
        phoneNumber: testDrive ? testDrive.phoneNumber : '',
        vehicleId: testDrive ? testDrive.vehicleId : '',
        dealershipId:
          testDrive ? testDrive.dealershipId : filteredDealerships && filteredDealerships.length > 0 ? filteredDealerships[0].id : '',
        comments: testDrive ? testDrive.comments : '',
        languageCode,
        brands: testDrive ? testDrive.brands : '',
        ...receiveUpdates,
      }}
      // TODO - finish validation
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required(t('Please provide a first name.')),
        lastName: Yup.string().max(255).required(t('Please provide a last name.')),
        email: Yup.string()
          .email(t('Please provide a properly formatted email address.'))
          .required(t('Please provide a properly formatted email address.')),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
          .required(t('Email addresses do not match.')),
        city: Yup.string().required(t('Please provide a city.')),
        zipCode: !isCadillacCanada && !isEVLiveCanada
          ? Yup.string()
            .min(5, t('Zip Code is not valid.'))
            .max(5, t('Zip Code must be at most 5 characters.'))
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip Code is not valid.'))
            .required(t('Zip Code is not valid.'))
          : Yup.string(),
        postalCode: isCadillacCanada || isEVLiveCanada
          ? Yup.string()
            .min(6, t('Postal Code is not valid.'))
            .max(6, t('Postal Code must be at most 6 characters.'))
            .matches(
              /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
              t('Postal Code is not valid.'),
            )
            .required(t('Postal Code is not valid.'))
          : Yup.string(),
        phoneNumber: Yup.string()
          .min(10, t('Please provide a valid phone number.'))
          .matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            t('Phone number is limited to Canada and USA'),
          )
          .required(t('Please provide a valid phone number.')),
        dealershipId: Yup.string().required(t('Please select a dealership.')),
        receiveUpdates: (isCadillacCanada || isEVLiveCanada) && Yup.string().required(t('Please make a selection.')),
        vehicleId: Yup.string().max(255).required(t('Please select a vehicle.')).notOneOf(['']),
        brands: (isEVLive || isEVLiveCanada) ? Yup.string().max(255).required(t('Please select a brand.')).notOneOf(['']) : Yup.string()
      })}
      onSubmit={async (values, { setStatus, setSubmitting, resetForm }): Promise<void> => {
        try {
          setStatus({ success: true });
          setSubmitting(false);
          resetForm();
          setComments(savedComments); // resetting comments to savedComments from testDrive
          // @ts-ignore
          const testDrive = {
            ...values,
            comments,
            brandId,
          } as TestDrive;
          onSubmit(testDrive);
          trackAdobe('test-drive');
          onClose();
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! ${err.message}`);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        isValid,
        submitForm,
        validateForm,
      }): JSX.Element => {
        const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (isValid) {
            handleSubmit(event);
          }
        };
        return (
          <Box className="modal-container">
            <ModalIconButton
              onClose={onClose}
              brandId={brandId}
            />
            <Box id="errorContainer">
              <Element
                name="errorElement"
                className={styles.errorElement}
              >
                <ErrorComponent
                  containerId="errors"
                  condition={!isValid && validateErrors}
                  errors={errors}
                  dataDTM="test drive"
                />
              </Element>
            </Box>
            <Box>
              <Typography
                variant="modalTitle"
                component="h2"
              >
                {t('Book a test drive')}
              </Typography>

              {isGMC && <Box className="modal-border" />}

              {(isCadillacUS || isCadillacCanada) && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  sx={{ mb: '15px' }}
                  dangerouslySetInnerHTML={{
                    __html: t('Thank you for your interest in Cadillac. U.S. residents please fill out the required fields below. Non U.S. residents, please visit your country specific GM website and/or your local dealer for further information.'),
                  }}
                />
              )}

              {/* {!isEVLive && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  sx={{ mb: '15px', maxWidth: isEVLive || isEVLiveCanada ? '780px' : '100%' }}
                  className={brandId.toLowerCase() === 'chevy' ? styles.modalSubText : undefined}
                  dangerouslySetInnerHTML={{
                    __html: t('By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.'),
                  }}
                />
              )} */}

              {!isEVLiveCanada && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  sx={{ mb: '15px', maxWidth: isEVLive || isEVLiveCanada ? '780px' : '100%' }}
                  className={brandId.toLowerCase() === 'chevy' ? styles.modalSubText : undefined}
                  dangerouslySetInnerHTML={{
                    __html: t('Book Test Drive: By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.'),
                  }}
                />
              )}

              {(isEVLive || isEVLiveCanada || isCadillacUS || isCadillacCanada) && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  className={styles.modalSubText}
                  dangerouslySetInnerHTML={{
                    __html: t('* Indicates Required Fields'),
                  }}
                />
              )}
            </Box>
            <form
              id="test-drive"
              onSubmit={handleCheckForm}
              noValidate
              className={clsx(styles.form, 'test-drive')}
            >
              <Grid
                container
                spacing="30px"
              >
                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="firstName"
                >
                  <InputLabel>
                    <span dangerouslySetInnerHTML={{ __html: t('First Name (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className="stat-input-field"
                    data-dtm="test drive"
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.firstName}
                    variant="outlined"
                  />
                </GridWrapper>
                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="lastName"
                >
                  <InputLabel>
                    <span dangerouslySetInnerHTML={{ __html: t('Last Name (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className="stat-input-field"
                    data-dtm="test drive"
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastName}
                    variant="outlined"
                  />
                </GridWrapper>

                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="email"
                >
                  <InputLabel className={styles.inputLabel}>
                    <span dangerouslySetInnerHTML={{ __html: t('Email Address (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className="stat-input-field"
                    data-dtm="test drive"
                    error={Boolean(touched.email && errors.email)}
                    type="email"
                    fullWidth
                    helperText={touched.email && errors.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      let email = e.target.value;
                      if (brandId === 'cadillac' || brandId === 'cadillaccan') { email = email.toLowerCase(); }
                      setFieldValue('email', email);
                    }}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </GridWrapper>
                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="confirmEmail"
                >
                  <InputLabel className={styles.inputLabel}>
                    <span dangerouslySetInnerHTML={{ __html: t('Confirm Email Address (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className="stat-input-field"
                    data-dtm="test drive"
                    error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                    type="email"
                    fullWidth
                    helperText={touched.confirmEmail && errors.confirmEmail}
                    name="confirmEmail"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      let email = e.target.value;
                      if (brandId === 'cadillac' || brandId === 'cadillaccan') { email = email.toLowerCase(); }
                      setFieldValue('confirmEmail', email);
                    }}
                    required
                    value={values.confirmEmail}
                    variant="outlined"
                  />
                </GridWrapper>
                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="city"
                >
                  <InputLabel>
                    <span dangerouslySetInnerHTML={{ __html: t('City (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className="stat-input-field"
                    data-dtm="test drive"
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.city}
                    variant="outlined"
                  />
                </GridWrapper>
                {isCadillacCanada || isEVLiveCanada ? (
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="postalCode"
                  >
                    <InputLabel>
                      <span dangerouslySetInnerHTML={{ __html: t('Postal Code*') }} />
                    </InputLabel>
                    <TextFieldForm
                      className="stat-input-field"
                      data-dtm="test drive"
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      name="postalCode"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setPostalCode(e.target.value);
                      }}
                      required
                      value={values.postalCode}
                      variant="outlined"
                    />
                  </GridWrapper>
                ) : (
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="zipCode"
                  >
                    <InputLabel>
                      <span dangerouslySetInnerHTML={{ __html: t('Zip Code (Required):') }} />
                    </InputLabel>
                    <TextFieldForm
                      className="stat-input-field"
                      data-dtm="test drive"
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      fullWidth
                      helperText={touched.zipCode && errors.zipCode}
                      name="zipCode"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setZipCode(e.target.value);
                      }}
                      required
                      value={values.zipCode}
                      variant="outlined"
                    />
                  </GridWrapper>
                )}
                <GridWrapper
                  item
                  xs={12}
                  id="phoneNumber"
                >
                  <InputLabel>
                    <span dangerouslySetInnerHTML={{ __html: t('Phone number (Required):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className={`stat-input-field ${styles.phoneNumberInput}`}
                    data-dtm="test drive"
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    type="phone"
                    fullWidth
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    name="phoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </GridWrapper>

                {/* TODO: Add functionally for brands */}
                {(isEVLive || isEVLiveCanada) && (
                  <GridWrapper
                    item
                    md={6}
                    xs={12}
                    id="vehicleIdModal"
                  >
                    <InputLabel className={styles.inputLabel}>
                      <span dangerouslySetInnerHTML={{ __html: t('Select a brand:') }} />
                    </InputLabel>
                    <GridWrapper>
                      <SelectField
                        className="stat-dropdown"
                        data-dtm="test drive:brand"
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.brands && errors.brands)}
                        required
                        name="brands"
                        value={values.brands}
                        onChange={async (e) => {
                          const brand = e.target.value;
                          const filteredVehicleItems = vehicles.filter((v) => v.vehicleType === brand || v.vehicleTypes?.includes(brand));
                          setVehicleItems(filteredVehicleItems);
                          handleChange(e);
                        }}
                        input={<OutlinedInput />}
                      >
                        {t3DealerBrand !== ''
                        && (
                        <option
                          selected
                          value=""
                        >
                          {t('All Brands')}
                        </option>
                        )}
                        {t3DealerBrand
                        && (
                        <option
                          selected
                          value=""
                        >
                          {t(`${t3DealerBrand.charAt(0).toUpperCase()}${t3DealerBrand.substring(1)}`)}
                        </option>
                        )}
                        {vehicleTypes && t3DealerBrand !== '' && (
                          vehicleTypes.filter((x) =>
                            vehicles.some((vehicle) => (
                              (vehicle.vehicleType === x.name || vehicle.vehicleTypes?.includes(x.name))
                            ))).map((vehicleType) => (
                              <option
                                className="stat-dropdown"
                                data-dtm="test drive:brand"
                                key={vehicleType.id}
                                value={vehicleType.name}
                              >
                                {vehicleType.name}
                              </option>
                          )))}
                      </SelectField>
                      {isChevy && (
                        <div className="dropdown-icon">
                          <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                        </div>
                      )}
                      {(isCadillacUS || isCadillacCanada || isGMC || isEVLive || isEVLiveCanada || isBuick) && (
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                          }}
                        />
                      )}
                    </GridWrapper>
                    {touched.brands && errors.brands && (
                      <FormHelperText error>{t('Please select a brand.')}</FormHelperText>
                    )}
                  </GridWrapper>
                )}

                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="vehicleIdModal"
                >
                  <InputLabel className={styles.inputLabel}>
                    <span dangerouslySetInnerHTML={{ __html: t('Select a vehicle:') }} />
                  </InputLabel>
                  <GridWrapper>
                    <SelectField
                      className="stat-dropdown"
                      data-dtm="test drive:vehicle"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.vehicleId && errors.vehicleId)}
                      required
                      name="vehicleId"
                      value={values.vehicleId}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                    >
                      <option
                        disabled
                        value=""
                      >
                        {t('All Vehicles')}
                      </option>
                      {vehicleItems
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((vehicle) => (
                          <option
                            key={vehicle.id}
                            value={vehicle.id}
                          >
                            {t(vehicle.name)}
                          </option>
                        ))}
                    </SelectField>
                    {isChevy && (
                      <div className="dropdown-icon">
                        <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                      </div>
                    )}
                    {(isCadillacUS || isCadillacCanada || isGMC || isEVLive || isEVLiveCanada || isBuick) && (
                      <div
                        className="chevron-icon"
                        style={{
                          backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                        }}
                      />
                    )}
                  </GridWrapper>
                  {touched.vehicleId && errors.vehicleId && (
                    <FormHelperText error>{t('Please select a vehicle.')}</FormHelperText>
                  )}
                </GridWrapper>

                <GridWrapper
                  item
                  md={6}
                  xs={12}
                  id="dealershipId"
                >
                  <InputLabel className={styles.inputLabel}>
                    <span dangerouslySetInnerHTML={{ __html: t('Select a Dealership (Required):') }} />
                  </InputLabel>
                  <GridWrapper>
                    <SelectField
                      className="stat-dropdown"
                      data-dtm="test drive:dealership"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.dealershipId && errors.dealershipId)}
                      required
                      name="dealershipId"
                      value={values.dealershipId || t('All Dealerships')}
                      onChange={handleChange}
                      // disabled={filteredDealerships ? true : false}
                      input={<OutlinedInput />}
                    >
                      <option
                        disabled
                        value="All Dealerships"
                      >
                        {t('All Dealerships')}
                      </option>
                      {filteredDealerships
                        && filteredDealerships.length > 0 ? (
                          <>
                            {filteredDealerships
                              && filteredDealerships.map((dealership: Dealership) => (
                                <option
                                  key={dealership.id}
                                  value={dealership.id}
                                >
                                  {dealership.name}
                                </option>
                              ))}
                          </>
                        ) : (
                          <>
                            {sortedDealerships
                            && sortedDealerships.map((dealership) => (
                              <option
                                key={dealership.id}
                                value={dealership.id}
                              >
                                {dealership.name}
                              </option>
                            ))}
                          </>
                        )}
                    </SelectField>
                    {isChevy && (
                      <div className="dropdown-icon">
                        <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                      </div>
                    )}
                    {(isCadillacUS || isCadillacCanada || isGMC || isEVLive || isEVLiveCanada || isBuick) && (
                      <div
                        className="chevron-icon"
                        style={{
                          backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                        }}
                      />
                    )}
                  </GridWrapper>
                  {touched.dealershipId && errors.dealershipId && (
                    <FormHelperText error>{t('Please select a dealership.')}</FormHelperText>
                  )}
                </GridWrapper>
                <GridWrapper
                  item
                  xs={12}
                  style={{ marginBottom: 0 }}
                >
                  <InputLabel>
                    <span dangerouslySetInnerHTML={{ __html: t('Comments (Optional):') }} />
                  </InputLabel>
                  <TextFieldForm
                    className={clsx(styles.textField, 'stat-input-field')}
                    data-dtm="test drive"
                    error={Boolean(touched.comments && errors.comments)}
                    helperText={touched.comments && errors.comments}
                    name="comments"
                    multiline
                    minRows={6}
                    inputProps={{ maxLength: maxCommentLength }}
                    value={comments}
                    onChange={(event) => {
                      const { value } = event.target;
                      setComments(value);
                    }}
                  />
                </GridWrapper>
              </Grid>
              <Typography
                component="h5"
                variant="characterCount"
                className={styles.characterCount}
              >
                {comments.length}/{maxCommentLength}
              </Typography>

              {(isCadillacCanada || isEVLiveCanada) && (
                <Grid
                  container
                  className={styles.checkboxes}
                >
                  <RadioGroup
                    id="receiveUpdates"
                    name="receiveUpdates"
                    value={values.receiveUpdates}
                    onChange={handleChange}
                    sx={{
                      flexWrap: 'nowrap',
                      [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="optIn"
                    >
                      <FormControlLabel
                        className="stat-checkbox"
                        data-dtm="test drive"
                        value="Y"
                        control={
                          <Radio
                            checkedIcon={
                              isCadillacCanada
                                ? <><CheckIcon className={styles.checkIcon} /><CheckBoxOutlineBlankIcon /></>
                                : <CheckIcon className="check-icon" />
                            }
                            icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                          />
                        }
                        label={`${t('Opt In for additional updates and offers')}`}
                      />
                      <Typography
                        variant="modalSubText"
                        component="p"
                        dangerouslySetInnerHTML={{
                          __html: isCadillacCanada
                            ? t('YES! I want to receive email and other electronic communications with news, offers and updates about products and services from Cadillac and General Motors of Canada Company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*')
                            : t('Book Test Drive: YES! I want to receive email and other electronic communications with news, offers and updates about products and services from Cadillac and General Motors of Canada Company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*')
                        }}
                      />
                    </GridWrapper>
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="optOut"
                    >
                      <FormControlLabel
                        className="stat-checkbox"
                        data-dtm="test drive"
                        value="N"
                        control={
                          <Radio
                            checkedIcon={
                              isCadillacCanada
                                ? <><CheckIcon className={styles.checkIcon} /><CheckBoxOutlineBlankIcon /></>
                                : <CheckIcon className="check-icon" />
                            }
                            icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                          />
                        }
                        label={`${t('Opt Out')}`}
                      />
                      <Typography
                        variant="modalSubText"
                        component="p"
                        dangerouslySetInnerHTML={{
                          __html: isCadillacCanada
                            ? t('General Motors of Canada Company would like to periodically provide you with information on new products and services that are relevant to you based on information we have about you, and use your information in accordance with our privacy policy . If you wish the information provided be used solely to respond to your request, simply check the box.')
                            : t('Book Test Drive: General Motors of Canada Company would like to periodically provide you with information on new products and services that are relevant to you based on information we have about you, and use your information in accordance with our privacy policy . If you wish the information provided be used solely to respond to your request, simply check the box.')
                        }}
                      />
                    </GridWrapper>
                  </RadioGroup>
                </Grid>
              )}

              <Box className={styles.buttonContainer}>
                <Button
                  className={clsx('stat-button-link', styles.submitButton)}
                  data-dtm="test drive"
                  onClick={() => {
                    validateForm()
                      .then(() => submitForm())
                      .catch((e) => new Error(e));
                    setValidateErrors(true);
                    executeScroll(isValid);
                  }}
                  disabled={isSubmitting}
                  variant="primary"
                  type="submit"
                  aria-label="Submit book test drive"
                >
                  {t('Submit')}
                </Button>
                {onSave && (
                  <TextButton
                    className={`${styles.saveButton} ${isGMC || isBuick ? 'stat-button-link' : 'stat-text-link'
                    }`}
                    data-dtm="test drive"
                    disableRipple
                    variant={isGMC || isBuick ? 'tertiary' : 'text'}
                    type="button"
                    // @ts-ignore
                    onClick={() => handelSave({ ...values, comments } as TestDrive)}
                    aria-label="Save book test drive"
                  >
                    {t('Save')}
                  </TextButton>
                )}
              </Box>

              {/* {isCadillacCanada && (
                <Grid sx={{ mt: '50px' }}>
                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={styles.legalFooter}
                  >
                    {t(
                      '*General Motors of Canada Company (GM) is located at 1908 Colonel Sam Drive, Oshawa, ON, L1H 8P7, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click “YES”, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',
                    )}
                  </Typography>
                </Grid>
              )} */}

              {(isCadillacCanada || isEVLiveCanada) && (
                <Grid sx={{ mt: '50px' }}>
                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={styles.legalFooter}
                    dangerouslySetInnerHTML={{
                      __html: t<string>(
                        'Book Test Drive: * General Motors of Canada Company (GM) is located at 500 Wentworth Street West, Oshawa, ON, L1J 0C5, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click “YES”, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',
                      ),
                    }}
                  />
                </Grid>
              )}
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default BookTestDriveView;

export const getT3Dealer = (t3Dealer: string) => {
  const suffix = '_customer';
  if (!t3Dealer) {
    return '';
  }
  // Check if the string ends with '_customer'
  if (t3Dealer.endsWith(suffix)) {
    // Extract the part before '_customer'
    return t3Dealer.slice(0, -suffix.length);
  }

  return ''; // Return empty string if format is incorrect
};

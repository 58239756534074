import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

type SessionMenuProps = {
  hideVideo?: boolean;
  label?: string;
  children?: ReactNode;
  onClick?: () => void;
  isBorder?: boolean;
  className?: string;
  dataDTM?: string;
  brandId: string;
};

const SessionMenuItem = (props: SessionMenuProps): ReactElement => {
  const { label, children, onClick, hideVideo, isBorder, className, dataDTM, brandId } = props;
  const theme = useTheme();
  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';

  let sessionMenuItemStyles = {};

  if (isCadillacUS || isCadillacCanada || isGMC) {
    sessionMenuItemStyles = {
      sessionBorder: {
        marginRight: '26px',
        marginBottom: 20,
      },
      SessionMenuItem: {
        height: 'unset',
        width: '100%',
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: '26px',
        paddingRight: '26px',
        marginBottom: 0,
        '&:hover': {
          backgroundColor: '#000'
        },
      },
    };
  }

  if (isBuick) {
    sessionMenuItemStyles = {
      sessionBorder: {
        marginRight: '26px',
        marginBottom: 20,
      },
      SessionMenuItem: {
        height: 'unset',
        width: '100%',
        paddingTop: '8.5px',
        paddingBottom: '8.5px',
        paddingLeft: '26px',
        paddingRight: '26px',
        marginBottom: 0,
        '&:hover': {
          backgroundColor: '#3F3F38',
          textDecoration: 'none !important',
        },
      },
    };
  }

  if (isEVLive) {
    sessionMenuItemStyles = {
      sessionBorder: {
        marginTop: 20,
        marginRight: 25,
        marginBottom: 20,
        height: 1
      },
      SessionMenuItem: {
        height: 'unset',
        width: '100%',
        maxWidth: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: '25px',
        paddingRight: '25px',
        marginBottom: 0,
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
      },
    };
  }

  if (isEnergy) {
    sessionMenuItemStyles = {
      SessionMenuItem: {
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: 10,
        marginBottom: 0,
        backgroundColor: theme.palette.background.paper,
        width: 'fit-content',
        '&:first-child': {
          marginTop: 0,
        },
        '&:hover': {
          backgroundColor: "#0009FF",
          transition: 'all 300ms ease',
          '@global': {
            '.MuiTypography-sessionMenuIconText': {
              color: '#ffffff !important',
            },
            '.camera-stream-stop-icon': {
              backgroundImage: theme.palette.background.cameraStreamStopWhiteIcon,
            },
            '.camera-stream-icon': {
              backgroundImage: theme.palette.background.cameraStreamWhiteIcon,
            },
            '.camera-stream-not-supported-icon': {
              backgroundImage: theme.palette.background.cameraStreamNotSupportedWhiteIcon,
            },
            '.book-test-drive-icon': {
              backgroundImage: theme.palette.background.bookTestDriveWhiteIcon,
            },
            '.contact-dealer-icon': {
              backgroundImage: theme.palette.background.contactDealerWhiteIcon,
            },
            '.screen-share-icon': {
              backgroundImage: theme.palette.background.screenShareWhiteIcon,
            },
            '.invite-a-friend-icon': {
              backgroundImage: theme.palette.background.inviteAFriendWhiteIcon,
            },
            '.help-icon': {
              backgroundImage: theme.palette.background.helpWhiteIcon,
            },
          },
        },
        [theme.breakpoints.up(1200)]: {
          marginTop: 16,
        },
      },
      sessionIcon: {
        marginLeft: 8,
        height: 24,
        width: 'auto',
      },
    };
  }

  const useStyles = makeStyles({
    sessionBorder: {
      marginTop: 20,
      marginBottom: 30,
      height: 2,
      width: 30,
    },
    SessionMenuItem: {
      height: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 0,
      marginBottom: 20,
    },
    sessionIcon: {
      marginLeft: 20,
      height: 30,
      width: 'auto',
    },
    [theme.breakpoints.up('xs')]: {
      ...sessionMenuItemStyles,
    },
  });
  const styles = useStyles();

  if (isBorder) {
    return (
      <Box
        className={styles.sessionBorder}
        sx={{
          backgroundColor: hideVideo
            ? theme.palette.border.sessionBorder
            : theme.palette.background.paper,
        }}
      />
    );
  }

  return (
    <Button
      className={clsx(styles.SessionMenuItem, className)}
      onClick={onClick}
      disableRipple
      data-dtm={dataDTM}
      aria-label={`Select ${label}`}
    >
      {label && (
        <Typography
          variant="sessionMenuIconText"
          component="p"
          sx={{ color: hideVideo ? theme.palette.color.sessionMenuIconTextMute : '' }}
        >
          {label}
        </Typography>
      )}
      <Box className={styles.sessionIcon}>
        {children}
      </Box>

    </Button>
  );
};

export default SessionMenuItem;
